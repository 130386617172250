import { Injectable, Injector } from "@angular/core";
import { formatDate } from "@angular/common";
import { BehaviorSubject } from "rxjs";
//import Sugar from 'sugar-date';
import { IOService } from "./io.service";
import { ShareDataService } from "./share-data.service";
import { Util } from "./util";
import { ConfirmationDialogComponent } from "~dialog/confirmation/confirmation.component";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import TimeAgo from 'javascript-time-ago'
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en);

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public members = [];
  private _current = new BehaviorSubject<any>(null);
  public current = this._current.asObservable();
  private _list = new BehaviorSubject<any>([]);
  public list = this._list.asObservable();
  private _messages = new BehaviorSubject<any>([]);
  public messages = this._messages.asObservable();
  private _messageGroups = new BehaviorSubject<any>([]);
  public messageGroups = this._messageGroups.asObservable();
  private timeAgo = new TimeAgo('en-US');

  constructor(
    //private readonly injector: Injector,
    private readonly shared: ShareDataService,
    private dialog: MatDialog,
    private readonly util: Util,
    private router: Router,
    private io: IOService
  ) {
     
    // Add locale-specific relative date/time formatting rules.
    TimeAgo.addLocale(en)
     
    // Create relative date/time formatter.
    
    setTimeout(() => {
      //this.io = this.injector.get(IOService);
      // @ts-ignore
      this.io.on('io/init', this.init.bind(this));
      // @ts-ignore
      this.io.on('logout', this.reset.bind(this));
      if (this.io.connected) {
        this.init();
      }
    }, 0);

    setInterval(() => this.updatePages(this._list.getValue()), 30000);
  }

  init() {
    this.io.listen('page/list', ({ pages }) => this.updatePages(pages));
    this.io.listen('page/members', ({ members }) => {
      this.members = members;
    });
    this.io.listen('member/added', ({ pageId, member }) => {
      const currentPage = this._current.getValue();
      if (currentPage._id !== pageId) {
        return;
      }
      this.members.push(member);
    });
    this.io.listen('member/deleted', ({ pageId, member }) => {
      const currentPage = this._current.getValue();
      if (currentPage._id !== pageId) {
        return;
      }
      this.members = this.members.filter(_member => _member._id !== member._id);
    });
    this.io.listen('member/deleted', ({ pageId, member }) => {
      const currentPage = this._current.getValue();
      if (currentPage._id !== pageId) {
        return;
      }
      this.members.forEach((_member) => {
        if (_member._id === member._id) {
          _member = member;
        }
      });
    });
    this.io.listen('video/prompt/recording/save', this.handleVideoRecordingSavePrompt.bind(this));
    this.io.listen('page/typing', ({ beamIds }) => this.updatePageTyping(beamIds));
  }

  reset() {
    this._current.next(null);
    this._list.next([]);
    this._messages.next([]);
    this._messageGroups.next([]);
    this.members = [];
  }

  resetPage() {
    if (this._current.getValue()) {
      this.io.send('page/unsubscribe', { pageId: this._current.getValue()._id });
    }
    this._current.next(null);
  }

  selectPage(page: any) {
    this._current.next(page);
    this.shared.chatLoading = true;
    this._messages.next([]);
    this._messageGroups.next([]);
    if (page) {
      if (this.router.url.startsWith('/page')) {
        this.router.navigate(['/page', this.getPageTitleUrl(page.title), page._id]);
      }
      setTimeout(() => {
        this.io.send('page/subscribe', { pageId: page._id });
      }, 0);
    }
  }

  selectPageId(pageId: string) {
    const __list = this._list.getValue();
    const page = __list.find(p => p._id === pageId);
    if (page) {
      this.selectPage(page);
    }
  }

  updatePages(pages: Array<any>, selectPageId: string = null) {
    const PAGE_DATETIME_FORMAT = '{MM}/{dd}/{yyyy} {hh}:{mm}:{ss}.{ms} {TT}';
    pages.forEach((page) => {
      if (page.title === null) {
        //page.title = Sugar.Date.format(new Date(page.createdAt), PAGE_DATETIME_FORMAT);
        page.title = formatDate(new Date(page.createdAt),"yyyy/MM/dd HH:mm:ss zz",'en-US');
      }
      if (page.lastMessage) {
        if (new Date(page.lastMessage.createdAt) <= new Date()) {
          //page.lastMessage.createdAtRelative = Sugar.Date.relative(new Date(page.lastMessage.createdAt));
          page.lastMessage.createdAtRelative = this.timeAgo.format(new Date(page.lastMessage.createdAt));
        } else {
          page.lastMessage.createdAtRelative = 'now';
        }
      }
    })
    pages = this.util.deDuplicate(pages, '_id');

    this._list.next(pages);
    if (selectPageId) {
      const page = pages.find(p => p._id === selectPageId);
      if (page) {
        this.selectPage(page);
      }
    }
    this.shared.pagesLoading = false;
  }

  setMessages(messages) {
    let _messages = messages.map(this.processMessage.bind(this));
    _messages = this.util.deDuplicateAndKeepUndefined(_messages, '_id');
    this._messages.next(_messages);
    this.groupMessages();
  }

  addMessage(message) {
    this.setMessages(this._messages.getValue().concat([message]))
  }

  updateMessage(message) {
    let updated = false;
    let messages = this._messages.getValue();
    for (let i in messages) {
      const msg = messages[i];
      if (msg._id === message._id) {
        messages[i] = message;
        updated = true;
      } else if (typeof msg._id === 'undefined' &&
        (!message.attributes || msg.attributes.type === message.attributes.type)
        && msg.body === message.body) {
        message.updated = true;
        messages[i] = message;
        updated = true;
        break;
      }
    }
    if (!updated) {
      this.addMessage(message)
    } else {
      this.setMessages(messages);
    }
  }

  deleteMessage(message) {
    let messages = this._messages.getValue();
    messages = messages.filter(msg => msg._id !== message._id);
    this.setMessages(messages);
  }

  handleVideoRecordingSavePrompt({ page }) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Save Call Recording?',
        message: 'Would you like to save a recording of the last video call?',
      },
    });
    dialogRef.afterClosed().subscribe((save) => {
      this.io.send('video/record', { pageId: page._id, save });
    });
  }

  public sendTypingEvent(typing) {
    if (this._current.value) {
      const data = { pageId: this._current.value._id, beamId: this.shared.user.beamId.IdOne, status: typing };
      this.io.send('page/typing', data);
    }
  }

  public selectDefaultPage(page?) {
    if (!this._current.getValue() && this.shared.isChatActive()) {
      if (!page) {
        this._list.next(this._list.getValue());
      } else {
        this.selectPage(page);
      }
    }
  }

  private getUserDetails(userId) {
    const user = this.shared.users.get(userId);
    if (!user) {
      return null;
    }
    user.isCurrentUser = userId === this.shared.user._id;
    return user;
  }

  /**
   * Formats the date for display
   *
   * @param date Date
   */
  private formatDate(date) {
    const currdate = new Date();
    const dateToCheck = new Date(date);
    if (currdate.toDateString() === dateToCheck.toDateString()) {
      return `${dateToCheck.getHours().toString().padStart(2, '0')}:`
        + `${dateToCheck.getMinutes().toString().padStart(2, '0')}:`
        + `${dateToCheck.getSeconds().toString().padStart(2, '0')}`;
    } else {
      return `${dateToCheck.getDate().toString().padStart(2, '0')}-`
        + `${(dateToCheck.getMonth() + 1).toString().padStart(2, '0')}-`
        + `${dateToCheck.getFullYear().toString().substr(-2)}`;
    }
  }

  private processMessage(message) {
    return {
      ...message,
      type: message.attributes && message.attributes.type ? message.attributes.type : 'text',
      data: message.attributes ? message.attributes.data : {},
      attributes: { ...message.attributes, data: message.attributes ? message.attributes.data : {} },
      timeago: this.formatDate(message.createdAt),
    };
  }

  private groupMessages() {
    // Creates message groups based on messages by the same user in a sequence
    // Scenario:
    // User A posts 3 messages
    // User B posts 1 message
    // User A posts 2 messages
    // We will now have 3 message groups with 3, 1 and 2 messages.
    // Message groups can also be split by last read status indicator
    const messages = this._messages.getValue();

    const messageGroups = messages.reduce((result, message, i, list) => {
      const lastRead = this.filterAndMapLastRead(message.lastRead);
      const prevLastRead = i > 0 ? this.filterAndMapLastRead(list[i - 1].lastRead) : [];
      // First message group, just push the message
      if (result.length === 0) {
        result.push({ user: this.getUserDetails.call(this, message.owner), messages: [message], lastRead });
        // New message group - if user is different from last user or last message had a seen by message (last read)
      } else if (i > 0 && (list[i - 1].owner !== message.owner || prevLastRead.length > 0)) {
        result.push({ user: this.getUserDetails.call(this, message.owner), messages: [message], lastRead });
      } else {
        result[result.length - 1].lastRead = lastRead;
        result[result.length - 1].messages = [...result[result.length - 1].messages, message];
      }
      return result;
    }, []);
    this._messageGroups.next(messageGroups);
  }

  private updatePageTyping(beamIds: any[]) {
    const page = this._current.value;
    if (beamIds && beamIds.length > 0 && beamIds.some(id => id !== this.shared.user.beamId.IdOne)) {
      beamIds = beamIds.filter(id => id !== this.shared.user.beamId.IdOne);
      page.isTyping = true;
      page.whoTyping = beamIds && beamIds.length > 0 ? beamIds.join() : '';
    } else {
      page.isTyping = false;
      page.whoTyping = '';
    }
    this._current.next(page);
  }

  private filterAndMapLastRead(lastRead: any[]) {
    if (lastRead) {
      return lastRead.filter(user => user.beamId !== this.shared.user.beamId.IdOne)
        .map(user => user.beamId).join(', ');
    } else {
      return [];
    }
  }

  private getPageTitleUrl(title) {
    function slugify(text) {
      return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
    }

    return title ? slugify(title) : '';
  }
}
