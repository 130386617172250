import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { ContactComponent } from './contact/contact.component';
import { TermsComponent } from './terms/terms.component';
import { MaterialModule } from '~common/material';

const routes: Routes = [
  { path: 'contact', component: ContactComponent },
  { path: 'terms', component: TermsComponent },
];

@NgModule({
  declarations: [
    ContactComponent,
    TermsComponent,
  ],
  imports: [
    MaterialModule,
    FormsModule,
    RouterModule.forChild(routes),
  ],
  entryComponents: [
    TermsComponent,
  ],
})
export class StaticModule {}
