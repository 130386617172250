import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Util {
  private days = [
    { name: 'Monday' },
    { name: 'Tuesday' },
    { name: 'Wednesday' },
    { name: 'Thursday' },
    { name: 'Friday' },
    { name: 'Saturday' },
    { name: 'Sunday' },
  ];

  delay(duration: number) {
      return new Promise(res => setTimeout(() => res(), duration));
  }

  debounce(delay: number, fn: Function) {
      let timerId: any;
      return (...args: any[]) => {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          fn(...args);
          timerId = null;
        }, delay);
      };
  }

  deDuplicate(list: Array<any>, key: string): Array<any> {
    return list.map(e => e[key])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => list[e])
      .map(e => list[e]);
  }

  deDuplicateAndKeepUndefined(list: Array<any>, key: string): Array<any> {
    return list.map(e => e[key])
      .map((e, i, final) => (typeof e === 'undefined' || final.indexOf(e) === i) && i)
      .filter(e => list[e])
      .map(e => list[e]);
  }


  popupCenter(url: string, title: string, w: number, h: number) {
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, 'scrollbars=no,status=no,location=no,toolbar=no,menubar=no,width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus();
    }
  }

  getRepeatText(rideEvent: any) {
    const time = ' ' + rideEvent.attributes.data.dateTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
    let repeat = '';
    if (rideEvent.attributes.data.isRepeating) {
      const repeatDays = rideEvent.attributes.data.repeatDays.length === 7 ?
        'day' :
        rideEvent.attributes.data.repeatDays.map(r => this.days[r - 1].name).join(', ');
      repeat = ' - Repeats every ' + repeatDays;
    }
    return time + repeat;
  }
}
