import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
declare var google: any;

@Component({
  selector: 'app-emergency-emulator',
  templateUrl: './emergency-emulator.component.html',
  styleUrls: ['./emergency-emulator.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmergencyEmulatorComponent implements OnInit{
  @ViewChild('Address', { static: true }) AddressRef: ElementRef;
  addresses = [
    '577 Pineknoll Street, Opelika, AL, USA',
    '99 High Noon Lane, Blue Eye, MO, USA',
    '7659 NW Plaza, Saint Ann, St. Louis, MO, USA',
    '32 William Avenue, Minneapolis, MN, USA',
    '42 North Cross Street, Layton, UT, USA',
    '942 Manor Station Way, Manteca, CA, USA',
  ];
  sources = [
    { source: 'Fixed Address', buttonText: 'Landline Phone', type: 'landline' },
    { source: 'Triangulated Location', buttonText: 'Mobile Phone', type: 'mobile' },
    { source: 'Triangulated Location', buttonText: 'Text to 911', type: 'text' },
    { source: 'GPS', buttonText: 'Mobile App', type: 'app' },
    { source: 'GPS', buttonText: 'Assist Device', type: 'assist' },
    { source: 'Fixed Address', buttonText: 'Building Alarm', type: 'building' },
    { source: 'GPS', buttonText: 'Vehicle Alarm', type: 'vehicle' },
    { source: 'Fixed Address', buttonText: 'Public Alarm', type: 'public' },
    { source: 'GPS', buttonText: 'Social Alert', type: 'alert' },
  ];
  address = new FormControl('');
  phoneNumber = '';
  instructions = '';
  location: any;
  addressAutocomplete: google.maps.places.Autocomplete;
  source: any;

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.initAutoComplete();
  }

  selectSource(source) {
    // this.randomizeAddress();/
    // this.randomizePhone();
    this.source = source;
  }

  send() {
    this.router.navigate(['/page',
      {
        type: 'emergency',
        sourceType: this.source.type,
        address: this.address.value,
        lat: this.location.lat,
        lng: this.location.lng,
        phoneNumber: this.phoneNumber,
        instructions: this.instructions,
        location: this.location
      }]);
  }

  private initAutoComplete() {
    this.addressAutocomplete = new google.maps.places.Autocomplete(this.AddressRef.nativeElement);
    this.addressAutocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = this.addressAutocomplete.getPlace();
      this.address.setValue(place.formatted_address),
      this.location = {
        text: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
    });
  }

  private randomizeAddress() {
    const address = this.addresses[Math.floor(Math.random() * this.addresses.length)];
    this.address.setValue(address);
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        this.location = {
          text: address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
      }
    });
  }

  isSelected(source) {
    if (this.source && source.type === this.source.type) { return 'selectedButton'}
  }
}
