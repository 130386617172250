import { Injectable, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";


enum MiddleBlock {
  CHAT,
  NEW_RIDE,
  EMERGENCY,
  TRACE,
  MEDICAL,
}

@Injectable({
  providedIn: 'root'
})

export class ShareDataService {
  users: Map<string, any> = new Map();
  friends: Array<any> = [];
  groups: Array<any> = [];
  assets: Array<any> = [];
  pages: Array<any> = [];
  people: Array<any> = [];
  places: Array<any> = [];
  media: Array<any> = [];
  events: Array<any> = [];

  public pagesLoading = false;
  public chatLoading = false;
  public mapLoading = false;
  public user: any = null;
  public ShowMap: Boolean = false;
  public ShowDirection: Boolean = false;
  public showDirectionpanel: Boolean = false;
  public ShowMarker: Boolean = true;
  public ClearMap: EventEmitter<any> = new EventEmitter<any>();
  public videoInvitationPage: Object;

  public FormatedAddress = '';
  public ShowMapView = new EventEmitter<any>();
  public ShowMapDirection = new EventEmitter<any>();
  public ShowMapDirectionFromChat = new EventEmitter<any>();
  public ShowMapDirectionFromRide = new EventEmitter<any>();
  public ShowMapGPSLive = new EventEmitter<any>();
  public ShowMapGeofence = new EventEmitter<any>();
  public TermsandConditionsclick = new EventEmitter<any>();
  public ShowPointeronMap = new EventEmitter<any>();
  public location = new EventEmitter<any>();
  public showVideoInvitation = new EventEmitter<any>();
  public showFriendChat = new EventEmitter<any>();
  public recoveryMode = new EventEmitter<any>();
  public logout = new EventEmitter<any>();

  MiddleBlock = MiddleBlock;
  public activeMiddleBlock: MiddleBlock = MiddleBlock.CHAT;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  removeBodyClasses() {
    this.document.body.classList.remove('chat-fullblock');
    this.document.body.classList.remove('chat-fullscreen');
    this.document.body.classList.remove('map-fullblock');
    this.document.body.classList.remove('map-fullscreen');
    this.document.body.classList.remove('chat-closescreen');
    this.document.body.classList.remove('map-closescreen');
    this.document.body.classList.remove('chat-closescreen');
    this.document.body.classList.remove('chat-only');
    this.document.body.classList.remove('map-only');
    this.document.body.classList.remove('leftnav-only');
    this.document.body.classList.remove('leftnav-only');
    this.document.body.classList.remove('landing');
  }

  /**
   * Handle flip horizontal layout
   */
  middleBlockFlipHorizontal() {
    this.removeBodyClasses();
    this.document.body.classList.add('chat-fullblock');
  }

  /**
   * Handle flip vertical layout
   */
  middleBlockFlipVertical() {
    this.removeBodyClasses();
  }

  /**
   * Handle full-screen layout
   */
  middleBlockFullScreen() {
    this.removeBodyClasses();
    this.document.body.classList.add('chat-fullscreen');
  }

  isChatActive() {
    return this.activeMiddleBlock === MiddleBlock.CHAT;
  }
}
