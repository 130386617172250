import { Component, Inject, ViewChild, } from '@angular/core';
import { TextTimeInputComponent } from '~chat/create-event/text-time-input/text-time-input.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';
import { Util } from '~common/util';
import { IAddress } from '../../beam-forms/beam-forms.interface';

enum DATE_INPUT {
  DATE_TIME,
  TEXT_BASED
}

@Component({
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})

export class CreateEventComponent {
  @ViewChild('RideSchedule') RideSchedule: TextTimeInputComponent;
  @ViewChild('timePicker') timePicker: NgxMaterialTimepickerComponent;
  days = [
    { id: 1, name: 'Monday', shortcut: 'Mo' },
    { id: 2, name: 'Tuesday', shortcut: 'Tu' },
    { id: 3, name: 'Wednesday', shortcut: 'We' },
    { id: 4, name: 'Thursday', shortcut: 'Th' },
    { id: 5, name: 'Friday', shortcut: 'Fr' },
    { id: 6, name: 'Saturday', shortcut: 'Sa' },
    { id: 7, name: 'Sunday', shortcut: 'Su' },
  ];
  notifyVia = ['Email', 'SMS', 'App'];

  DATE_INPUT = DATE_INPUT;
  dateInputType = DATE_INPUT.DATE_TIME;
  dialogTitle = 'Event';
  titlePlaceholder = 'Event Title';
  ride = false;
  edit = false;
  showLocation = true;

  eventTitle = '';
  eventDate = new Date();
  eventTime = '12:00 AM';
  rideTime = '';
  repeatDays: number[] = [];
  eventReminderMinutes = 15;
  notificationMethods: string[] = [];
  rideEvents: any[] = [];
  location: IAddress = {
    text: '',
    lat: null,
    lng: null,
  };

  private eventId: string = null;
  private isRepeating = false;
  private isNotification = false;

  constructor(public dialogRef: MatDialogRef<CreateEventComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public util: Util
  ) {
    if (data.message) {
      this.edit = true;
      this.eventId = data.message._id;
      this.eventTitle = data.message.data.title;
      this.eventDate = new Date(data.message.data.dateTime);
      this.eventTime = new Date(data.message.data.dateTime).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
      this.isRepeating = data.message.data.isRepeating;
      this.repeatDays = data.message.data.repeatDays;
      this.isNotification = data.message.data.isNotification;
      this.eventReminderMinutes = data.message.data.eventReminderMinutes;

      this.notificationMethods = data.message.data.notificationMethods;
      this.repeatDays = data.message.data.repeatDays;
    } else {
      this.eventTitle = data.title;
    }
    if (data.ride) {
      this.ride = true;
      if (data.rideEvents) {
        this.rideEvents = data.rideEvents;
      }
      this.eventTitle = 'Pickup time';
      this.dialogTitle = 'Schedule Ride';
      this.titlePlaceholder = 'Schedule Ride';
      this.dateInputType = DATE_INPUT.TEXT_BASED;
      this.showLocation = false;
    }
    if (data.singleTime) {
      this.eventTitle = data.singleTime.eventTitle;
      this.eventDate = new Date(data.singleTime.timestamp);
      this.eventTime = new Date(data.singleTime.timestamp).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    }
    if (typeof data.showLocation !== 'undefined') {
      this.showLocation = data.showLocation;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createEvent() {
    let _date;
    if (this.ride) {
      _date = this.rideTime;
    } else {
      const { hours, minutes } = this.parseTime(this.eventTime);
      _date = new Date(this.eventDate.getTime());
      _date.setHours(hours, minutes, 0);
    }
    this.isRepeating = this.repeatDays.length > 0;
    this.isNotification = this.notificationMethods.length > 0;

    // Putting all event parameters together
    const attributes = {
      type: 'event',
      timestamp: new Date().getTime(),
      data: {
        title: this.eventTitle,
        dateTime: _date,
        isRepeating: this.isRepeating,
        isPending: true,
        repeatDays: this.repeatDays,
        isNotification: this.isNotification,
        notificationMethods: this.notificationMethods,
        notificationTime: new Date(_date.getTime() - this.eventReminderMinutes * 60 * 1000),
        eventReminderMinutes: this.eventReminderMinutes
      }
    };
    // Event title is in the message body
    return { id: this.eventId, body: this.eventTitle, attributes };
  }

  parseTime(date: string): { hours: number, minutes: number } {
    const [time, period] = date.split(' ');
    let [hours, minutes]: number[] = time.split(':').map(t => parseInt(t, 10));
    hours = hours === 12 ? 0 : hours;

    if (period && period.toUpperCase() === 'PM') {
      hours += 12;
    }

    return { hours, minutes };
  }

  addEvent() {
    if (this.isValid()) {
      this.rideEvents.push(this.createEvent());
      this.resetForm();
    }
  }

  resetForm() {
    this.eventDate = new Date();
    this.eventTime = this.ride ? 'Pickup time' : '12:00 AM';
    this.rideTime = null;
    this.repeatDays = [];
    this.notificationMethods = [];
    this.eventReminderMinutes = 15;
    this.isRepeating = false;
    this.repeatDays = [];
    this.isNotification = false;
    this.notificationMethods = [];
    if (this.RideSchedule) {
      this.RideSchedule.setTime(null);
    }
  }

  saveEvent() {
    if (this.isValid()) {
      this.dialogRef.close([].concat(this.createEvent()));
    }
  }

  saveRideEvents() {
    if (this.rideEvents.length === 0) {
      this.saveEvent();
    } else {
      this.dialogRef.close(this.rideEvents);
    }
  }

  removeRideEvent(rideEvent: any) {
    const indexOf = this.rideEvents.indexOf(rideEvent);
    this.rideEvents.splice(indexOf, 1);
  }

  isValid() {
    return this.dateInputType === DATE_INPUT.DATE_TIME ? (this.eventTitle && this.eventTime) : this.rideTime;
  }
}
