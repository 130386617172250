import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _activeMessages = new Map<string, string>();
  private _activeMessagesSubject = new BehaviorSubject<string[]>([]);
  public activeMessages = this._activeMessagesSubject.asObservable();

  show(id: string, message: string) {
    this._activeMessages.set(id, message);
    this.update();
  }

  hide(id: string) {
    this._activeMessages.delete(id);
    this.update();
  }

  private update() {
    this._activeMessagesSubject.next(Array.from(this._activeMessages.values()));
  }
}
