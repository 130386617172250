import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmergencyEmulatorComponent } from './emergency-emulator/emergency-emulator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { ChatModule } from '~chat/chat.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TextMaskModule,
    ChatModule,
    FormsModule
  ],
  declarations: [EmergencyEmulatorComponent]
})
export class EmergencyEmulatorModule { }
