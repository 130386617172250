import { Component, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { MapsAPILoader } from '@agm/core';
import { ShareDataService } from "~common/share-data.service";
import { MapType } from '../map.enum';

@Component({
  selector: 'app-map-header',
  templateUrl: './map-header.component.html',
  styleUrls: ['./map-header.component.css'],
})
export class MapHeaderComponent {
  
  @Input() mapType: MapType;
  @Input() page;
  @Output() search: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
  
  @ViewChild('search', { read: ElementRef, static: true }) public searchInput: ElementRef;

  public MapType = MapType;
  public map: google.maps.Map;

  private _autocomplete: google.maps.places.Autocomplete;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private mapsAPILoader: MapsAPILoader,
    public shared: ShareDataService,
  ) {
    this._initSearch();
  }

  /**
   * This function is called by MapViewComponent once map is ready for use
   * 
   * @param map
   */
  initMap(map: google.maps.Map) {
    this.map = map;
  }

  flipHorizontal() {
    this.shared.removeBodyClasses();
    this.shared.showDirectionpanel = false;
    this.document.body.classList.add('map-fullblock');
  }

  flipVertical() {
    this.shared.removeBodyClasses();
    this.shared.showDirectionpanel = false;
  }

  fullScreen() {
    this.shared.removeBodyClasses();
    this.shared.showDirectionpanel = true;
    this.document.body.classList.add('map-fullscreen');
  }

  close() {
    this.shared.showDirectionpanel = false;
    const varclassName = this.document.body.className;
    this.shared.removeBodyClasses();
    if (varclassName === 'map-only') {
      this.document.body.classList.add('leftnav-only');
      this.searchInput.nativeElement.value = '';
    } else if (varclassName === '') {
      this.document.body.classList.add('chat-only');
    } else {
      this.document.body.classList.add('chat-only');
    }
  }

  /**
   * Initialize search input autocomplete
   * Use map center to set bounds
   * Send out searched place to parent map component to handle
   */
  private async _initSearch() {
    await this.mapsAPILoader.load();

    this._autocomplete = new google.maps.places.Autocomplete(this.searchInput.nativeElement);
    if (this.map) {
      this._autocomplete.bindTo('bounds', this.map);
    }
    this._autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = this._autocomplete.getPlace();
      this.search.emit(place);
    });
  }
}
