<div class="container">
    <div class="col-md-12">
        <mat-accordion class="headers-align">
            <button *ngIf="data.editable" mat-menu-item (click)="doAction('edit')">
                <span>Edit</span>
            </button>
            <button *ngIf="data.editable" mat-menu-item (click)="doAction('delete')">
                <span>Remove</span>
            </button>
            <button mat-menu-item (click)="doAction('copy')">
                <span>Copy</span>
            </button>
        </mat-accordion>
    </div>
</div>
