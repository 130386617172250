<h1 mat-dialog-title>Internet Connected?</h1>
<div mat-dialog-content class="text-center">
    <img src="assets/images/network-issue.png" class="img-thumbnail" />
    <br>
    <br>
    <p>It appears you have lost connectivity to the internet.</p>
    <p>If the problem persists, please refresh the page.</p>
</div>
<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="refresh()">Refresh</button>
</mat-dialog-actions>
