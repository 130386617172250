/// <reference types="@types/googlemaps" />
import { Injectable, NgZone } from '@angular/core';
// @ts-ignore
import {} from 'googlemaps';
import { MapViewComponent } from './map-view.component';
import { IGps } from './map.interface';
import { GPSService } from '~common/gps.service';
import { MapConfig } from './map.config';
import { MapTrackingMode } from './map.enum';

@Injectable()
export class MapGpsService {

  private view: MapViewComponent;
  private imeiSubscriptions: Set<string> = new Set();

  constructor(
    private zone: NgZone,
    private gps: GPSService,
  ) {
    // Watch for gps data coming in
    // Data is subscribed for separately
    this.gps.data.subscribe(this.handleGpsData.bind(this));
  }

  setMapView(view: MapViewComponent) {
    this.view = view;
  }

  /**
   * Sub and unsub to IMEIs by retrieving from filtered assets and people
   * Diff new imeis with current and figure out which ones we need to 
   * subscribe and unsubscribe
   */
  refreshImeiSubscriptions(loadBreadcrumbs = false) {
    // IMEIs are retreived from selected assets and people
    const imeis = this.view.filter.getSubscribedImeis();

    // Instead of unsubscribing and resubscribing to IMIEs on every refresh
    // We determine changes and unsubscribe / subscribe as needed
    const imeisToSubscribe: Set<string> = new Set();
    // We start by assuming all imies need to be unsubscribed, then remove
    // from list as needed
    const imeisToUnsubscribe: Set<string> = new Set(this.imeiSubscriptions);

    for (let imei of imeis) {
      // If IMEI not in current list, add to current and to-subscribe list
      if (!this.imeiSubscriptions.has(imei)) {
        imeisToSubscribe.add(imei);
        this.imeiSubscriptions.add(imei);
      }
      // If IMEI in current list, remove from to-unsubscribe list
      imeisToUnsubscribe.delete(imei);
    }

    // Now we need to remove IMEIs from current list that is no longer subscribed
    for (let imei of Array.from(this.imeiSubscriptions)) {
      if (!imeis.includes(imei)) {
        this.imeiSubscriptions.delete(imei);
      }
    }

    // Subscribe + unsubscribe
    this.gps.subscribeToImeis(Array.from(imeisToSubscribe));
    this.gps.unsubscribeToImeis(Array.from(imeisToUnsubscribe))

    if (this.view.filter.breadcrumbs) {
      this.view.filter.selectedPeople
      .forEach(item => this.showGPSHistorical(item.beamId, item.imei, item.reportingStart, new Date()));
    }
  }

  /**
   * Unsubscribe from all currently subscribed IMEIs
   */
  unsubscribeFromImeis() {
    this.gps.unsubscribeToImeis(Array.from(this.imeiSubscriptions));
    this.imeiSubscriptions.clear();
  }

  /**
   * Handles gps data coming in from api-gps when subscribed to IMEIs
   * Creates gps marker, adds it to marker list and recalculates viewport
   * 
   * @param gps IGps
   */
  handleGpsData(gps: IGps) {
    const marker = this.view.marker.createPeopleMarker(gps);
    this.view.markers.push(marker);
    this.view.recalculateViewport();
  }

  showGPSHistorical(title, deviceKey, reportingStart, reportingEnd, lastLocation?, live?, limit?) {
    this.zone.runOutsideAngular(() => {
      this.gps.post('gps/list', {
        imei: deviceKey,
        from: new Date(reportingStart),
        to: reportingEnd ? new Date(reportingEnd) : new Date(),
      }).subscribe((data) => {
        let gpsList: Array<IGps> = data.meta_data.gps || [];

        if (gpsList.length === 0) {
          if (lastLocation) {
            title = lastLocation;
            gpsList = [lastLocation];
          } else if (!live) {
            this.view.marker.addCurrentMarker(MapConfig.Marker.NoGpsTitle);
            return;
          }
        } else {
          this.view.current = gpsList[0];
        }

        if (limit && gpsList.length > limit) {
          gpsList = gpsList.slice(-limit);
        }

        this.view.markers = this.view.markers.concat(gpsList.map(gps =>
          this.view.marker.createPeopleMarker(gps, true)));
        this.view.recalculateViewport();
      });
    });
  }
}