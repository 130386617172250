import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaterialModule } from './material';
import { ShareDataService } from './share-data.service';
import { LoadingService } from './loading.service';
import { LocationService } from './location.service';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { IOService } from '~common/io.service';
import { GPSService } from '~common/gps.service';
import { MeterService } from '~common/meter.service';
import { DispatchService } from '~common/dispatch.service';
import { PageService } from '~common/page.service';
import { RideService } from '~common/ride.service';

@NgModule({
  imports: [
    MaterialModule,
  ],

})
export class CommonSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CommonSharedModule,
      providers: [
        ShareDataService,
        LoadingService,
        LocationService,
        AuthGuard,
        AuthService,
        IOService,
        GPSService,
        MeterService,
        DispatchService,
        NotificationService,
        PageService,
        RideService
      ],
    }
  }
}
