import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { DialogModule } from '~dialog/dialog.module';
import { StaticModule } from '~static/static.module';
import { ChatModule } from '~chat/chat.module';
import { VideoModule } from '~video/video.module';
import { MapModule } from '~map/map.module';
import { PageService } from '~common/page.service';
import { IOService } from '~common/io.service';
import { ShareDataService } from '~common/share-data.service';
import { AuthService } from '~common/auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventsCalendarComponent } from '~chat/events-calendar/events-calendar.component';
import { AlarmFormModule } from './alarm-form/alarm-form.module';
import { EmergencyEmulatorModule } from './emergency-emulator/emergency-emulator.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { CommonSharedModule } from '~common/common-shared.module';

@NgModule({
  declarations: [
    AppComponent,
    EventsCalendarComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBdiNxE31mGELQwmau05oOcld_1Kk2ED0s',
      libraries: ['places', 'geometry'],
    }),
    ReactiveFormsModule,
    // ToastrModule.forRoot(),
    AppRoutingModule,
    DialogModule,
    StaticModule,
    CommonModule,
    ChatModule,
    MapModule,
    VideoModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AlarmFormModule,
    EmergencyEmulatorModule,
    CommonSharedModule.forRoot()
  ],
  providers: [
    AuthService,
    ShareDataService,
    PageService,
    IOService,
  ],
  bootstrap: [AppComponent],
  exports: [EventsCalendarComponent],
})
export class AppModule {
}
