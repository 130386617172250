import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  templateUrl: './network-error.component.html',
})

export class NetworkErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NetworkErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {}

  refresh(): void {
    window.location.href = '';
  }
}
