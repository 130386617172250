<div class="container">
    <h2 mat-dialog-title>RSVP</h2>
    <div class="row">
        <mat-form-field class="w-100">
            <input matInput placeholder="RSVP Title" required="true" value="" [(ngModel)]="messageTitle">
        </mat-form-field>
    </div>
    <div class="row">
        <div class="rsvp-box green">
            <input matInput value="{{messageGreen}}" required="true" [(ngModel)]="messageGreen">
        </div>
        <div class="rsvp-box amber">
            <input matInput value="{{messageAmber}}" required="true" [(ngModel)]="messageAmber">
        </div>
    </div>
    <div class="row">
        <div class="rsvp-box double red">
            <input matInput value="{{messageRed}}" required="true" [(ngModel)]="messageRed">
        </div>
    </div>
    <div class="row">
        <mat-dialog-actions>
            <button mat-raised-button class="green-btn" (click)="createRSVP()">Done</button>
            <button mat-raised-button class="orange-btn" mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </div>
</div>