import { Injectable, Injector } from '@angular/core';
import * as io from 'socket.io-client';

import { Env } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ShareDataService } from './share-data.service';
//import { PageService } from './page.service';
import { EventEmitter } from 'events';
import { Router } from '@angular/router';

interface WsException {
  status: number,
  message: string,
}

@Injectable({
  providedIn: 'root'
})
export class IOService extends EventEmitter {
  public connected = false;
  private baseUrl = Env.apiUrl;
  private socket: SocketIOClient.Socket;
//  private pageService: PageService;
  private auth: AuthService;
  //private page: any;

  constructor(
    private readonly shared: ShareDataService,
    private readonly injector: Injector,
    private readonly router: Router,

  ) {
    super();

    this.auth = this.injector.get(AuthService);
    // this.pageService = this.injector.get(PageService);

    if (this.auth.isLoggedIn()) {
      this.connect();
    }
  }

  connect() {
    if (this.socket) {
      return false;
    }

    this.socket = io(this.baseUrl, {
      query: {
        accessToken: this.auth.getToken()
      },
      transports: ['websocket'],
      path: '/socket.io-v2'
    });
    this.socket.on('error', () => { });
    this.socket.on('error/auth', () => {
      console.log('AUTH ERROR')
      this.disconnect();
    });
    this.socket.on('connect', () => { });
    //this.socket.on('reconnect', this.reconnect.bind(this));
    this.socket.on('app/init', this.init.bind(this));
    this.socket.on('user/list', this.initUsers.bind(this));
    this.socket.on('user/me', this.initMe.bind(this));
    this.socket.on('friend/list', this.initFriends.bind(this));
    this.socket.on('iot/list', this.initAssets.bind(this));
    this.socket.on('page/list', this.initPages.bind(this));
    this.socket.on('gps/list', this.initPeople.bind(this));
    this.socket.on('location/list', this.initPlaces.bind(this));
    this.socket.on('media/list', this.initMedia.bind(this));
    this.socket.on('event/list', this.initEvents.bind(this));
    this.socket.on('exception', (err: WsException) => {
      if (err.status === 401) {
        this.logout();
      } else {
        console.error(err);
      }
    });

    // @ts-ignore
    this.emit('io/init');
    this.connected = true;

    // this.pageService.current.subscribe((page) => {
    //   this.page = page;
    // });
  }

  // Helg: never sent from server
  // reconnect() {
  //   if (this.page) {
  //     this.send('page/subscribe', { pageId: this.page._id });
  //   }
  // }

  disconnect() {
    if (this.socket) {
      // @ts-ignore
      this.emit('logout');
      this.socket.emit('logout');
      this.socket = null;
      this.logout();
    }
  }

  send(event: string, data: any, callback?: Function): boolean | Promise<any> {
    if (this.socket) {
      if (callback) {
        this.socket.emit(event, data, callback);
        return true;
      } else {
        return new Promise((resolve, reject) => {
          this.socket.emit(event, data, (err: Error, result: any) => {
            if (err) {
              return reject(err);
            }
            resolve(result);
          });
        });
      }
    }
  }

  listen(event: string, fn: Function) {
    this.socket.on(event, fn);
  }

  private init() {
  }

  private initUsers({ users }) {
    this.shared.users = users.reduce((mappedUsers, user) => {
      mappedUsers.set(user._id, user);
      return mappedUsers
    }, new Map());
  }

  private initMe({ me }) {
    this.shared.user = me;
  }

  private initFriends({ friends }) {
    this.shared.friends = friends;
  }

  private initAssets({ items }) {
    this.shared.assets = items;
  }
  private initPages({ pages }) {
    this.shared.pages = pages.filter(page => !!page.location);
  }
  private initPeople({ items }) {
    this.shared.people = items;
  }
  private initPlaces({ items }) {
    this.shared.places = items;
  }
  private initMedia({ items }) {
    this.shared.media = items;
  }
  private initEvents({ items }) {
    this.shared.events = items;
  }

  logout(): void {
    this.disconnect();
    this.auth.clearToken();
    this.router.navigate(['/account/login']);
  }
}
