import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '~common/auth.guard';
import { VideoComponent } from '~video/video.component';
import { AlarmFormComponent } from './alarm-form/alarm-form.component';
import { EmergencyEmulatorComponent } from './emergency-emulator/emergency-emulator/emergency-emulator.component';
import { CustomPreloadingStrategy } from './custom-preloading-strategy';


const routes: Routes = [
  { path: '', canActivate: [AuthGuard], redirectTo: 'page', pathMatch: 'full' },
  { path: 'video/:id', canActivate: [AuthGuard], component: VideoComponent },
  { path: 'alarm', canActivate: [AuthGuard], component: AlarmFormComponent, data: { preload: false } },
  { path: 'emergency', canActivate: [AuthGuard], component: EmergencyEmulatorComponent, data: { preload: false } },
  { path: 'page', loadChildren: () => import('./page/page.module').then(mod => mod.PageModule) },
  { path: 'driver', loadChildren: () => import('./driver/driver.module').then(mod => mod.DriverModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: CustomPreloadingStrategy })],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy]
})
export class AppRoutingModule {
}
