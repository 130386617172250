import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ListFriendsDialogComponent } from './list-friends/list-friends.component';
import { ChatViewComponent } from './chat-view.component';
import { InputMenuDialogComponent } from './input-menu-dialog/input-menu-dialog.component';
import { MessageMenuDialogComponent } from './message-menu-dialog/message-menu-dialog.component';
import { LocationMessageMenuDialogComponent } from './location-message-menu-dialog/location-message-menu-dialog.component';
import { GPSTrackerToggleComponent } from './gps-tracker-toggle/gps-tracker-toggle.component';
import { GPSTimeRangeComponent } from './gps-tracker-toggle/gps-time-range/gps-time-range.component';
import { MaterialModule } from '~common/material';
import { VideoModule } from '~video/video.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MediaUploadComponent } from './upload-media-dialog/upload-media.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropZoneDirective } from '~chat/drag-drop.directive';
import { ImageViewer } from './image-viewer/image-viewer.component';
import { VideoViewer } from './video-player/video-player.component';
import { CreateEventComponent } from './create-event/create-event-component';
import { CreateRSVPComponent } from './create-rsvp/create-rsvp.component';
import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { EventMessageMenuDialogComponent } from './event-message-menu-dialog/event-message-menu-dialog.component';
import { RSVPResponseListDialogComponent } from './rsvp-response-list/rsvp-response-list.component';
import { RSVPMessageMenuDialogComponent } from './rsvp-message-menu-dialog/rsvp-message-menu-dialog.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { TextTimeInputComponent } from './create-event/text-time-input/text-time-input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { TextMaskModule } from 'angular2-text-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocationInputComponent } from './location-input/location-input.component';
import { VitalsAddDialogComponent } from './vitals-add-dialog/vitals-add-dialog.component';
import { MedicalReportDialogComponent } from './medical-report-dialog/medical-report-dialog.component';
import { SymptomsAddDialogComponent } from './symptoms-add-dialog/symptoms-add-dialog.component';

@NgModule({
  declarations: [
    ListFriendsDialogComponent,
    ChatViewComponent,
    InputMenuDialogComponent,
    MessageMenuDialogComponent,
    LocationMessageMenuDialogComponent,
    EventMessageMenuDialogComponent,
    RSVPMessageMenuDialogComponent,
    GPSTrackerToggleComponent,
    GPSTimeRangeComponent,
    MediaUploadComponent,
    DropZoneDirective,
    ImageViewer,
    VideoViewer,
    CreateEventComponent,
    CreateRSVPComponent,
    RSVPResponseListDialogComponent,
    MedicalReportDialogComponent,
    SymptomsAddDialogComponent,
    VitalsAddDialogComponent,
    DocumentViewerComponent,
    TextTimeInputComponent,
    PhoneInputComponent,
    LocationInputComponent,
  ],
  imports: [
    NgxMaterialTimepickerModule,
    NgxDropzoneModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    VideoModule,
    ReactiveFormsModule,
    NgxDocViewerModule,
    TextMaskModule,
    NgSelectModule
  ],
  entryComponents: [
    ListFriendsDialogComponent,
    InputMenuDialogComponent,
    MessageMenuDialogComponent,
    LocationMessageMenuDialogComponent,
    EventMessageMenuDialogComponent,
    RSVPMessageMenuDialogComponent,
    GPSTrackerToggleComponent,
    GPSTimeRangeComponent,
    MediaUploadComponent,
    ImageViewer,
    VideoViewer,
    CreateEventComponent,
    CreateRSVPComponent,
    EventsCalendarComponent,
    RSVPResponseListDialogComponent,
    MedicalReportDialogComponent,
    SymptomsAddDialogComponent,
    VitalsAddDialogComponent,
    DocumentViewerComponent,
  ],
  exports: [
    ChatViewComponent,
    PhoneInputComponent
  ],
})
export class ChatModule {}
