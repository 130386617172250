import { Component, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './input-menu-dialog.component.html',
  styleUrls: ['./input-menu-dialog.component.css'],
})

export class InputMenuDialogComponent {
  public message = '';

  constructor(public dialogRef: MatDialogRef<InputMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  doAction(message, action) {
    this.dialogRef.close({ action, message });
  }
}
