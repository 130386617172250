import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './medical-report-dialog.component.html',
  styleUrls: ['./medical-report-dialog.component.css']
})

export class MedicalReportDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<MedicalReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
