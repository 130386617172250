import { Inject, Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
//import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { Env } from '../../environments/environment';
import { PageService } from './page.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

  private messaging: any;
  private token: string;

  constructor(
    private api: ApiService,
    private snackBar: MatSnackBar,
    private pageService: PageService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    try {
      firebase.initializeApp(Env.firebase);
      this.messaging = firebase.messaging();
    } catch (err) {
      console.warn('Firebase notifications failed', err);
    }
  }

  /* private get toastr(): ToastrService {
   return this.injector.get(ToastrService);
  } */

  async init() {
    if (await this.getPermission()) {
      this.token = await this.messaging.getToken();
      this.updateToken();
      this.messaging.onMessage(this.handleNotification.bind(this));
    }
  }

  updateToken() {
    this.api.post('token/setFCMToken', {
      deviceId: navigator.userAgent,
      deviceType: 'browser',
      token: this.token,
    }).subscribe();
  }

  async getPermission(): Promise<boolean> {
    try {
      await this.messaging.requestPermission();
      return true;
    } catch (err) {
      switch (err.code) {
        case 'messaging/permission-blocked':
          this.snackBar.open('You have blocked notifications in your browser. '
            + 'You will not receive notifications unless you enable it.', 'x', {
              verticalPosition: 'bottom',
              horizontalPosition: 'left',
            });
          break;
        case 'messaging/permission-default':
          this.snackBar.open('You have not enabled notifications in your browser. '
          + 'You will not receive notifications unless you do.', 'x', {
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
          });
      }
      console.warn('Notification permission denied');
      return false;
    }
  }

  handleNotification(payload) {
    const { title, body, icon } = payload.notification;
    this.browserNotifyIfInactive(title, body, icon);
  }

  browserNotifyIfInactive(title, body, icon, page = null) {
    if (this.document.hidden || !this.document.hasFocus()) {
      // @ts-ignore
      const notification = new Notification(title, { body, icon, requireInteraction: true, tag: 'beam' });
      notification.addEventListener('click', () => {
        if (page) {
          this.pageService.selectPage(page);
        }
        window.focus();
        notification.close();
      });
      return true;
    } else {
      return false;
    }
  }
}
