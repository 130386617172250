<h1 mat-dialog-title>Edit Bio</h1>
<div mat-dialog-content class="bioContent">
    <div class="row">
        <div class="col-12 operters-row text-center ">
            <img [src]="userProfilePic ? userProfilePic : '../../assets/images/unknown.png'"
                 onError="this.src='../../assets/images/unknown.png'"
                 alt="" class="profilePic rounded-circle"
                (click)="triggerFileUploadClick($event)">
        </div>
    </div>
    <input #ProfilePic type='file' (change)="onSelectFile($event)" hidden>
    <div class="row">
        <div class="col-md-6 col-xs-12">
            <mat-form-field class="example-full-width w-100">
                <input matInput [(ngModel)]="userData.firstName" name="FirstName" placeholder="First Name">
            </mat-form-field>
        </div>
        <div class="col-md-6 col-xs-12">
            <mat-form-field class="example-full-width w-100">
                <input matInput [(ngModel)]="userData.lastName" name="LastName" placeholder="Last Name">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-xs-12">
            <mat-form-field class="w-100">
                <input matInput [(ngModel)]="userData.doB" name="DOB" [value]="userData.doB" [matDatepicker]="picker"
                    placeholder="DoB">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-xs-12">
            <mat-radio-group class="example-radio-group custom-radio" name="Gender" [(ngModel)]="userData.gender">
                <mat-radio-button class="example-radio-button" *ngFor="let gender of GenderList" [value]="gender">
                    {{gender}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
            <mat-form-field class="w-100">
                <input matInput #AddressSearch [(ngModel)]="userData.addressOne" name="address1"
                    placeholder="Enter Street Address">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12">
            <mat-form-field class="w-100">
                <input matInput [(ngModel)]="userData.addressTwo" name="address2"
                    placeholder="Enter City, Zip Code, Country">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-xs-12">
            <mat-dialog-actions class="justify-content-end">
                <button mat-raised-button class="green-btn" (click)="saveProfileData()">Save</button>
                <button mat-button class="orange-btn" mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </div>
</div>
