import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IOService } from '~common/io.service';
import { FailureDialogComponent } from '~dialog/failure/failure.component';
import { LocationService } from '~common/location.service';

@Component({
  templateUrl: './upload-media.component.html',
  styleUrls: ['./upload-media.component.css'],
})

export class MediaUploadComponent {
  fileList: any = [];
  loading = false;
  private readonly pageId: string;

  constructor(public dialogRef: MatDialogRef<MediaUploadComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private readonly io: IOService,
              public locationService: LocationService) {

    this.pageId = this.data.pageId;

  }

  async onFilesAdded(files: File[]) {
    this.fileList = [];
    for (const file of files) {
      const fileData = await this.fileParser(file);
      this.fileList.push(fileData);
    }
  }

  async fileParser(file) {
    return new Promise(async (s, r) => {
      const location = await this.getCurrentLocation();
      const data: any = {
        filename: file.name,
        size: file.size,
        mimetype: file.type,
        pageId: this.pageId,
        location: location
      };
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: ProgressEvent) => {
        const content = (e.target as FileReader).result;
        data.content = content;
        s(data);
      };
    });
  }

  async getCurrentLocation() {
    return new Promise(s => {
      this.locationService.current.subscribe(location => {
        s(location);
      });
    });
  }

  resetDropZone(dropzone) {
    this.fileList = [];
    dropzone.reset();
  }

  upload(dropzone) {
    this.loading = true;
    this.io.send('media/upload', this.fileList, (err, data) => {
      this.loading = false;
      this.resetDropZone(dropzone);
      this.dialogRef.close();
      if (err) {
        this.dialog.open(FailureDialogComponent, { data: { Title: err } });
      }
    });
  }
}
