import { Component } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

import { ApiService } from '~common/api.service';
import { SuccessDialogComponent } from '~dialog/success/success.component';
import { FailureDialogComponent } from '~dialog/failure/failure.component';
import { IOService } from '~common/io.service';
import { Env } from '../../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './edit-bio.component.html',
  styleUrls: ['./edit-bio.component.css']
})
export class EditBioDialogComponent {
  GenderList: string[] = ['male', 'female'];
  public FirstName: string;
  public LastName: string;
  public DOB: string;
  public Gender: string;
  userData: any = {};
  userProfilePic: any = '../../assets/images/unknown.png';
  profilePicChange: boolean = false;

  @ViewChild('AddressSearch', {
    read: ElementRef,
    static: true
}) public AddressElementRef: ElementRef;
  @ViewChild('ProfilePic', { static: true }) profilePic: ElementRef;

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<EditBioDialogComponent>,
    public dialog: MatDialog,
    private readonly io: IOService
  ) {
    this.io.send('user/getme', {}, this.setData.bind(this))
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setData(error, data) {
    if (error) console.log(error);
    this.userData = data;
    if (data.image) {
      this.userProfilePic = `${Env.apiUrl}${Env.uploads}/${data.image.filename}`;
    }
  }

  saveProfileData() {
    const updateData = { profile: this.userData };
    this.io.send('user/update', updateData, (err, data) => {
      if (err) {
        this.dialog.open(FailureDialogComponent, {
          width: '300px',
          data: { Message: 'Failed to update profile' },
        });
        this.onNoClick();
      } else {
        this.dialog.open(SuccessDialogComponent, {
          width: '300px',
          data: { Message: 'Profile updated successfully' },
        });
        if (this.profilePicChange) {
          this.uploadProfilePic();
        }
        this.onNoClick();
      }

    });

  }

  uploadProfilePic() {
    this.io.send('user/pic', this.userProfilePic, (err, data) => {
      if (err) console.log('upload failed');
    });
  }

  onSelectFile(event) {
    this.profilePicChange = true;
    if (event.target.files && event.target.files[0]) {
      this.userProfilePic = event.target.files[0];
      const reader = new FileReader();
      this.userProfilePic = reader.readAsDataURL(event.target.files[0]);
      this.userProfilePic = event.target.files[0];
      reader.onload = (e) => {
        this.userProfilePic = e.target['result'];
      };
    }
  }

  triggerFileUploadClick(event: any) {
    event.preventDefault();
    this.profilePic.nativeElement.click();
  }
}
