import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.css'],
})

export class VideoViewer {

    public isVideo :boolean = false;
    public isAudio :boolean = false;
    public url: string;

    constructor(public dialogRef: MatDialogRef<VideoViewer>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog
        ) {
        this.url = this.data.url;
        this.isAudio = this.data.isAudio;
        this.isVideo = this.data.isVideo;
    }
}
