<div *ngIf="shared.user && shared.user.profile" class="top-panel-row d-flex justify-content-between w-100">
    <div class="user-title-head w-100 text-left pl-0">
        <div *ngIf="mapType === MapType.Home" class="dp-main-block float-left">
            <img [src]="shared.user.imageUrl ? shared.user.imageUrl : '../../assets/images/unknown.png'"
                alt="" class="logo-main" onError="this.src='../../assets/images/unknown.png'">
            <span class="userid-line">{{shared.user.beamId.IdOne}}</span>
        </div>
        <span *ngIf="mapType === MapType.Page" class="userid-line">{{page.title}}</span>
    </div>
    <div class="menu-item-block d-flex align-items-center pull-right">
        <span (click)="flipHorizontal()" class="ico-item align-control">
            <img alt="" src="../../assets/images/align-bottom.png">
        </span>
        <span (click)="flipVertical()" class="ico-item align-control">
            <img alt="" src="../../assets/images/align-right.png">
        </span>
        <span (click)="fullScreen()" class="ico-item align-control">
            <img alt="" src="../../assets/images/full-screen.png">
        </span>
        <span (click)="close()" class="ico-item align-control">
            <img alt="" src="../../assets/images/close.png">
        </span>
    </div>
</div>
<div class="panel-row w-100 search-input d-flex align-items-center mb-3">
    <input #search class="form-control" placeholder="Search Map" type="text">
</div>
<div #directionsPanel [hidden]="!shared.showDirectionpanel" class="direction-setup"></div>
