import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.css'],
})

export class ImageViewer {
    public imageUrl: string;
    loading = true;
    constructor(public dialogRef: MatDialogRef<ImageViewer>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog
        ) {
        this.imageUrl = this.data.imageUrl;
    }
}
