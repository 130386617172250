<div class="hideDriver">
<div class="driver" [class.requested]="rideRequested" (mouseenter)="setAsSelectedTaxi()" [style.display]="show ? 'block' : 'none'">
  <img *ngIf="taxi && taxi.img" [src]="taxi.img" width="40" height="40">
  <img *ngIf="!taxi || !taxi.img" src="assets/images/ride-request.png" width="40" height="40">
  <div *ngIf="taxi">
    <div>
      <p>{{taxi.car}}</p>
      <p>{{taxi.plate}}</p>
    </div>
    <p><b>{{taxi.driver}}</b></p>
    <p *ngIf="taxi.distance"><i>{{taxi.distance}} away</i></p>
  </div>
  <div *ngIf="!taxi">
    Select driver from map or Submit ride
  </div>
  <div *ngIf="taxi" class="buttons">
    <button class="btn green-btn" (click)="requestRide()">Request Ride</button>
  </div>
</div>
</div>
