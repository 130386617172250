import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule} from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { NgSelectModule } from '@ng-select/ng-select';
import { MapViewComponent } from './map-view.component';
import { MapFilterComponent } from './map-filter/map-filter.component';
import { MapMarkerService } from './map.marker';
import { MapGpsService } from './map.gps';
import { MapFilterService } from './map.filter';
import { MapHeaderComponent } from './map-header/map-header.component';
import { RideDriverComponent } from '~map/driver/driver.component';

@NgModule({
  declarations: [
    MapViewComponent,
    MapFilterComponent,
    MapHeaderComponent,
    RideDriverComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AgmCoreModule,
    AgmDirectionModule,
    MatMenuModule,
    NgSelectModule,
    MatSlideToggleModule,
  ],
  exports: [
    MapViewComponent,
  ],
  providers: [
    MapMarkerService,
    MapGpsService,
    MapFilterService,
  ],
})
export class MapModule {}
