<div class="schedule">
  <mat-form-field>
    <input matInput
           #scheduledTimeTooltip="matTooltip"
           (click)="scheduledTimeTooltip.show()"
           [(ngModel)]="scheduledTimeText"
           (ngModelChange)="setScheduledTimeFromText($event)"
           matTooltip="Examples:
              - in 15 minutes
              - in 45 minutes
              - in 2 hours
              - tomorrow at 5:15pm
              - next monday 8am
              - day after tomorrow 12pm
              - 25 feb 2pm"
           matTooltipClass="scheduled-time-hint"
           matTooltipHideDelay="500"
           matTooltipPosition="right"
           [placeholder]="placeholder"
           type="text">
  </mat-form-field>
  <p>{{ scheduledTimeFormatted }}</p>
</div>
