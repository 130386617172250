import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './message-menu-dialog.component.html',
  styleUrls: ['./message-menu-dialog.component.css']
})

export class MessageMenuDialogComponent implements OnInit {
  public message = '';

  constructor(public dialogRef: MatDialogRef<MessageMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.message = this.data.message.body;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  doAction(message, action, data = null) {
    this.dialogRef.close({ action, message, data });
  }
}
