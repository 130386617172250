import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { ShareDataService } from '~common/share-data.service';
import { ApiService } from '~common/api.service';
import { TextTimeInputComponent } from '~chat/create-event/text-time-input/text-time-input.component';

@Component({
  templateUrl: './location-message-menu-dialog.component.html',
  styleUrls: ['./location-message-menu-dialog.component.css'],
})

export class LocationMessageMenuDialogComponent implements OnInit {
  @ViewChild("GeofenceExpirationDate", { static: true }) GeofenceExpirationDate: TextTimeInputComponent;
  title = '';
  geofenceRadius = 0;
  geofenceExpiration: Date;
  showDirectionButton = true;
  showNavigationButton = true;
  addressLoading = true;
  address: any;

  constructor(private api: ApiService,
    public dialogRef: MatDialogRef<LocationMessageMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shareDataService: ShareDataService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer) {

    if (this.data.message.data.geofence) {
      this.geofenceRadius = this.data.message.data.geofenceRadius;
      this.geofenceExpiration = this.data.message.data.geofenceExpiration;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.title = this.data.message.data.gps.title;
    this.getLocation(this.data.message.data.gps);
    this.getAddress();
  }

  showDirection(message) {
    this.shareDataService.ShowMapDirectionFromChat.emit(message.data.gps);
    this.shareDataService.showDirectionpanel = true;
    this.onNoClick();

  }

  getNavigateUrl(message) {
    if (!message || !message.data || !message.data.gps) {
      return '#';
    }
    return this.sanitizer.bypassSecurityTrustUrl(
      `beamnav:${message.data.gps.lat},${message.data.gps.long},${message.data.gps.title}`
    );
  }

  updateRemove(message, action) {
    message.data.gps.title = this.title;
    this.shareDataService.location.emit({ message, action });
    this.onNoClick();
  }

  getLocation(message) {
    this.showDirectionButton = false;
    this.showNavigationButton = false;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const crd = pos.coords;
          if (crd.latitude.toFixed(4) !== message.lat.toFixed(4) && crd.longitude.toFixed(4) !== message.long.toFixed(4)) {
            this.showDirectionButton = true;
            this.showNavigationButton = true;
          } else {
            this.showDirectionButton = false;
            this.showNavigationButton = false;
          }
        });
    }
  }

  toggleEta(message) {
    message.data.eta = !message.data.eta;
    this.updateRemove(message, 'update');
  }

  addGeofence() {
    if (this.geofenceRadius > 0) {
      this.data.message.data.geofence = true;
      this.data.message.data.geofenceRadius = this.geofenceRadius;
      this.data.message.data.geofenceExpiration = this.geofenceExpiration;
    }
    this.updateRemove(this.data.message, 'update');
  }

  setGeofenceExpirationDate(expanded: boolean) {
    if (expanded) {
      this.GeofenceExpirationDate.setTime(this.geofenceExpiration);
    }
  }

  private getAddress() {
    const geocoder = new google.maps.Geocoder();
    const lat = this.data.message.data.gps.lat;
    const long = this.data.message.data.gps.long;
    const latlng = new google.maps.LatLng(lat, long);
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0] && results[0].formatted_address) {
        this.address = results[0].formatted_address;
      }
      this.addressLoading = false;
    });
  }
}
