import { Component, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  templateUrl: './rsvp-response-list.component.html',
  styleUrls: ['./rsvp-response-list.component.css']
})
export class RSVPResponseListDialogComponent {

  // @ViewChild('SearchInput', { static: false }) searchInput: ElementRef;

  public title: string;
  public responses: any[];
  
  constructor(
    public dialogRef: MatDialogRef<RSVPResponseListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.message.body;
    this.responses = data.message.data.responses;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
