<div class="container">
  <h2>BeamLive Terms and Conditions</h2>
  <div class="col-md-12 terms-condtions">
    <hr>
    <p><strong>1.&nbsp;</strong> Your use of BeamLive’s products, software and services (referred to collectively herein as the “Services”) is subject to the terms of a legal agreement between you and BeamLive. “BeamLive” means Beam Live Inc. a Delaware Corporation based in California United States. This document explains how the agreement is made up and sets out some of the terms of that agreement. Unless otherwise agreed in writing with BeamLive, your agreement with BeamLive will always include, at a minimum, the terms and conditions set out in this document. These are referred to below as the “Terms”. In addition to the Terms, your agreement with BeamLive will also include the terms of any order form, subscriber agreement or similar purchase agreement (whether evidenced in written or electronic form) by and between you and either BeamLive or a BeamLive Affiliate Party (as defined below) pursuant to which you acquire the right to use the Services (the “Subscriber Agreement”).</p>
    <p><strong>2.&nbsp;</strong> Prior to your use of the Services, you shall evidence your acceptance of the Terms either (a) electronically as contemplated in BeamLive’s website at page.beam.live or (b) in other manner and/or medium as acceptable to BeamLive at its discretion. Without limitation to the foregoing, if for any reason you fail to so evidence your acceptance of the Terms, your use of the Services constitutes your acceptance of the Terms.</p>
    <p><strong>3.&nbsp;</strong> You agree to use the Services only for purposes that are permitted by (a) the Terms and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions (including any laws regarding the export of data or software to and from the United States or other relevant countries).You agree that you will not engage in any activity that interferes with or disrupts the Services (or the servers and networks which are connected to the Services). You agree that you will not reproduce, duplicate, copy, sell, trade or resell the Services for any purpose.</p>
    <p><strong>4.&nbsp;</strong> You understand that all information (such as data files, written text, computer software, maps, photographs, videos or other images) which you may have access to as part of, or through your use of, the Services are the sole responsibility of the person from which such content originated. All such information is referred to below as the “Content”.</p>
    <p><strong>5.&nbsp;</strong> You acknowledge and agree that BeamLive (or BeamLive’s licensors) own all legal right, title and interest in and to the Services, including any intellectual property rights which subsist in the Services (whether those rights happen to be registered or not, and wherever in the world those rights may exist). You further acknowledge that the Services may contain information which is designated confidential by BeamLive and that you shall not disclose such information without BeamLive’s prior written consent. Except as specifically provided otherwise in a written agreement by and between you and BeamLive, nothing in the Terms gives you a right to use any of BeamLive’s trade names, trademarks, service marks, logos, domain names, and other distinctive brand features.</p>
    <p><strong>6.&nbsp;</strong> <strong>TO THE FULLEST EXTENT PERMITTED BY LAW, BEAMLIVE,&nbsp;</strong><strong>THE BEAMLIVE AFFILIATED PARTIES, EACH OF THEIR LICENSORS AND ALL OTHER&nbsp;</strong>
      <b>THIRD PARTIES PROVIDING ANY PRODUCTS OR OTHER SERVICES USED IN CONNECTION WITH THE SERVICES THAT ARE THE SUBJECT OF THE TERMS (INCLUDING, BUT NOT LIMITED&nbsp;TO, ANY WIRELESS CARRIERS PARTNERS, OTHER WIRELESS CARRIERS, SOFTWARE PROVIDERS, PARTIES SELLING COUPONS OR SIMILAR PRODUCTS FOR THE PURCHASE OF GOODS&nbsp;OR SERVICES, HANDSET AND/OR OTHER HARDWARE MANUFACTURERS, AND CONTENT PROVIDERS [“THE OTHER THIRD PARTIES” AND COLLECTIVELY WITH BEAMLIVE, THE BEAMLIVE AFFILIATED&nbsp;PARTIES AND EACH OF THEIR LICENSORS, THE “SERVICE PROVIDERS”]), AND ALL SUCH ENTITIES’ OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES, AND THE YOUR USE THEREOF.&nbsp;</b><strong>YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED "AS IS" AND “AS AVAILABLE”. BEAMLIVE, THE BEAMLIVE AFFILIATED PARTIES, EACH OF THEIR LICENSORS AND ALL OTHER SERVICE PROVIDERS FURTHER EXPRESSLY DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOU, ON BEHALF OF YOURSELF AND YOUR PERSONAL REPRESENTATIVES, HEIRS, EXECUTORS, ADMINISTRATORS, AGENTS, AND ASSIGNS, UNDERSTAND ACKNOWLEDGE AND AGREE THAT BEAMLIVE, THE BEAMLIVE AFFILIATED PARTIES, EACH OF THEIR LICENSORS AND ALL OTHER SERVICE PROVIDERS DO NOT REPRESENT OR WARRANT TO YOU THAT:</strong></p>
    <p><strong>(A) YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS,</strong></p>
    <p><strong>(B) YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,</strong></p>
    <p><strong>(C) ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE,&nbsp;</strong></p>
    <p><strong>(D)&nbsp;</strong><strong>THAT THE BEAMLIVE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS,</strong><strong>&nbsp;AND/OR</strong></p>
    <p><strong>(E) THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.</strong></p>
    <p>
      <br>
    </p>
    <p><strong>7. WITHOUT LIMITATION TO THE TERMS OF PARAGRAPH 6 ABOVE,&nbsp;</strong><strong>BEAMLIVE, THE BEAMLIVE AFFILIATED PARTIES, EACH OF THEIR LICENSORS AND ALL OTHER SERVICE PROVIDERS&nbsp;</strong><strong>ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY</strong></p>
    <p><strong>(A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,&nbsp;</strong></p>
    <p><strong>(B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF BEAMLIVE SERVICES,&nbsp;</strong></p>
    <p><strong>(C) ANY UNAUTHORIZED ACCESS TO OR USE OF ITS SERVERS AND/OR ANY AND ALL INFORMATION STORED THEREIN,</strong></p>
    <p><strong>(D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE BEAMLIVE SERVICES OR ANY THE FUNCTIONS CONTAINED IN THE BEAMLIVE SERVICES,&nbsp;</strong></p>
    <p><strong>(E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE BEAMLIVE SERVICES OR BY ANY THIRD PARTY, AND/OR&nbsp;</strong></p>
    <p><strong>(F) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE BEAMLIVE SERVICES AND ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE BEAMLIVE SERVICES&nbsp;</strong></p>
    <p><strong>(G) ANY INACCURACY, LACK OF AVAILABILITY AND/OR SIMILAR PROBLEM WITH THE BEAMLIVE SERVICES.</strong></p>
    <p><strong>8. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM BEAMLIVE, THE BEAMLIVE AFFILIATED PARTIES; EACH OF THEIR LICENSORS OR ANY OTHER SERVICE PROVIDERS OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.&nbsp;</strong>
      <b>BEAMLIVE AND THE BEAMLIVE AFFILIATED PARTIES DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY ANY THIRD PARTY (INCLUDING, BUT NOT LIMITED TO,&nbsp;</b><strong>ALL&nbsp;</strong><strong>OTHER SERVICE PROVIDERS) IN CONNECTION WITH THE SERVICES, OR ANY HYPERLINKED SOFTWARE OR WEBSITE, AND NEITHER BEAMLIVE NOR ANY&nbsp;</strong><strong>BEAMLIVE AFFILIATED PARTIES, OR THEIR RESPECTIVE LICENSORS&nbsp;</strong><strong>WILL BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY OF THESE THIRD PARTIES.&nbsp;</strong></p>
    <p><strong>9. YOU, ON BEHALF OF YOURSELF AND YOUR PERSONAL REPRESENTATIVES, HEIRS, EXECUTORS, ADMINISTRATORS, AGENTS, AND ASSIGNS, EXPRESSLY UNDERSTAND AND AGREE THAT BEAMLIVE, THE BEAMLIVE AFFILIATED PARTIES, AND ITS LICENSORS AND/OR ANY OTHER SERVICE PROVIDERS SHALL NOT BE LIABLE TO YOU AND/OR YOUR PERSONAL REPRESENTATIVES, HEIRS, EXECUTORS, ADMINISTRATORS, AGENTS, AND ASSIGNS FOR:</strong></p>
    <p><strong>(A) ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS;</strong></p>
    <p><strong>(B) ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF:</strong></p>
    <p><strong>(I) ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OF ANY REPRESENTATION OR WARRANTY MADE BY, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND, ANY THIRD PARTY PROVIDING ANY PRODUCTS OR SERVICES INCORPORATED INTO OR USED IN CONNECTION WITH THE SERVICES;</strong></p>
    <p><strong>(II) ANY CHANGES WHICH BEAMLIVE MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);</strong></p>
    <p><strong>(III) THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES;</strong></p>
    <p><strong>(IV) YOUR FAILURE TO PROVIDE BEAMLIVE WITH ACCURATE INFORMATION; OR</strong></p>
    <p><strong>(V) YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL.</strong></p>
    <p><strong>10.</strong><strong> BEAMLIVE’S LIABILITY TO YOU&nbsp;</strong><strong>AND/OR YOUR PERSONAL REPRESENTATIVES, HEIRS, EXECUTORS, ADMINISTRATORS, AGENTS, AND ASSIGNS</strong><strong>&nbsp;FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF (A) THE AMOUNT PAID, (IF ANY), BY YOU TO BEAMLIVE FOR THE BEAMLIVE SERVICES DURING THE TERM OF YOUR USE OF SUCH SERVICES AND (B) $10.00.</strong><strong>&nbsp;THE LIMITATIONS ON BEAMLIVE’S LIABILITY TO YOU IN THIS PARAGRAPH 10 AND PARAGRAPH 9 ABOVE SHALL APPLY WHETHER OR NOT BEAMLIVE HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.&nbsp;</strong><strong>THE SERVICES ARE INTENDED ONLY AS PERSONAL SERVICES FOR INDIVIDUAL USE AND SHOULD NOT BE USED OR RELIED ON AS AN EMERGENCY SYSTEM, OR USED IN CONNECTION WITH ANY HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE, OR ANY OTHER APPLICATION IN WHICH THE FAILURE OR INACCURACY OF THAT APPLICATION OR THE BEAMLIVE SERVICES COULD LEAD DIRECTLY TO, OR COULD REASONABLY BE EXPECTED TO LEAD TO, DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR PROPERTY DAMAGE.</strong></p>
    <p><strong>11. YOU,&nbsp;</strong><strong>ON BEHALF OF YOURSELF AND YOUR PERSONAL REPRESENTATIVES, HEIRS, EXECUTORS, ADMINISTRATORS, AGENTS, AND ASSIGNS,&nbsp;</strong><strong>ACKNOWLEDGE AND AGREE THAT BEAMLIVE HAS OFFERED THE BEAMLIVE SERVICES AND ENTERED INTO THIS AGREEMENT IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN. YOU FURTHER ACKNOWLEDGE AND AGREE THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THIS AGREEMENT REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND&nbsp;</strong><strong>BEAMLIVE, THE BEAMLIVE AFFILIATED PARTIES, EACH OF THEIR LICENSORS AND ALL OTHER SERVICE PROVIDERS</strong><strong>, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THIS AGREEMENT FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND BEAMLIVE. BEAMLIVE WOULD NOT BE ABLE TO PROVIDE THE BEAMLIVE SERVICES TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS AND DISCLAIMERS.</strong></p>
    <p><strong>12</strong>. The Terms, and your relationship with BeamLive under the Terms, shall be governed by the laws of the State of California without regard to its conflict of laws provisions. You and BeamLive agree to submit to the exclusive jurisdiction of the courts located within the California to resolve any legal matter arising from the Terms. Notwithstanding this, you agree that BeamLive shall still be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.</p>
  </div>

</div>