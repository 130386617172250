<div class="container">
   <table class="table table-sm">
      <colgroup>
        <col style="background-color: #e9ecef" />
      </colgroup>
       <thead class="thead-light">
           <tr>
               <th style="width: 180px;">{{ data.headers[0] }}</th>
               <th style="width: 90px;">{{ data.headers[1] }}</th>
               <th style="width: 90px;">{{ data.headers[2] }}</th>
               <th style="width: 90px;">{{ data.headers[3] }}</th>
           </tr>
       </thead>
       <tbody>
           <tr *ngFor="let symptom of data.symptoms">
               <td>{{ symptom.name }}</td>
               <td><input class="col-md-12" [(ngModel)]="symptom.onsetDate" type="date" /></td>
               <td><input class="col-md-12" [(ngModel)]="symptom.entry" type="text" /></td>
               <td><input class="col-md-12" [(ngModel)]="symptom.unit" type="text" /></td>
           </tr>
       </tbody>
    </table>

   <mat-dialog-actions>
     <button mat-raised-button class="green-btn" (click)="add()">Save</button>
     <button mat-button class="orange-btn" mat-dialog-close>Cancel</button>
   </mat-dialog-actions>
</div>
