import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
//import Sugar from 'sugar-date';
import * as chrono from 'chrono-node';

//Sugar.Date.extend();

@Component({
  selector: 'app-text-time-input',
  templateUrl: './text-time-input.component.html',
  styleUrls: ['./text-time-input.component.css']
})
export class TextTimeInputComponent {
  @Output() scheduledTime: EventEmitter<Date> = new EventEmitter();
  @Input() placeholder = 'Scheduled Time';
  public scheduledTimeText = '';
  public scheduledTimeFormatted = '';

  constructor() { }

  setTime(time: Date) {
    if (time) {
      this.setScheduledTimeFromDate(time);
      this.scheduledTimeText = this.scheduledTimeFormatted;
    } else {
      this.scheduledTimeText = '';
      this.scheduledTimeFormatted = '';
    }
  }

  setScheduledTimeFromText(scheduledTimeText: string) {
    //const parsedDateTime = Sugar.Date.create(scheduledTimeText);
    const parsedDateTime = chrono.parseDate(scheduledTimeText)
    this.setScheduledTimeFromDate(parsedDateTime);
  }

  setScheduledTimeFromDate(parsedDateTime: Date) {
    if (parsedDateTime instanceof Date) {
      if (parsedDateTime < new Date()) {
        this.scheduledTimeFormatted = 'This time has already passed';
        this.scheduledTime.emit(null);
      //} else if (parsedDateTime.isBefore(new Sugar.Date.create('in 5 minutes'))) {
      } else if (parsedDateTime < chrono.parseDate('in 5 minutes')) {
        this.scheduledTimeFormatted = 'Rides must be scheduled after 5 minutes';
        this.scheduledTime.emit(null);
      //} else if (parsedDateTime.isAfter(new Sugar.Date.create('after 7 days'))) {
      } else if (parsedDateTime > chrono.parseDate('in 7 days')) {
        this.scheduledTimeFormatted = 'Rides must be scheduled within 7 days';
        this.scheduledTime.emit(null);
      } else {
        //this.scheduledTimeFormatted = parsedDateTime.full();
        this.scheduledTimeFormatted = formatDate(parsedDateTime,'fullTime',' en-US');
        this.scheduledTime.emit(parsedDateTime);
      }
    } else {
      this.scheduledTimeFormatted = '';
      this.scheduledTime.emit(null);
    }
  }

}
