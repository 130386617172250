import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '~common/api.service';
import { Inject } from '@angular/core';
import { ShareDataService } from '~common/share-data.service';
import { SuccessDialogComponent } from '~dialog/success/success.component';
import { WarningDialogComponent } from '~dialog/warning/warning.component';
import { FailureDialogComponent } from '~dialog/failure/failure.component';
import { ConfirmationDialogComponent } from '~dialog/confirmation/confirmation.component';
import { PageService } from '~common/page.service';
import { IOService } from '~common/io.service';

@Component({
  selector: 'app-list-friends',
  templateUrl: './list-friends.component.html',
  styleUrls: ['./list-friends.component.css']
})
export class ListFriendsDialogComponent implements OnInit {
  memberList = [];
  insideMemberList = [];
  outsideMemberList = [];
  groups = [];
  filteredGroups = [];
  nonBeamerToInvite = null;
  invitingNonBeamer = false;
  page;

  @ViewChild('SearchInput', { static: true }) searchInput: ElementRef;

  constructor(
    private shared: ShareDataService,
    public dialogRef: MatDialogRef<ListFriendsDialogComponent>,
    private api: ApiService,
    private io: IOService,
    private pageService: PageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.pageService.current.subscribe(data => this.data = data);
    this.page = this.data;
    this.listAllFriends();
    this.listGroups();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  listAllFriends() {
    const membersInPageIds = this.pageService.members
      .map(member => member && member.user ? member.user._id : null)
      .filter(memberId => !!memberId);
    const nonBeamMembers = this.pageService.members
      .filter(member => !member.user);

    const insideMemberList = [];
    const outsideMemberList = [];
    this.memberList = this.pageService.members.map((_member) => {
      const member = { ...this.shared.users.get(_member.user._id) };
      if (membersInPageIds.includes(member._id) || this.page.owner._id === member._id) {
        const memberInPage = this.pageService.members.find(f => f.user._id === member._id);
        member.addedToPage = true;
        if (this.page.owner._id === member._id) {
          member.accepted = 'accepted';
        } else {
          member.accepted = memberInPage ? memberInPage.accepted : 'pending';
        }
        insideMemberList.push(member);
      } else {
        member.addedToPage = false;
        outsideMemberList.push(member);
      }
      return member;
    });
    nonBeamMembers.forEach((nonBeamFriend) => {
      insideMemberList.push({
        displayName: nonBeamFriend.email || nonBeamFriend.phoneNumber,
        phoneNumber: nonBeamFriend.phoneNumber,
        username: nonBeamFriend.email,
        addedToPage: true,
      });
    });

    const friendsOutsidePage = this.shared.friends.filter((friend) => {
      friend.addedToPage = false;
      if (!membersInPageIds.includes(friend._id)) {
        this.memberList.push(friend);
        return true;
      }
      return false;
    });

    this.insideMemberList = insideMemberList;
    this.outsideMemberList = outsideMemberList.concat(friendsOutsidePage);

    setTimeout(() => this.applySearch(), 0);
  }

  listGroups() {
    this.api.get('/page').subscribe((response) => {
      if (response && response.meta_data && response.meta_data) {
        let groups = response.meta_data.pages;
        groups = groups.filter(page => page.flag !== 'friends');
        // Only show pages with more than 1 friend
        // @@TODO
        //groups = groups.filter(page => page.members && page.members.length > 1);
        groups = groups.concat(this.shared.groups);
        groups = groups.reduce((x, y) => x.findIndex(e => e._id === y._id) < 0 ? [...x, y] : x, []); // unique by _id
        this.groups = groups;
      }
    });
  }

  toggleUser(user) {
    if (user.addedToPage) {
      this.removeUser(user);
    } else {
      this.addUser(user);
    }
  }

  addUser(user) {
    if (this.page.flag === 'friends') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Create new page?',
          message: 'Adding someone to a friends page will create a new page with the three of you. Would you like to create a new page?',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const memberIds = this.pageService.members.map(_member => _member.user._id);
          memberIds.push(user._id);
          const page: any = {};
          const friend = this.pageService.members[0].user;
          const friendName = friend.beamId && friend.beamId.IdOne ? friend.beamId.IdOne : friend.username;
          page.title = `${friendName}, ${user.beamId.IdOne}`;
          page.location = this.page.location;
          page.profile = this.page.profile;
          page.appId = this.page.appId;
          page.memberIds = memberIds;
          this.dialog.closeAll();
          this.api.post('page', page)
            .subscribe((res) => {
              this.data = res;
              this.pageService.selectPage(this.data.meta_data.page);
              this.dialog.open(SuccessDialogComponent, {
                width: '300px',
                data: { Message: 'New page created and your friend invited to it' },
              });
            }, (err) => {
              this.dialog.open(FailureDialogComponent, {
                width: '300px',
                data: { Message: err.error.message_detail }
              });
            });
        }
      });
    } else if (this.page.flag === 'iot') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Create new page?',
          message: 'Adding someone to an IoT page will create a new page with the two of you. Would you like to create a new page?',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const memberIds = [user];
          const page: any = {};
          page.title = `${this.page.title} (new)`;
          page.location = this.page.location;
          page.profile = this.page.profile;
          page.appId = this.page.appId;
          page.memberIds = memberIds;
          page.profile = this.page.profile;
          page.copiedFromFlag = 'iot';
          this.dialog.closeAll();
          this.api.post('page', page)
            .subscribe((res) => {
              this.data = res;
              this.pageService.selectPage(this.data.meta_data.page);
              this.dialog.open(SuccessDialogComponent, {
                width: '300px',
                data: { Message: 'New page created and your friend invited to it' },
              });
            }, (err) => {
              this.dialog.open(FailureDialogComponent, {
                width: '300px',
                data: { Message: err.error.message_detail }
              });
            });
        }
      });
    } else {
      const data = {
        friendPhoneNumber: user.phoneNumber,
        friendEmail: user.username,
        role: 'ROLE_MEMBER',
      };


      this.io.send('member/add', { pageId: this.page._id, memberId: user._id });
      this.handleFriendResponse(user, { status: true }, true);
    }
  }
  removeUser(user) {
    this.io.send('member/delete', { pageId: this.page._id, memberId: user._id });
    this.handleFriendResponse(user, { status: true }, false);
  }

  inviteNonBeamer(phoneOrEmail: string) {
    this.invitingNonBeamer = true;
    let phoneNumber: string, email: string;
    if (phoneOrEmail.startsWith('+')) {
      phoneNumber = phoneOrEmail;
    } else {
      email = phoneOrEmail;
    }
    const data = {
      phoneNumber,
      email,
      targetPageId: this.page._id
    };

    this.api.post(`account/invite-non-beamer`, data)
      .subscribe((res) => { this.handleFriendResponse(null, res, true); });
  }

  handleFriendResponse(user, result, addedToPage) {
    if (this.invitingNonBeamer) {
      this.invitingNonBeamer = false;
      this.searchInput.nativeElement.value = '';
      this.applySearch();
    }
    if (result.status) {
      if (user && addedToPage) {
        const insideMemberList = [...this.insideMemberList];
        user.addedToPage = addedToPage;
        insideMemberList.unshift(user);
        this.insideMemberList = insideMemberList;
        this.outsideMemberList = this.outsideMemberList.filter(friend => friend._id !== user._id);
      } else if (user) {
        const outsideMemberList = [...this.outsideMemberList];
        user.addedToPage = addedToPage;
        outsideMemberList.unshift(user);
        this.outsideMemberList = outsideMemberList;
        this.insideMemberList = this.insideMemberList.filter(friend => friend._id !== user._id);
      }
      // Refresh page so friends list is updated
    } else if (result.code === 203) {
      this.dialog.open(WarningDialogComponent, {
        width: '300px',
        data: { Message: result.message },
      });
    } else {
      this.dialog.open(FailureDialogComponent, {
        width: '200px',
        data: { Message: result.message },
      });
    }
  }

  handleGroupResponse(user, result) {
    if (result.status) {
      const insideMemberList = [...this.insideMemberList];
      user.addedToPage = true;
      insideMemberList.unshift(user);
      this.insideMemberList = insideMemberList;
      this.outsideMemberList = this.outsideMemberList.filter(friend => friend._id !== user._id);
    }
  }

  inviteGroup(group) {
    const friendsToInvite = group.members
      .map(member => member.user)
      .filter(friend => !this.insideMemberList.some(insideFriend => insideFriend._id === friend._id));


    if (friendsToInvite.length === 0 ) {
      this.dialog.open(WarningDialogComponent, {
        width: '300px',
        data: { Message: 'Everyone in this group has already been added to this page.' },
      });
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Invite everyone in group?',
        message: 'You\'re inviting everyone on this group to this page. Are you sure?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        friendsToInvite.forEach((friendToInvite) => {
          const data = {
            friendPhoneNumber: friendToInvite.phoneNumber,
            friendEmail: friendToInvite.username,
            role: 'ROLE_MEMBER',
          };

          this.api.post(`page/addFriendToPage/${this.page._id}`, data)
            .subscribe((res) => { this.handleGroupResponse(friendToInvite, res); });
        });
      }
    });
  }

  applySearch() {
    const term = this.searchInput.nativeElement.value;
    this.insideMemberList = this.memberList.filter(friend => this.friendSearchFilter(term, friend, true));
    if (term.trim() !== '') {
      let users = Array.from(this.shared.users).map(user => user[1]);
      users = users.filter(user => !this.insideMemberList.some(insideFriend => insideFriend._id === user._id));
      this.outsideMemberList = users.filter(friend => this.friendSearchFilter(term, friend, false));
      if (this.insideMemberList.length === 0) {
        if (term.startsWith('+') && term.length >= 10) {
          this.nonBeamerToInvite = term;
        } else if (/\S+@\S+/.test(term)) {
          this.nonBeamerToInvite = term;
        } else {
          this.nonBeamerToInvite = null;
        }
      }
    } else {
      this.outsideMemberList = this.memberList.filter(member => !member.addedToPage);
      this.nonBeamerToInvite = null;
    }
    this.filteredGroups = this.groups.filter(group => this.groupSearchFilter(term, group));
  }

  handleSearchInput(event) {
    this.applySearch();
  }

  friendSearchFilter(term, friend, inPage) {
    const _term = term.toLowerCase();

    if (friend._id === this.shared.user._id) {
      return false;
    }

    if (!!friend.addedToPage !== inPage) {
      return false;
    }

    if (!term) {
      return true;
    }

    if (friend.username.toLowerCase().startsWith(_term)) {
      return true;
    }

    if (friend.profile && friend.profile.firstName && friend.profile.firstName.toLowerCase().startsWith(_term)) {
      return true;
    }

    if (friend.profile && friend.profile.lastName && friend.profile.lastName.toLowerCase().startsWith(_term)) {
      return true;
    }

    if (friend.beamId && friend.beamId.IdOne && friend.beamId.IdOne.toLowerCase().startsWith(_term)) {
      return true;
    }

    if (friend.phoneNumber && friend.phoneNumber.startsWith(_term)) {
      return true;
    }

    return false;
  }

  groupSearchFilter(term, group) {
    const _term = term.toLowerCase();

    if (!_term) {
      return false;
    }

    if (group.title && group.title.split(' ').some(t => t.toLowerCase().startsWith(term))) {
      return true;
    }

    return false;
  }
}
