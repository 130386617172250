import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILocation } from '../beam-forms/beam-forms.interface';

const LOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 3000,
  maximumAge: 1000,
};

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public loading = true;
  private _current = new BehaviorSubject<ILocation>({ lat: 0, lng: 0 });
  public current = this._current.asObservable();

  constructor() {
    this.getLocation();
  }

  getLocation() {
    if (localStorage.getItem('location')) {
      this._current.next(JSON.parse(localStorage.getItem('location')));
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.updateCurrentLocation.bind(this),
        this.retryLocationWatch.bind(this),
        LOCATION_OPTIONS,
      );
      navigator.geolocation.watchPosition(
        this.updateCurrentLocation.bind(this),
        null,
        LOCATION_OPTIONS,
      );
    }
  }

  updateCurrentLocation(pos: Position) {
    this.loading = false;
    const { latitude, longitude } = pos.coords;
    const location = { lat: latitude, lng: longitude };
    this._current.next(location);
    localStorage.setItem('location', JSON.stringify(location))
  }

  retryLocationWatch() {
    navigator.geolocation.clearWatch(this.updateCurrentLocation.bind(this));
    this.getLocation();
  }
}
