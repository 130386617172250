<div class="container d-flex d-flex-row">
    <div>
        <table cellpadding="10">
            <tbody>
                <tr>
                    <td><strong>Latitude</strong></td>
                    <td *ngIf="data.location">{{ data.location.lat }}</td>
                </tr>
                <tr>
                    <td><strong>Longitude</strong></td>
                    <td *ngIf="data.location">{{ data.location.long ? data.location.long : data.location.lng }}</td>
                </tr>
                <tr *ngIf="data.location && data.location.heading">
                    <td><strong>Heading</strong></td>
                    <td>{{data.location.heading}}</td>
                </tr>
                <tr *ngIf="data.location && data.location.speedFormatted">
                    <td><strong>Speed</strong></td>
                    <td>{{data.location.speedFormatted}}</td>
                </tr>
                <tr *ngIf="data.location && data.location.tsFormatted">
                    <td><strong>Timestamp</strong></td>
                    <td>{{data.location.tsFormatted}}</td>
                </tr>
            </tbody>
        </table>
        <hr *ngIf="data.address || addressLoading" />
        <div *ngIf="addressLoading" class="address">
            <img src="/assets/images/map-pointer.png" />
            <p>Fetching address...</p>
        </div>
        <div *ngIf="data.address" class="address"> 
            <img src="/assets/images/map-pointer.png" />
            <p>{{data.address}}</p>
        </div>
    </div>
    <div>
        <mat-accordion class="headers-align">
            <button mat-menu-item (click)="showDirection()">
                <span>Directions</span>
            </button>
            <a [href]="getNavigateUrl()" mat-menu-item>
                <span>Navigation</span>
            </a>
            <button mat-menu-item mat-menu-item (click)="hidePin()">
                <span>Clear</span>
            </button>
            <mat-expansion-panel hideToggle class="map-expansion-panel" *ngIf="data.mapType === MapType.Page">
                <mat-expansion-panel-header class="map-expansion-panel-header">
                    <button mat-menu-item (click)="LocationTitleInput.focus()">
                        <span>Save</span>
                    </button>
                </mat-expansion-panel-header>
                <mat-form-field class="full-width">
                    <input #LocationTitleInput [(ngModel)]="title" class="full-width" matInput
                        placeholder="Location Title">
                </mat-form-field>
                <mat-action-row>
                    <button class="green-btn" mat-button (click)="saveTitle()">Save</button>
                    <button mat-button class="orange-btn" (click)="onNoClick()">Cancel</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
