<div class="emergencyEmulator">
  <div class="sources">
    <div class="emergencyTitle">Select Incoming Call Source</div>
    <div *ngFor="let s of sources">
      <button (click)="selectSource(s)" [class]="isSelected(s)">{{s.buttonText}}</button>
    </div>
  </div>
  <div class="inputs">
    <div class="emergencyTitle">Incoming Call Data</div>
    <input #Address [formControl]="address" placeholder="Address">
    <app-phone-input class="phoneNumber" [phoneInput]="phoneNumber" (phoneOutput)="phoneNumber = $event"></app-phone-input>
    <textarea [(ngModel)]="instructions"
              placeholder="Enter Special Instruction about Source And type/profile of Alarm or Alert"
              rows="5">
    </textarea>
    <button (click)="send()">Send</button>
  </div>
</div>
