<div class="container">
   <table class="table table-sm table-bordered">
       <thead class="thead-light">
           <tr>
               <th *ngFor="let header of data.headers">{{ header }}</th>
           </tr>
       </thead>
       <tbody>
           <tr *ngFor="let row of data.rows">
               <td *ngFor="let col of row">{{ col }}</td>
           </tr>
       </tbody>
   </table>
   <div *ngIf="data.comments">
        <div *ngFor="let comment of data.comments" class="instructions">
            <h3>{{ comment.title }}:</h3>
            <p>{{ comment.text }}</p>
        </div>
    </div>

</div>
