import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ApiService} from "~common/api.service";

@Component({
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent {
  public doc: string;
  public url: string;
  public filename: string;
  public loading = true;
  public fullScreen = false;

  constructor(public dialogRef: MatDialogRef<DocumentViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private api: ApiService
  ) {
    this.doc = this.data.doc;
    this.url = this.data.url;
    this.filename = this.data.filename;
  }

  fullscreen(){
    if (this.fullScreen) {
      this.dialogRef.updateSize('850px', '600px');
    } else {
      this.dialogRef.updateSize('100vw', '100vh');
    }
    this.fullScreen = !this.fullScreen;
  }

  download() {
    this.api.getMedia(this.url).subscribe(res => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(res);
      document.body.appendChild(a);
      a.download = this.filename;
      a.click();
      document.body.removeChild(a);
    });
  }
}
