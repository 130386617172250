export enum MarkerType {
  Asset,
  Page,
  People,
  Place,
  Media,
}

export enum MapType {
  Home = 'home',
  Page = 'page',
}

export enum MapTrackingMode {
  Manual = 'manual',
  AutoSingle = 'auto (single)',
  AutoAll = 'auto (all)',
}
