<div class="container">
   <table class="table table-sm">
       <thead class="thead-light">
           <tr>
               <th>Item</th>
               <th>Entry</th>
               <th>Unit</th>
           </tr>
       </thead>
       <tbody>
           <tr>
               <td>Temperature</td>
               <td><input [(ngModel)]="data.temperature" type="text" /></td>
               <td>
                   <button class="btn btn-sm"
                     [class.btn-secondary]="data.temperatureUnit === 'F'"
                     [class.btn-inverse]="data.temperatureUnit === 'C'"
                     (click)="selectTemperatureUnit('F')">F</button>
                   <button class="btn btn-sm"
                     [class.btn-secondary]="data.temperatureUnit === 'C'"
                     [class.btn-inverse]="data.temperatureUnit === 'F'"
                     (click)="selectTemperatureUnit('C')">C</button>
               </td>
           </tr>
           <tr>
               <td>Blood Pressure</td>
               <td><input [(ngModel)]="data.bloodPressure" type="text" /></td>
               <td>mmHg</td>
           </tr>
           <tr>
               <td>Heart Rate</td>
               <td><input [(ngModel)]="data.heartRate" type="text" /></td>
               <td>Per Minute</td>
           </tr>
           <tr>
               <td>Oxygen Level</td>
               <td><input [(ngModel)]="data.oxygenLevel" type="text" /></td>
               <td>Percent</td>
           </tr>
           <tr>
               <td>Lung Test</td>
               <td></td>
               <td></td>
           </tr>
           <tr>
               <td>EKG</td>
               <td></td>
               <td></td>
           </tr>
       </tbody>
    </table>
    <div class="form">
        <textarea class="form-control"
            #autosize="cdkTextareaAutosize"
            [(ngModel)]="data.priorSurgeries"
            cdkAutosizeMaxRows="5"
            cdkAutosizeMinRows="2"
            cdkTextareaAutosize
            placeholder="Prior surgeries">
        </textarea>
        <textarea class="form-control"
            #autosize="cdkTextareaAutosize"
            [(ngModel)]="data.preConditions"
            cdkAutosizeMaxRows="5"
            cdkAutosizeMinRows="2"
            cdkTextareaAutosize
            placeholder="Any pre-conditions">
        </textarea>
        <textarea class="form-control"
            #autosize="cdkTextareaAutosize"
            [(ngModel)]="data.medications"
            cdkAutosizeMaxRows="5"
            cdkAutosizeMinRows="2"
            cdkTextareaAutosize
            placeholder="Medications currently taking">
        </textarea>
        <textarea class="form-control"
            #autosize="cdkTextareaAutosize"
            [(ngModel)]="data.allergies"
            cdkAutosizeMaxRows="5"
            cdkAutosizeMinRows="2"
            cdkTextareaAutosize
            placeholder="Known allergies">
        </textarea>
    </div>

   <mat-dialog-actions>
     <button mat-raised-button class="green-btn" (click)="add()">Add Entry</button>
     <button mat-button class="orange-btn" mat-dialog-close>Cancel</button>
   </mat-dialog-actions>
</div>
