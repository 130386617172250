import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-gps-time-range',
  templateUrl: './gps-time-range.component.html',
})

export class GPSTimeRangeComponent {
  public dateFrom = new Date();
  public timeFrom;
  public dateTo = new Date();
  public timeTo;

  constructor(
    public dialogRef: MatDialogRef<GPSTimeRangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  done() {
    const startDate = this.combineDateTime(this.dateFrom, this.timeFrom);
    const endDate = this.combineDateTime(this.dateTo, this.timeTo);
    this.dialogRef.close({ startDate, endDate });
  }

  combineDateTime(date, time) {
    const { hours, minutes } = this.parseTime(time);
    const _date = new Date(date.getTime());
    _date.setHours(hours, minutes);
    return _date;
  }

  parseTime(time12h) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');
    minutes = minutes;
    if (hours === '12') {
      hours = '00';
    }

    if (modifier.toUpperCase() === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return { hours, minutes };
  }
}
