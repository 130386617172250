<div class="container">
    <div class="col-md-12">
        <mat-accordion class="headers-align">
            <mat-expansion-panel *ngIf="data.editable && data.message.type !== 'rsvp-response'" hideToggle class="map-expansion-panel">
                <mat-expansion-panel-header class="map-expansion-panel-header">
                    <button mat-menu-item>
                        <span>Edit</span>
                    </button>
                </mat-expansion-panel-header>
                <mat-form-field class="full-width">
                    <textarea [(ngModel)]="message" class="full-width" matInput placeholder="Message"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
                <mat-action-row>
                    <button class="green-btn" mat-button (click)="doAction(this.data.message, 'update', message)">Update</button>
                    <button mat-button class="orange-btn" (click)="onNoClick()">Cancel</button>
                </mat-action-row>
            </mat-expansion-panel>
            <button *ngIf="data.editable" mat-menu-item (click)="doAction(this.data.message, 'delete')">
                <span>Remove</span>
            </button>
            <button *ngIf="data.message.type !== 'rsvp-response'" mat-menu-item (click)="doAction(this.data.message, 'copy')">
                <span>Copy</span>
            </button>
        </mat-accordion>
    </div>
</div>
