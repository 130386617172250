import {Component, Inject} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ApiService } from '~common/api.service';
import { SuccessDialogComponent } from '~dialog/success/success.component';
import { FailureDialogComponent } from '~dialog/failure/failure.component';

@Component({
  templateUrl: './verify-phone.component.html',
})
export class VerifyPhoneDialogComponent {

  constructor(
    private api: ApiService,
    public dialogRef: MatDialogRef<VerifyPhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private router: Router
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form: NgForm) {
    const values = form.value;
    
    const data = {
      phoneNumber: this.data.phone,
      token: values.OTP
    };

    this.api.post('account/register/verifyphone', data).subscribe((res) => {
      this.data = res;
      if (this.data.status === true ) {
        this.router.navigate(['/account/login']);
        this.dialog.open(SuccessDialogComponent, {
            width: '300px',
            data: { Message: this.data.message }
        });
        this.dialogRef.close();
      } else {
          this.dialog.open(FailureDialogComponent, {
          width: '200px',
          data: { Message: this.data.message }
        });
      }
      return;
    });
  }
}
