export const Env = {

  production: false,

  apiUrl: 'https://beamjs.helg.com/',
  gpsUrl: 'https://page-dev-gps.beam.live/',
  dispatchUrl: 'https://page-dev-dispatch.beam.live/',
  meterApiUrl: 'https://meter-dev-api.beam.live/',
  
  apiVersion: 'v2',
  meterApiVersion: 'api/v1',
  uploads: 'static',

  firebase: {
    apiKey: "AIzaSyBaarBS5TpbG4uMpQTrAqspBVKbwzVkY7k",
    authDomain: "beam-live-passenger.firebaseapp.com",
    databaseURL: "https://beam-live-passenger.firebaseio.com",
    projectId: "beam-live-passenger",
    storageBucket: "beam-live-passenger.appspot.com",
    messagingSenderId: "391484580180",
    appId: "1:391484580180:web:8dca7ed0a3513fa42628c1",
    measurementId: "G-MBQ6J1T667",
  },
  
};
