<div style="position: relative; width: 100%; height: 100%;">
  <div *ngIf="loading" class="loading-center">
    <p>Loading...</p>
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
  <div *ngIf="!loading"  class="document-button-div fullscreen-button-div" (click)="fullscreen()">
    <div [class]="fullScreen ? 'document-button fullscreen-button-icon-exit' : 'document-button fullscreen-button-icon'"></div>
  </div>
  <div *ngIf="!loading"  class="document-button-div download-button-div" (click)="download()">
    <div class="document-button download-button-icon"></div>
  </div>
  <ngx-doc-viewer [url]="doc" viewer="google" class="document-viewer" (loaded)="loading = false"
                  [style.display]="loading ? 'none' : 'block'"></ngx-doc-viewer>
</div>
