import { NgModule } from '@angular/core';
import { MaterialModule } from '~common/material';
import { AlarmFormComponent } from './alarm-form.component';
import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '~common/auth.guard';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  { path: 'alarm', component: AlarmFormComponent, canActivate: [ AuthGuard ] },
];

@NgModule({
  declarations: [
    AlarmFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    MaterialModule,

    RouterModule.forChild(routes),
  ],
  exports: [
    AlarmFormComponent
  ]
})
export class AlarmFormModule {
}
