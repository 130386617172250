import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { ConfirmationDialogComponent } from "./confirmation/confirmation.component";
import { EditBioDialogComponent } from "./edit-bio/edit-bio.component";
import { FailureDialogComponent } from "./failure/failure.component";
import { ChatImportDialogComponent } from "./chat-import/chat-import.component";
import { LocationDialogComponent } from "./location/location.component";
import { NetworkErrorDialogComponent } from "./network-error/network-error.component";
import { SuccessDialogComponent } from "./success/success.component";
import { TransferOwnershipDialogComponent } from "./transfer-ownership/transfer-ownership.component";
import { VerifyPhoneDialogComponent } from "./verify-phone/verify-phone.component";
import { WarningDialogComponent } from "./warning/warning.component";
import { CommonSharedModule } from "~common/common-shared.module";
import { MaterialModule } from "~common/material";
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SuccessDialogComponent,
    WarningDialogComponent,
    FailureDialogComponent,
    ConfirmationDialogComponent,
    NetworkErrorDialogComponent,
    EditBioDialogComponent,
    ChatImportDialogComponent,
    LocationDialogComponent,
    TransferOwnershipDialogComponent,
    VerifyPhoneDialogComponent,
  ],
  imports: [
    CommonSharedModule,
    MaterialModule,
    FormsModule,
    CommonModule,
  ],
  entryComponents: [
    SuccessDialogComponent,
    WarningDialogComponent,
    FailureDialogComponent,
    ConfirmationDialogComponent,
    NetworkErrorDialogComponent,
    EditBioDialogComponent,
    ChatImportDialogComponent,
    LocationDialogComponent,
    TransferOwnershipDialogComponent,
    VerifyPhoneDialogComponent,
  ],
})
export class DialogModule {}
