/// <reference types="@types/googlemaps" />
import { Component, OnInit, NgZone, ElementRef, ViewChild, Inject, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { DOCUMENT } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
// @ts-ignore
import {} from 'googlemaps';
import { LocationDialogComponent } from '~dialog/location/location.component';
import { ShareDataService } from '~common/share-data.service';
import { MapType, MapTrackingMode, MarkerType } from './map.enum';
import { PageService } from '~common/page.service';
import { LocationService } from '~common/location.service';
import { RideService } from '~common/ride.service';
import { IGps, IAssetMarker, IPeopleMarker, IPageMarker, IPlaceMarker, IMediaMarker, ILocation } from './map.interface';
import { MapConfig } from './map.config';
import { MapMarkerService } from './map.marker';
import { MapGpsService } from './map.gps';
import { MapFilterService } from './map.filter';
import { MapHeaderComponent } from './map-header/map-header.component';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css']
})

export class MapViewComponent implements OnInit {
  @Input() newRide: boolean = false;

  @ViewChild(MapHeaderComponent) header: MapHeaderComponent;
  @ViewChild('directionsPanel', { static: true }) directionsPanel: ElementRef;

  public MapType = MapType;
  public mapType = MapType.Home;
  public trackingMode: MapTrackingMode = MapTrackingMode.Manual;
  public MapTrackingMode = MapTrackingMode;
  public page;
  public map: google.maps.Map;
  public origin: any = '';
  public destination: any = '';
  public mapAddressSearchInput = '';
  public showMarker = true;
  public markers: Array<IAssetMarker | IPageMarker | IPlaceMarker | IPeopleMarker | IMediaMarker> = [];
  public renderOptions = {
    suppressMarkers: true,
  };
  public markerOptions = {
    origin: {
      icon: '../../assets/images/map-pointer.png',
      opacity: 0.8,
    },
    destination: {
      icon: 'null',
    },
  };
  ShowDirection = false;
  public current: ILocation;
  public showGeofence = false;
  public geofence = {
    latitude: 0,
    longitude: 0,
    radius: 0
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private mapsAPILoader: MapsAPILoader,
    public shared: ShareDataService,
    private pageService: PageService,
    private location: LocationService,
    public ride: RideService,
    public dialog: MatDialog,
    public marker: MapMarkerService,
    public gps: MapGpsService,
    public filter: MapFilterService,
  ) {
    this.marker.setMapView(this);
    this.gps.setMapView(this);
    this.filter.setMapView(this);
  }

  async ngOnInit() {
    // Ensure map library is loaded prior to working with it
    await this.mapsAPILoader.load();
  }

  ready(map: google.maps.Map) {
    this.map = map;
    this.map.setZoom(MapConfig.DefaultZoom);
    this.addCustomMapControls();

    this.map.addListener('dragend', () => this.setTrackingMode(MapTrackingMode.Manual));

    this.init();
    this.header.initMap(map);
  }

  init() {
    // Start keeping track of current location to use when needed
    this.watchCurrentLocation();

    // Watch for page changes
    this.pageService.current.subscribe((page) => this.pageChanged(page));

    // Handle requests by other components
    this.shared.ShowMapView.subscribe((data: any) => this.showMap(data));
    this.shared.ShowMapGPSLive.subscribe(this.showGps.bind(this));
    this.shared.ClearMap.subscribe(this.reset.bind(this));
    this.shared.ShowMapGeofence.subscribe((message) => this.setupGeofence(message));
    this.shared.ShowPointeronMap.subscribe((message: any) => this.showPointeronMap(message));
    this.shared.ShowMapDirectionFromChat.subscribe((data: any) => this.showMapDirectionFromChat(data));
    this.shared.ShowMapDirectionFromRide.subscribe(({ pickup, destination }) => this.showDirection(pickup, destination, 'Your ride'));
    this.shared.ShowMapDirection.subscribe((show: any) => {
      if (show) {
        const center = this.map.getCenter();
        if (this.current.lat !== center.lat() && this.current.long !== center.lng()) {
          this.showDirection(
            { lat: this.current.lat, lng: this.current.long },
            { lat: center.lat(), lng: center.lng() },
            MapConfig.Marker.DestinationTitle
          );
          this.showDirectionPanel();
        }
      } else {
        this.reset();
      }
    });
  }

  /**
   * Recalculates current map viewport
   * Gets bounds from markers and auto zooms and pans
   */
  recalculateViewport(zoom?: number) {
    if (this.markers.length === 0) {
      return;
    }
    if (this.markers.length === 1) {
      this.map.setCenter(this.markers[0].m.getPosition());
      this.map.setZoom(zoom ? zoom : MapConfig.DefaultZoom);
      return;
    }

    if (this.trackingMode === MapTrackingMode.AutoAll) {
      const bounds = new google.maps.LatLngBounds();
      // @ts-ignore
      const hasLatest = !!this.markers.find(m => m.isLatest);
      for (let marker of this.markers) {
        if (hasLatest && (marker.type === MarkerType.People || marker.type === MarkerType.Asset)) {
          marker = marker as IPeopleMarker;
          if (marker.isLatest && this.filter.getTrackingImeis().includes(marker.gps.imei)) {
            bounds.extend(marker.m.getPosition());
          }
        } else {
          bounds.extend(marker.m.getPosition());
        }
      }
      this.map.panToBounds(bounds, 100);
    } else if (this.trackingMode === MapTrackingMode.AutoSingle) {
      const imeis = this.filter.getTrackingImeis();
      if (imeis && imeis.length > 1) {
        this.setTrackingMode(MapTrackingMode.AutoAll);
        this.recalculateViewport(zoom);
      } else {
        const marker = this.markers.find((marker: IPeopleMarker) =>
          marker.isLatest && marker.gps.imei === imeis[0])
        this.map.setCenter(marker.m.getPosition());
      }
    }
  }

  /**
   * Keep track of user's current location
   * When changed and map doesn't have a center, center it
   */
  watchCurrentLocation() {
    this.location.current.subscribe((location) => {
      this.current = { lat: location.lat, long: location.lng };
      if (this.current && this.markers.length === 0) {
        this.marker.addCurrentMarker(MapConfig.Marker.YourTitle);
        this.recalculateViewport();
      }
    });
  }

  reset(setCurrentMarker = true) {
    this.shared.ShowDirection = false;
    this.shared.showDirectionpanel = false;
    this.showGeofence = false;
    this.filter.reset();

    this.origin = null;
    this.destination = null;
    this.gps.unsubscribeFromImeis();
    this.markers.forEach((marker) => {
      marker.m.setMap(null);
    });
    this.markers = [];
    this.setTrackingMode(MapTrackingMode.Manual);

    if (setCurrentMarker) {
      this.marker.addCurrentMarker(MapConfig.Marker.UnknownTitle);
      this.recalculateViewport();
    }
  }

  setTrackingMode(mode: MapTrackingMode) {
    this.trackingMode = mode;
  }

  mapClick(event) {
    const { lat, lng: long } = event.coords;
    this.reset(false);
    this.marker.addPlaceMarker(MapConfig.Marker.UnknownTitle, lat, long);
    this.setTrackingMode(MapTrackingMode.Manual);
  }

  search(place: google.maps.places.PlaceResult) {
    this.reset(false);
    const lat = place.geometry.location.lat();
    const long = place.geometry.location.lng();
    this.shared.ShowDirection = true;
    this.marker.addPlaceMarker(place.formatted_address, lat, long);
    this.recalculateViewport();
  }

  openMapDialog(location: IGps | ILocation) {
    console.log(this.mapType)
    this.dialog.open(LocationDialogComponent, {
      width: '580px',
      data: { location , mapType: this.mapType },
    });
  }

  private showGps({ live, isOwner, title, deviceKey,
    reportingStart, reportingEnd, lastLocation,
    showMap = true, showReport = false, iot = false }) {
    this.reset(false);
    this.mapType = MapType.Page;

    if (showMap) {
      this.shared.removeBodyClasses();
      this.shared.ShowMap = true;
    }
    if (showReport) {
      this.gps.showGPSHistorical(title, deviceKey, reportingStart, reportingEnd, lastLocation, false);
      this.setTrackingMode(MapTrackingMode.AutoAll);
    } else if (live || isOwner) {
      if (this.filter.breadcrumbs && reportingStart && live && !iot) {
        this.gps.showGPSHistorical(title, deviceKey, reportingStart, reportingEnd, null, live);
      }
      this.setTrackingMode(MapTrackingMode.AutoSingle);
      const tracking = this.trackingMode !== MapTrackingMode.Manual;
      this.filter.selectedPeople.push({ beamId: title, reportingStart, imei: deviceKey, selected: true, tracking });
      for (let item of this.shared.people) {
        if (item.imei === deviceKey) {
          item.selected = true;
          item.tracking = tracking;
        }
      }
      this.filter.apply();
    } else {
      this.reset(false);
      this.shared.ShowMapView.emit(true);
    }
  }

  private addCustomMapControls() {
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('mapFilters'));
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('mapButtons'));
    this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('rideDriver'));
  }

  private setupGeofence(message: any) {
    this.showGeofence = true;
    this.geofence = {
      latitude: message.attributes.data.gps.lat,
      longitude: message.attributes.data.gps.long,
      radius: message.attributes.data.geofenceRadius,
    };
  }

  private showPointer(location, zoom = null, ride = false, emergency = false, home = false) {
    this.reset(false);
    this.shared.ShowMap = true;
    this.shared.removeBodyClasses();

    if (!ride && !home) {
      this.mapType = MapType.Page;
    }
    if (emergency) {
      this.marker.addCurrentMarker(MapConfig.Marker.LinkTitle);
    } else {
      this.marker.addPlaceMarker(location.title, location.lat, location.long);
    }
    this.filter.selectedPlaces.push({ title: location.title, lat: location.lat, long: location.long });
    this.recalculateViewport(zoom);
  }

  /**
   * Handle changes to selected page
   * If page has been switched, clear the map and center to page or current
   * location
   * @param page
   */
  private pageChanged(page) {
    const pageSwitched = !this.page || !page || this.page._id !== page._id;
    this.page = page;
    if (pageSwitched) {
      if (page && page.location) {
        this.reset(false);
        this.marker.addCurrentMarker(page.title || MapConfig.Marker.PageTitle);
      } else {
        this.reset(true);
      }
    }
  }

  private showMap(data: any) {
    if (data.MapShow) {
      this.mapType = data.Type;
      this.reset();
      this.marker.addCurrentMarker(MapConfig.Marker.UnknownTitle);
    }
  }

  private showDirection(pickup: any, destination: any, destinationTitle: string) {
    this.reset();
    this.shared.ShowMap = true;
    this.shared.ShowDirection = true;
    this.origin = pickup;
    this.destination = destination;
    this.shared.removeBodyClasses();
    this.markerOptions.destination.icon = '../../assets/images/map-pointer.png';
  }

  private showDirectionPanel() {
    this.shared.showDirectionpanel = true;
    this.document.body.classList.add('map-fullscreen');
    this.document.body.classList.add('directions-view');
  }

  private showPointeronMap(message: any) {
    this.reset();
    this.showPointer(message.data.gps, message.data.zoom, message.ride, message.emergency, message.home);
  }

  private showMapDirectionFromChat(data: any) {
    if (this.current.lat !== data.lat && this.current.long !== data.long) {
      this.showDirection(
        { lat: this.current.lat, lng: this.current.long },
        { lat: data.lat, lng: data.long },
        MapConfig.Marker.DestinationTitle
      );
      this.showDirectionPanel();
    }
  }
}
