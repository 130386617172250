import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITaxi } from '../beam-forms/ride/ride.interface';

@Injectable({
  providedIn: 'root',
})
export class RideService {
  private _page = new BehaviorSubject<any>(null);
  private _taxis = new BehaviorSubject<ITaxi[]>([]);
  private _selectedTaxi = new BehaviorSubject<ITaxi>(null);
  public page = this._page.asObservable();
  public taxis = this._taxis.asObservable();
  public selectedTaxi = this._selectedTaxi.asObservable();
  public reset = new EventEmitter<boolean>();
  public driverSelect = new EventEmitter<any>();

  setPage(page?: any) {
    this._page.next(page);
  }

  setTaxis(taxis: ITaxi[]) {
    this._taxis.next(taxis);
  }

  setSelectedTaxi(taxi: ITaxi) {
    this._selectedTaxi.next(taxi);
  }
}
