<div style="text-align: center">
  <div style="display: inline-block;">
    <div style="border-bottom: 1px solid orange; margin-bottom: 10px;">
      <span class="title">Alarm</span>
      <img src="../../assets/images/beam-dp.png" alt="" width="40" height="40" style="float: right" class="beam-logo">
    </div>
    <div style="text-align: left;">
      <table class="alarm-form-table">
        <tbody>
        <tr>
          <td>Account number</td>
          <td>
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="message.accountNumber" [pattern]="'^[a-zA-Z0-9 ]*$'" autofocus>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>Name</td>
          <td>
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="name" [pattern]="'^[a-zA-Z ]*$'" required>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>Site Address</td>
          <td>
            <mat-form-field>
              <input matInput type="text"  #MapAddressSearch
                    [(ngModel)]="message.siteAddress" (ngModelChange)="triggerGoogleMapPlacesAPI()">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field>
              <input matInput type="tel" [(ngModel)]="message.pocPhone" placeholder="PoC Phone Number"
                     [textMask]="{ mask: phoneMasker }">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <input matInput type="tel" [(ngModel)]="message.pocPhone2" placeholder="PoC Phone Number"
                     [textMask]="{ mask: phoneMasker }">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <input matInput type="tel" [(ngModel)]="message.sitePhone" placeholder="Site Phone Number"
                     [textMask]="{ mask: phoneMasker }">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="message.alarmCode" placeholder="Alarm Code">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="message.alarmType" placeholder="Alarm Type" [pattern]="'^[a-zA-Z ]*$'">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="message.alarmZone" placeholder="Alarm Zone" [pattern]="'^[a-zA-Z ]*$'">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>Response</td>
          <td>
            <div>
              <mat-checkbox type="checkbox" [(ngModel)]="message.internalResponse" style="padding-right: 10px;"> Internal</mat-checkbox>
              <mat-checkbox type="checkbox" [(ngModel)]="message.externalResponse"> External</mat-checkbox>
            </div>
          </td>
        </tr>
        <tr>
          <td>Special instructions</td>
          <td colspan="2">
            <textarea name="specialInstructions" cols="40" rows="3" [(ngModel)]="message.specialInstructions"></textarea>
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <button (click)="activateAlarm()" class="activate-alarm" [disabled]="!validate()">Activate Alarm</button>
      </div>
    </div>
  </div>
</div>
