<div class="container">
    <div class="col-md-12 link-grid d-flex flex-wrap justify-content-around">

        <div class="linkgrid-list" (click)="select('media')">
            <img src="../../assets/images/Video.png" alt="" height="65">
            <span>Media</span>
        </div>
        <div class="linkgrid-list">
            <img src="../../assets/images/collaborate.png" alt="" height="65">
            <span>Collaborate</span>
        </div>

        <div class="linkgrid-list file-ico">
            <img src="../../assets/images/File.png" alt="" height="65">
            <span>Text</span>
        </div>

        <div class="linkgrid-list" (click)="select('pay')">
            <img src="../../assets/images/Pay.png" alt="" height="65">
            <span>Pay</span>
        </div>

        <div class="linkgrid-list" (click)="select('rsvp')">
            <img src="../../assets/images/rsvp.png" alt="" height="65">
            <span>RSVP</span>
        </div>

        <div class="linkgrid-list" (click)="select('event')">
            <img src="../../assets/images/Event.png" alt="" height="65">
            <span>Event</span>
        </div>

        <div class="linkgrid-list" (click)="select('place')">
            <img src="../../assets/images/Place.png" alt="" height="65">
            <span>Place</span>
        </div>

        <div class="linkgrid-list" (click)="select('people')">
            <img src="../../assets/images/People.png" alt="" height="65">
            <span>People</span>
        </div>

        <div class="linkgrid-list capture-ico">
            <img src="../../assets/images/IoT%20Files.png" alt="" height="65">
            <span>Capture</span>
        </div>

    </div>

</div>
