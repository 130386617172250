<app-video-invitation></app-video-invitation>
<div class="block-content w-100 px-2 float-left  position-relative">
    <div class="top-panel-row d-flex justify-content-between w-100">
        <div *ngIf="!page" class="user-title-head page-title w-100 pl-0"></div>
        <div *ngIf="page && !editingPage" class="user-title-head page-title w-100 pl-0">
            <span class="page-title-span" (click)="editPage()">{{page.title}}</span>
            <button *ngIf="page.flag !== 'friends' && !page.isDeleted" class="btn btn-sm page-title-btns ml-3 pr-0"
                (click)="editPage()">
                <img width="20" src="/assets/images/icons/edit.png" />
            </button>
            <button *ngIf="!page.isDeleted" class="btn btn-sm page-title-btns" (click)="removePage()">
                <img width="20" src="/assets/images/icons/delete.png" />
            </button>
        </div>
        <div *ngIf="page && editingPage" class="user-title-head w-100 pl-0">
            <form (submit)="savePage()">
                <input #EditPageTitle name="page-title" class="page-title-edit-input" [(ngModel)]="editedPageTitle" />
                <button class="btn btn-primary btn-sm mr-1" type="submit">Save</button>
                <button class="btn btn-secondary btn-sm" (click)="cancelSavePage()">Cancel</button>
            </form>
        </div>
        <div class="menu-item-block d-flex align-items-center pull-right">
            <span class="ico-item dotnav-hold">
                <button mat-icon-button [matMenuTriggerFor]="menu" class="clear-outline dot-nav">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <span>View Details</span>
                    </button>
                    <button mat-menu-item>
                        <span>Location</span>
                    </button>
                    <button mat-menu-item>
                        <span>Thumbnail</span>
                    </button>
                </mat-menu>
            </span>
            <span class="ico-item align-control" (click)="shared.middleBlockFlipHorizontal()">
                <img src="../../assets/images/align-bottom.png" alt="">
            </span>
            <span class="ico-item align-control" (click)="shared.middleBlockFlipVertical()">
                <img src="../../assets/images/align-right.png" alt="">
            </span>
            <span class="ico-item align-control" (click)="shared.middleBlockFullScreen()">
                <img src="../../assets/images/full-screen.png" alt="">
            </span>
            <span class="ico-item align-control" (click)="chatClose()">
                <img src="../../assets/images/close.png" alt="">
            </span>
        </div>
    </div>
    <div class="panel-row w-100 search-input d-flex align-items-center mb-3">
        <input type="text" class="chat-search form-control"
               [formControl]="search"
               placeholder="Search Chat">
        <div class="search-buttons">
            <a *ngIf="searching" href="javascript:void(0)" (click)="clearSearch()" class="search-buttons-x">x</a>
            <span *ngIf="searching" class="search-buttons-number">{{getSearchResult()}}</span>
            <button *ngIf="searching" class="search-buttons-next" (click)="nextSearchResult()">&#65088;</button>
            <button *ngIf="searching" class="search-buttons-prev" (click)="prevSearchResult()">&#65087;</button>
        </div>
        <div class="filter-buttons">
            <button class="filter" style="background-size: 20px !important" [matMenuTriggerFor]="filter"></button>
            <mat-menu #filter="matMenu" xPosition="before" overlapTrigger="false">
                <div style="width: 255px; padding: 5px;">
                    <div *ngFor='let fc of filterCounter ;let i = index'>
                        <span *ngFor="let filter of filters.slice(i * 4, (i+1)*4)" class="filter-ico-item" (click)="filterBy($event, filter.type, filter.mediaType)">
                            <img [class]="getFilterIconClass(filter.type, filter.mediaType)" [src]="filter.imgsrc" alt=""
                                 width="32" height="32">
                            <br/>
                            <span>{{filter.name}}</span>
                        </span>
                    </div>
                </div>
            </mat-menu>
        </div>
        <button type="submit" (click)="showMap()"></button>
    </div>
    <div class="display-area w-100 float-left">
        <ngx-dropzone *ngIf="isDragging"  class="chat-wrapper" (filesAdded)="handlefileDrop($event)" (dragleave)="onDragLeave($event)" [showPreviews]=false ></ngx-dropzone>
        <div *ngIf="!isDragging" class="chat-wrapper" (scroll)="onChatScroll($event)" #ChatArea (dragover)="onDragOver($event)">
            <div *ngIf="shared.chatLoading" class="page-alert">
                <h4>Loading</h4>
                <p>Please wait...</p>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div *ngIf="!shared.chatLoading && page && page.isInvitation && !page.isDeleted" class="page-alert">
                <h4>You have been invited to this page by {{page.invitedBy}}</h4>
                <hr />
                <p>
                    <button type="button" class="btn btn-success btn-lg"
                        (click)="rsvp('accepted')">Accept</button>&nbsp;
                    <button type="button" class="btn btn-danger btn-lg"
                        (click)="rsvp('declined')">Decline</button>&nbsp;
                    <button type="button" class="btn btn-outline-danger btn-lg" (click)="rsvp('blocked')">Block</button>
                </p>
            </div>
            <div *ngIf="!shared.chatLoading && page && (pageService.messageGroups|async) && (pageService.messageGroups|async)?.length === 0 && !page.isDeleted && !page.isInvitation"
                class="page-alert">
                <h4>No messages</h4>
                <p>Start a conversation and invite your friends</p>
            </div>
            <div *ngIf="!shared.chatLoading && !showFriendSentInvite && showFriendChat" class="page-alert">
                <h4>No previous messages</h4>
                <p>You haven't connected yet</p>
                <button type="button" class="btn btn-success btn-lg" (click)="handleStartFriendChat()">Start a
                    Conversation</button>&nbsp;
            </div>
            <div *ngIf="!shared.chatLoading && showFriendSentInvite" class="page-alert">
                <h4>Invite sent!</h4>
                <p>Your friend has been sent an invite.</p>
            </div>
            <div *ngIf="page && page.isDeleted" class="page-alert">
                <h4>Deleted page</h4>
                <p>You had deleted this page in the past</p>
                <button type="button" class="btn btn-success btn-lg" (click)="recoverPage()">Recover</button>&nbsp;
            </div>
            <div class="chat-wrapper-child" *ngIf="page && !page.isDeleted && !page.isInvitation">
                <div *ngFor="let messageGroup of messageGroups">
                    <div *ngIf="messageGroup.user">
                        <div *ngIf="!messageGroup.user.isCurrentUser" class="shadow-none p-2 their-message-block">
                            <img [src]="messageGroup.user.imageUrl ? messageGroup.user.imageUrl : 'assets/images/beam-dp.png'"
                                 onError="this.src='assets/images/beam-dp.png'" alt="" width="32" height="32" class="rounded-circle">
                            <span class="ml-2 beam-id">{{messageGroup.user.beamId.IdOne}}</span>

                            <div *ngFor="let message of messageGroup.messages"
                                [class.broadcast-message]="message.attributes && message.attributes.data && message.attributes.data.isBroadcast"
                                (contextmenu)="handleMessageRightClick($event, message)"
                                class="their-message-row rounded">

                                <div [id]="message._id" *ngIf="message.attributes && message.attributes.data && message.attributes.data.isBroadcast"
                                    class="row their-message">
                                    <div class="col-9">
                                        <img class="broadcast-img" src="assets/images/broadcast-ico.png" width="32"
                                            height="32">
                                        <span class="chat-broadcast-detail">{{message.attributes.data.broadcastPage}}
                                            <span
                                                *ngIf="message.attributes.data.sender && message.attributes.data.sender.beamId.IdOne">
                                                by {{message.attributes.data.sender.beamId.IdOne}}</span> (Broadcast)
                                        </span>
                                        <button *ngIf="page.flag === 'broadcast'"
                                            class="broadcast-reply btn btn-primary btn-sm ml-2"
                                            (click)="replyToBroadcast(messageGroup.user, message)">Reply</button>
                                    </div>
                                </div>

                                <!-- handle Image -->
                                <div [id]="message._id" *ngIf="message.type==='media'" class="row your-message mediaMessage"
                                    (click)="handleMediaMessage(message)"
                                    (contextmenu)="handleMessageRightClick($event, message, true)">
                                    <div class="col-9">
                                        <img class="mediaImgIcon" [src]="mediaIconPath[message.attributes.data.mediaType] ? mediaIconPath[message.attributes.data.mediaType] : 'assets/images/Video.png'"
                                             onerror="this.src='assets/images/Video.png';" width="32" height="32">
                                        <span class="ml-2 chat-text">
                                            {{ message.attributes.data.originalName ? message.attributes.data.originalName : message.body}}
                                        </span>
                                        <p class="chat-gps-detail">
                                            Size: <span> {{ getSizeInMb(message.attributes.data) }}</span></p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <!-- handle image complete -->

                                <div [id]="message._id" *ngIf="message.type==='text'" class="row their-message">
                                    <div class="col-9 chat-text">{{message.body}}</div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='location'" (click)="handleLocationClick(message)"
                                    (contextmenu)="handleLocationMessageRightClick($event, message)"
                                    class="row their-message location-message">
                                    <div class="col-9">
                                        <img class="location-img" src="assets/images/Place.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.gps.title}}</span>
                                        <img *ngIf="message.attributes.data.eta" class="ml-1 mb-1" 
                                            src="/assets/images/eta-message.gif" width="38" height="38"
                                            title="ETA" /> 
                                        <p *ngIf="message.attributes.data.eta" class="chat-gps-detail chat-location-eta-active"
                                            [class.chat-gps-detail-active]="message.attributes.data.live">
                                            ETA: Enabled
                                        </p>
                                        <p *ngIf="message.attributes.data.geofence" class="chat-gps-detail chat-location-eta-active">
                                            Geofence: {{message.attributes.data.geofenceRadius}}m
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='gps'" (click)="handleGPSClick(message)"
                                    class="row their-message gps-message">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/Sensor.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}}</span>
                                        <img *ngIf="message.attributes.data.reporting" class="ml-2 mb-1"
                                            src="/assets/images/tracking.gif" width="16" height="16"
                                            title="Tracking..." />
                                        <p class="chat-gps-detail chat-gps-detail-live"
                                            [class.chat-gps-detail-active]="message.attributes.data.live">
                                            Live Mode: {{message.attributes.data.live ? 'on' : 'off'}}
                                        </p>
                                        <p *ngIf="message.attributes.data.eta !== null" class="chat-gps-detail">
                                            <span *ngIf="message.attributes.data.eta > 0">ETA: 
                                                <span>{{ getHumanFriendlyEta(message.attributes.data.eta) }}</span>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='gps-report'" (click)="handleGPSReportClick(message)"
                                    (contextmenu)="handleGPSReportRightClick($event, message)"
                                    class="row their-message gps-report-message">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/IoT Files.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}} (report)</span>
                                        <p class="chat-gps-detail chat-gps-detail-report-date"
                                            *ngIf="message.attributes.data.reportingStart">
                                            Report Start:
                                            <span>{{getLocalDateString(message.attributes.data.reportingStart)}}</span>
                                        </p>
                                        <p class="chat-gps-detail chat-gps-detail-report-date"
                                            *ngIf="message.attributes.data.reportingEnd">
                                            Report End:
                                            <span>{{getLocalDateString(message.attributes.data.reportingEnd)}}</span>
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='video-recording'"
                                    (click)="handleVideoRecordingClick(message)"
                                    (contextmenu)="handleMessageRightClick($event, message)"
                                    class="row their-message video-recording-message">
                                    <div class="col-9">
                                        <img class="video-img" src="assets/images/Video.png" width="32" height="32">
                                        <span class="ml-2">Call Video Recording</span>
                                        <p class="chat-gps-detail">
                                            Duration: <span>{{message.attributes.data.duraton}} seconds</span></p>
                                        <p class="chat-gps-detail">
                                            Size: <span>{{message.attributes.data.size}} MB</span></p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='event'" (click)="handleEventClick(message)"
                                    (contextmenu)="handleEventRightClick($event, message)"
                                    class="row their-message gps-message">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/Event.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}}</span>
                                        <p class="chat-gps-detail">
                                            <span>{{ formatEventTimestamp(message.attributes.data.dateTime) }}</span>
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='rsvp'" class="row their-message rsvp-message">
                                    <div class="col-9">                                         
                                        <img class="gps-img" src="assets/images/rsvp.png" width="32" height="32" (click)="handleRSVPClick($event, message)">                                        
                                        <span class="rsvp-span beam-green-bg beam-white" (click)="handleRSVPMessage(message, message.attributes.data.messageGreen, 'green')">
                                            {{message.attributes.data.messageGreen}}
                                        </span>
                                        <span class="rsvp-span beam-orange-bg beam-white" (click)="handleRSVPMessage(message, message.attributes.data.messageAmber, 'amber')">
                                                {{message.attributes.data.messageAmber}}                                        
                                        </span>   
                                        <br>                                        
                                        <span class="rsvp-span rsvp-span-danger beam-red-bg beam-white" (click)="handleRSVPMessage(message, message.attributes.data.messageRed, 'red')">                                            
                                                {{message.attributes.data.messageRed}}                                        
                                        </span>                                                   
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='rsvp-response'" class="row their-message rsvp-response-message">
                                    <div class="col-9 chat-text">
                                        <img class="gps-img" src="assets/images/rsvp.png" width="32" height="32">
                                        {{message.body}}
                                    </div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='info'" class="row their-message info-message">
                                    <div class="col-9 chat-text">{{message.body}}</div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='medical-report'"
                                    (click)="handleMedicalReportClick(message)"
                                    (contextmenu)="handleMessageRightClick($event, message)"
                                    class="row their-message medical-message">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/medical-report.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}}</span>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div *ngIf="!message._id" class="pendingIndicator pendingMessage"></div>
                                <div *ngIf="message.updated" class="pendingIndicator sentMessage"></div>
                            </div>
                        </div>
                        <div *ngIf="messageGroup.user.isCurrentUser" class="p-2 your-message-block">
                            <div *ngFor="let message of messageGroup.messages"
                                [class.broadcast-message]="message.attributes && message.attributes.data && message.attributes.data.isBroadcast"
                                class="your-message-row rounded">
                                <div [id]="message._id" *ngIf="message.attributes && message.attributes.data && message.attributes.data.isBroadcast"
                                    class="row your-message">
                                    <div class="col-9">
                                        <img class="broadcast-img" src="assets/images/broadcast-ico.png" width="32"
                                            height="32">
                                        <span class="chat-broadcast-detail">{{message.attributes.data.broadcastPage}}
                                            <span
                                                *ngIf="message.attributes.data.sender && message.attributes.data.sender.beamId">
                                                by {{message.attributes.data.sender.beamId.IdOne}}</span> (Broadcast)
                                        </span>
                                    </div>
                                </div>

                                <!-- handle Image -->
                                <div [id]="message._id" *ngIf="message.type==='media'" class="row your-message mediaMessage"
                                    (click)="handleMediaMessage(message)"
                                    (contextmenu)="handleMessageRightClick($event, message, true)">
                                    <div class="col-9">
                                        <img class="mediaImgIcon" [src]="mediaIconPath[message.attributes.data.mediaType] ? mediaIconPath[message.attributes.data.mediaType] : 'assets/images/Video.png'"
                                             onerror="this.src='assets/images/Video.png';" width="32" height="32">
                                        <span class="ml-2 chat-text">
                                            {{ message.attributes.data.originalName ? message.attributes.data.originalName : message.body}}
                                        </span>
                                        <p class="chat-gps-detail">
                                            Size: <span> {{ getSizeInMb(message.attributes.data) }}</span></p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <!-- handle image complete -->

                                <div [id]="message._id" *ngIf="message.type==='text'" class="row your-message"
                                    (contextmenu)="handleMessageRightClick($event, message, true)">
                                    <div class="col-9 chat-text">{{message.body}}</div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" #ChatLocation *ngIf="message.type==='location'"
                                    (click)="handleLocationClick(message)"
                                    (contextmenu)="handleLocationMessageRightClick($event, message, ChatLocation, true)"
                                    class="row your-message location-message">
                                    <div class="col-9">
                                        <img class="location-img" src="assets/images/Place.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.gps.title}}</span>
                                        <img *ngIf="message.attributes.data.eta" class="ml-1 mb-1" 
                                            src="/assets/images/eta-message.gif" width="20" height="20"
                                            title="ETA" /> 
                                        <p *ngIf="message.attributes.data.eta" class="chat-gps-detail chat-location-eta-active"
                                            [class.chat-gps-detail-active]="message.attributes.data.live">
                                            ETA: Enabled
                                        </p>
                                        <p *ngIf="message.attributes.data.geofence" class="chat-gps-detail chat-location-eta-active">
                                            Geofence: {{message.attributes.data.geofenceRadius}}m
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='gps'" class="row your-message gps-message"
                                    (click)="handleGPSClick(message)"
                                    (contextmenu)="handleGPSRightClick($event, message, true)">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/Sensor.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}}</span>
                                        <img *ngIf="message.attributes.data.reporting" class="ml-2 mb-1"
                                            src="/assets/images/tracking.gif" width="16" height="16"
                                            title="Tracking..." />
                                        <p class="chat-gps-detail chat-gps-detail-live"
                                            [class.chat-gps-detail-active]="message.attributes.data.live">
                                            Live Mode: {{message.attributes.data.live ? 'on' : 'off'}}
                                        </p>
                                        <p *ngIf="message.attributes.data.eta !== null" class="chat-gps-detail">
                                            <span *ngIf="message.attributes.data.eta > 0">ETA: 
                                                <span>{{ getHumanFriendlyEta(message.attributes.data.eta) }}</span>
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='gps-report'" class="row your-message gps-report-message"
                                    (click)="handleGPSReportClick(message)"
                                    (contextmenu)="handleGPSReportRightClick($event, message, true)">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/IoT Files.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}} (report)</span>
                                        <p class="chat-gps-detail chat-gps-detail-report-date"
                                            *ngIf="message.attributes.data.reportingStart">
                                            Report Start:
                                            <span>{{getLocalDateString(message.attributes.data.reportingStart)}}</span>
                                        </p>
                                        <p class="chat-gps-detail chat-gps-detail-report-date"
                                            *ngIf="message.attributes.data.reportingEnd">
                                            Report End:
                                            <span>{{getLocalDateString(message.attributes.data.reportingEnd)}}</span>
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='video-recording'"
                                    class="row your-message video-recording-message"
                                    (click)="handleVideoRecordingClick(message)"
                                    (contextmenu)="handleMessageRightClick($event, message)">
                                    <div class="col-9">
                                        <img class="video-img" src="assets/images/Video.png" width="32" height="32">
                                        <span class="ml-2">Call Video Recording</span>
                                        <p class="chat-gps-detail">
                                            Duration: <span>{{message.attributes.data.duration}} seconds</span></p>
                                        <p class="chat-gps-detail">
                                            Size: <span>{{message.attributes.data.size}} MB</span></p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='event'" (click)="handleEventClick(message)"
                                    (contextmenu)="handleEventRightClick($event, message)"
                                    class="row your-message gps-message">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/Event.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}}</span>
                                        <p class="chat-gps-detail">
                                            <span>{{ formatEventTimestamp(message.attributes.data.dateTime) }}</span>
                                        </p>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='rsvp'"  class="row your-message rsvp-message"
                                    (contextmenu)="handleRSVPRightClick($event, message)">                                    
                                    <div class="col-9">                                         
                                        <img class="gps-img" src="assets/images/rsvp.png" width="32" height="32" (click)="handleRSVPClick($event, message)">                                        
                                        <span class="ml-2">{{message.body}}</span>
                                        <br>
                                        <span class="rsvp-span beam-green-bg beam-white" style="margin-left:37px;"  (click)="handleRSVPMessage(message, message.attributes.data.messageGreen, 'green')">
                                            {{message.attributes.data.messageGreen}}
                                        </span>
                                        <span class="rsvp-span beam-orange-bg beam-white" (click)="handleRSVPMessage(message, message.attributes.data.messageAmber, 'amber')">
                                                {{message.attributes.data.messageAmber}}                                        
                                        </span>   
                                        <br>                                        
                                        <span class="rsvp-span rsvp-span-danger beam-red-bg beam-white" (click)="handleRSVPMessage(message, message.attributes.data.messageRed, 'red')">                                            
                                                {{message.attributes.data.messageRed}}                                        
                                        </span>                                                   
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='rsvp-response'" class="row your-message rsvp-response-message"
                                     (contextmenu)="handleMessageRightClick($event, message, true)">
                                    <div class="col-9 chat-text">
                                        <img class="gps-img" src="assets/images/rsvp.png" width="32" height="32">
                                        {{message.body}}
                                    </div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='info'" class="row your-message info-message"
                                    (contextmenu)="handleMessageRightClick($event, message, true)">
                                    <div class="col-9 chat-text">
                                        {{message.body}}
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div [id]="message._id" *ngIf="message.type==='medical-report'" class="row your-message medical-message"
                                    (click)="handleMedicalReportClick(message)"
                                    (contextmenu)="handleMessageRightClick($event, message)">
                                    <div class="col-9">
                                        <img class="gps-img" src="assets/images/medical-report.png" width="32" height="32">
                                        <span class="ml-2">{{message.attributes.data.title}}</span>
                                    </div>
                                    <div class="col-3 text-muted text-right timestamp">{{message.timeago}}</div>
                                </div>
                                <div *ngIf="!message._id" class="pendingIndicator pendingMessage"></div>
                                <div *ngIf="message.updated" class="pendingIndicator sentMessage"></div>
                            </div>
                        </div>
                        <div *ngIf="messageGroup.lastRead && messageGroup.lastRead.length > 0" class="last-read">
                            Seen by {{messageGroup.lastRead}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!inputDisabled" class="chat-enter float-left d-flex mt-3 position-absolute">
                <button type="button" class="add-file"
                    (click)="handleImportButtonClick()"></button>
                <textarea #MessageBox rows="1" type="text" class="chat-enter w-100" spellcheck="true"
                    [placeholder]="inputPlaceholder" [disabled]="inputDisabled" (keypress)="handleMessageKeyUp($event)"
                    (keydown)="handleMessageKeyDown($event)" (paste)="handleInputPaste($event)"></textarea>
                <button type="button" id="start-video-btn" class="add-video"
                    (click)="startVideo()"></button>
                <i *ngIf="page && page.isTyping" class="typing-indicator badge text-muted">{{page.whoTyping}}
                    typing...</i>
            </div>
            <div *ngIf="inputDisabled" class="chat-enter float-left d-flex mt-3 position-absolute text-muted">
                <i>{{inputPlaceholder}}</i>
            </div>
        </div>
    </div>
</div>
