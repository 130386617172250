import { Injectable } from '@angular/core';

import { Env } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MeterService {
  private baseUrl = Location.joinWithSlash(Env.meterApiUrl, Env.meterApiVersion);

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  get(url: string) {
    const httpOptions = this.getOptions();
    return this.http.get(this.getUrl(url), httpOptions);
  }

  post(url: string, body: Object):any {
    const httpOptions = this.getOptions();
    return this.http.post(this.getUrl(url), body, httpOptions);
  }

  put(url: string, body: Object) {
    const httpOptions = this.getOptions();
    return this.http.put(this.getUrl(url), body, httpOptions);
  }

  delete(url: string) {
    const httpOptions = this.getOptions();
    return this.http.delete(this.getUrl(url), httpOptions);
  }

  private getUrl(url: string) {
    return Location.joinWithSlash(this.baseUrl, url);
  }

  private getOptions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const token = this.auth.getToken();
    if (token !== null && token !== '') {
      httpOptions.headers = httpOptions.headers.append('Authorization', `Bearer ${token}`);
    }

    return httpOptions;
  }
}
