import {Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {ApiService} from "~common/api.service";
import {Flag} from "~page/page.enum";
import {IOService} from "~common/io.service";
import { Router } from '@angular/router';
import { PageService } from '~common/page.service';

const DEFAULT_PHONE_MASK = '+000000000000';
const PHONE_MASK = new Map([
  [1, '+1 (000) 000-0000'], // US
  [61, '+61 000-000-000'], // Australia
  [51, '+51 000-000-0000'], // Peru
  [92, '+92 000-000-0000'], // Pakistan
  [91, '+91 000-000-0000'], // India
]);

@Component({
  selector: 'app-alarm-form',
  templateUrl: './alarm-form.component.html',
  styleUrls: ['./alarm-form.component.css']
})
export class AlarmFormComponent {
  @ViewChild('MapAddressSearch', {
    read: ElementRef,
    static: true
}) public MapAddressElementRef: ElementRef;

  message = {
    accountNumber: '',
    pocPhone: '',
    pocPhone2: '',
    sitePhone: '',
    alarmCode: '',
    alarmType: '',
    alarmZone: '',
    siteAddress: '',
    internalResponse: false,
    externalResponse: false,
    specialInstructions: '',
  };
  name;
  pageData;

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private api: ApiService,
              private pageService: PageService,
              private io: IOService,
              private router: Router) {
    this.pageData = {
      flag: Flag.Alarm,
      location: {
        coordinates: {
          lat: null,
          long: null
        }
      }
    };
  }


  activateAlarm() {

    if (this.validate()) {
      this.pageData.title = null//this.name;

      const memberIds = [
        '5c2f85b9e40182000f0e6726', // Mehrdd
        '5c3ccd0f09e40d03d28a345f', // BeamRobot
      ];
      this.pageData.memberIds = memberIds;
      const gps = this.pageData.location.coordinates;
      this.api.post('page', this.pageData)
        .subscribe((res) => {
          this.pageData = res.meta_data.page;
          const message = 'Account Number: ' + this.message.accountNumber + '\n' +
            'Name: ' + this.name + '\n' +
            'PoC Phone Number: ' + this.message.pocPhone + '\n' +
            'PoC Phone Number: ' + this.message.pocPhone2 + '\n' +
            'Site Phone Number: ' + this.message.sitePhone + '\n' +
            'Alarm Code: ' + this.message.alarmCode + '\n' +
            'Alarm Type: ' + this.message.alarmType + '\n' +
            'Alarm Zone: ' + this.message.alarmZone + '\n' +
            'Response: ' + (this.message.internalResponse ? 'Internal ' : '')
            + (this.message.externalResponse ? 'External' : '') + '\n' +
            'Special Instructions: ' + this.message.specialInstructions;
          this.io.send('message/new', {pageId: this.pageData._id, message: { body: message }});
          if (gps) {
            this.io.send('message/new', {pageId: this.pageData._id, message: {
              body: `Location: Site Address`,
              attributes: { data: { gps }, type: 'location' },
            }});
          }
          this.router.navigate(['/page']);
          this.pageService.selectPageId(this.pageData._id);
        }, (err) => {
        });
    }
  }

  phoneMasker(phoneNumber: string) {
    function convertFormatToMask(format: string): (string | RegExp)[] {
      return format.split('').map(s => s === '0' ? /\d/ : s);
    }
    for (const countryCode of Array.from(PHONE_MASK.keys())) {
      if (phoneNumber.startsWith(`+${countryCode}`)) {
        return convertFormatToMask(PHONE_MASK.get(countryCode));
      }
    }
    return convertFormatToMask(DEFAULT_PHONE_MASK);
  }

  triggerGoogleMapPlacesAPI() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.MapAddressElementRef.nativeElement);

      if (this.pageData && this.pageData.location.coordinates.lat !== '' && this.pageData.location.coordinates.long !== '') {
        const center = new google.maps.LatLng(this.pageData.location.coordinates.lat, this.pageData.location.coordinates.long);
        const circle = new google.maps.Circle({
          center: center,
          radius: 100,
        });
        autocomplete.setBounds(circle.getBounds());
      }

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.pageData.location.coordinates.lat = place.geometry.location.lat();
          this.pageData.location.coordinates.long = place.geometry.location.lng();
          this.pageData.location.coordinates.title = place.formatted_address;
        });
      });
    });
  }

  validate() {
    return this.name && this.name !== '';
  }
}
