import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './symptoms-add-dialog.component.html',
  styleUrls: ['./symptoms-add-dialog.component.css']
})
export class SymptomsAddDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SymptomsAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close(this.data.symptoms);
  }
}
