import { Component, OnInit } from '@angular/core';
import { NotificationService } from '~common/notification.service';
import { AuthService } from '~common/auth.service';
import { GPSService } from '~common/gps.service';
import { IOService } from '~common/io.service';
import { ShareDataService } from '~common/share-data.service';
import { DispatchService } from '~common/dispatch.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private notification: NotificationService,
    private gps: GPSService,
    private dispatch: DispatchService,
    private io: IOService,
    private shared: ShareDataService,
  ) {}

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.shared.pagesLoading = true;
      this.notification.init();
      this.gps.connect();
      this.dispatch.connect();
      this.io.connect();
    }
  }
}
