import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

import { ApiService } from '~common/api.service';

@Component({
  templateUrl: './chat-import.component.html',
})

export class ChatImportDialogComponent {
  panelOpenState = false;
  Title = '';
  userlocation: any[] = [];
  constructor(private api: ApiService,
    public dialogRef: MatDialogRef<ChatImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  select(action) {
    this.dialogRef.close(action);
  }
}
