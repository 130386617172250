import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './vitals-add-dialog.component.html',
  styleUrls: ['./vitals-add-dialog.component.css']
})

export class VitalsAddDialogComponent {

  data = {
    temperature: '',
    temperatureUnit: '',
    bloodPressure: '',
    heartRate: '',
    oxygenLevel: '',
    priorSurgeries: '',
    preConditions: '',
    medications: '',
    allergies: '',
  }

  constructor(
    public dialogRef: MatDialogRef<VitalsAddDialogComponent>,
    /*@Inject(MAT_DIALOG_DATA) public data: any,*/
  ) {}

  selectTemperatureUnit(unit: string) {
    this.data.temperatureUnit = unit;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close(this.data);
  }
}
