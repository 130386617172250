<h1 mat-dialog-title>Verify Phone</h1>
<div mat-dialog-content class="hight100">
    <form #verifyPhoneForm="ngForm" (submit)="onSubmit(verifyPhoneForm)" name="verifyPhoneForm">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <mat-form-field class="w-100">
                    <input matInput [(ngModel)]="data.OTP" name="OTP" placeholder="Enter 4-Digit Verification Code">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-xs-12">
                <mat-dialog-actions class="justify-content-end">
                    <button mat-raised-button class="green-btn">Done</button>
                    <button mat-button class="orange-btn" mat-dialog-close>Cancel</button>
                </mat-dialog-actions>
            </div>
        </div>
    </form>
</div>
