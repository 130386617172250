import { NgModule } from "@angular/core";
import { VideoComponent } from "./video.component";
import { VideoInvitationComponent } from "./invitation/invitation.component";
import { CommonSharedModule } from "~common/common-shared.module";
import { MaterialModule } from "~common/material";
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    VideoComponent,
    VideoInvitationComponent,
  ],
  imports: [
    CommonSharedModule,
    MaterialModule,
    CommonModule,
  ],
  exports: [
    VideoComponent,
    VideoInvitationComponent,
  ]
})
export class VideoModule {}
