<div class="container">
  <h2 mat-dialog-title>{{dialogTitle}}</h2>
  <div class="row">
    <div>
      <mat-form-field class="full-width">
        <input matInput placeholder="{{titlePlaceholder}}" required="true" value="" [(ngModel)]="eventTitle">
      </mat-form-field>
    </div>
    <div *ngIf="dateInputType === DATE_INPUT.DATE_TIME">
      <mat-form-field style="width: 120px">
        <input matInput [matDatepicker]="datePicker" placeholder="Date" [(ngModel)]="eventDate"
               (focus)="datePicker.open()" (click)="datePicker.open()">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field style="width: 120px">
        <input matInput placeholder="Time" aria-label="Time" [ngxTimepicker]="timePicker" readonly required="true"
               [(ngModel)]="eventTime">
        <ngx-material-timepicker #timePicker></ngx-material-timepicker>
      </mat-form-field>
    </div>
    <div *ngIf="dateInputType === DATE_INPUT.TEXT_BASED">
      <app-text-time-input class="full-width" #RideSchedule (scheduledTime)="rideTime = $event"></app-text-time-input>
    </div>
  </div>
  <div class="row" *ngIf="!data.single">
    <ng-select class="full-width"
               [clearSearchOnAdd]="true"
               [closeOnSelect]="false"
               [(ngModel)]="repeatDays"
               [items]="days"
               [multiple]="true"
               [selectOnTab]="true"
               [virtualScroll]="true"
               appendTo="body"
               bindLabel="name"
               bindValue="id"
               placeholder="Repeat">
      <ng-template let-item="item" ng-label-tmp let-clear="clear">
        <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span class="ng-value-label">{{item.shortcut}}</span>
      </ng-template>
      <ng-template ng-header-tmp>
        <div class="filter-controls">
          <button (click)="repeatDays = [1, 2, 3, 4, 5, 6, 7]" class="btn btn-link">Every day</button>
          <button (click)="repeatDays = []" class="btn btn-link">Clear</button>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="row" style="align-items: flex-end;">
    <mat-form-field>
      <input matInput placeholder="Notify" min=0 value="15" [(ngModel)]="eventReminderMinutes"> minutes before
    </mat-form-field>
    <div style="margin-bottom: 20px">
      <ng-select style="max-width: 100%"
                 [clearSearchOnAdd]="true"
                 [closeOnSelect]="false"
                 [ngModel]="notificationMethods"
                 [hideSelected]="true"
                 [items]="notifyVia"
                 [multiple]="true"
                 [selectOnTab]="true"
                 [virtualScroll]="true"
                 appendTo="body"
                 placeholder="Notify via">
      </ng-select>
    </div>
  </div>
  <div class="row" *ngIf="showLocation">
    <app-location-input class="location" [showOnMap]="false"
                        [locationInput]="location"
                        (locationOutput)="location = $event"></app-location-input>
  </div>
  <div *ngIf="rideEvents.length > 0">
    <div class="row">Scheduled rides:</div>
    <div *ngFor="let rideEvent of rideEvents">
      <span>{{util.getRepeatText(rideEvent)}}</span>
      <span class="removeRideEvent" (click)="removeRideEvent(rideEvent)">×</span>
      <br/>
    </div>
  </div>
  <div class="button-row">
      <button *ngIf="!edit && !data.single" class="green-btn btn-button" (click)="addEvent()" [disabled]="!isValid()">Add
      </button>
      <button *ngIf="!edit && !data.single" class="yellow-btn btn-button" (click)="saveRideEvents()"
              [disabled]="!isValid() && rideEvents.length === 0">Done
      </button>
      <button *ngIf="edit || data.single" class="green-btn btn-button" (click)="saveEvent()" [disabled]="!isValid()">Done
      </button>
      <button class="orange-btn btn-button" mat-dialog-close>Cancel</button>
  </div>
</div>
