export const MapConfig = {
  // Speed settings for determing gps marker colors (in meters per second)
  Speed: {
    Motion: 2,
    Speeding: 25,
  },

  DefaultZoom: 16,

  Marker: {
    UnknownTitle: '(Click to use this location)',
    YourTitle: '(You)',
    LinkTitle: 'More',
    DestinationTitle: '(Your destination)',
    PageTitle: '(Page)',
    NoGpsTitle: '(No GPS Data)',
  },
}