import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { ShareDataService } from '~common/share-data.service';
import { IAddress } from '../../beam-forms/beam-forms.interface';

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.css']
})
export class LocationInputComponent implements OnInit, OnChanges {
  @Input() locationInput: IAddress;
  @Input() showOnMap = false;
  @Output() locationOutput = new EventEmitter<IAddress>();
  @ViewChild('Location', { static: true }) locationRef: ElementRef;
  location: IAddress = {
    text: '',
    lat: null,
    lng: null,
  };
  locationAutocomplete: google.maps.places.Autocomplete;
  locationAddress = new FormControl('');

  constructor(private shared: ShareDataService,
              private mapsAPILoader: MapsAPILoader) { }

  async ngOnInit() {
    await this.mapsAPILoader.load();
    this.initAutocomplete();
  }

  private initAutocomplete() {
    this.locationAutocomplete = new google.maps.places.Autocomplete(this.locationRef.nativeElement);

    this.locationAutocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = this.locationAutocomplete.getPlace();
      this.location = {
        text: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.locationOutput.emit(this.location);
      if (this.showOnMap) {
        this.showPickupOnMap();
      }
    });
  }

  private showPickupOnMap() {
    this.shared.ShowPointeronMap.emit({
      data: {
        gps: {
          title: this.location.text,
          long: this.location.lng,
          lat: this.location.lat,
        }
      }, home: true
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.locationInput && this.location !== this.locationInput) {
      this.location = this.locationInput;
      this.locationAddress.setValue(this.locationInput.text);
    }
  }
}
