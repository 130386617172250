<div class="container">
  <h2 mat-dialog-title>Transfer Page Ownership</h2>
  <div class="col-md-12">
    <div class="col-md-12 search-row px-0">
      <input #SearchInput type="text" placeholder="Search..." (keyup)="handleSearchInput($event)">
    </div>
    <table class="list-friends">
      <thead>
        <tr>
          <th width="80%" colspan="2"> User Name </th>
          <th width="20%"><b>Actions</b></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let user of filteredFriendList'>
          <td><img [src]="user.imageUrl ? user.imageUrl : 'assets/images/unknown.png'"
                   onError="this.src='assets/images/unknown.png'"
                   alt="" width="32" height="32" class="rounded-circle"></td>
          <td class="service-name-col">{{ user.username }} <span class="dot"></span></td>
          <td class="service-id-col">
            <button class="btn" [class.green-btn]="!transferring"
              [class.orange-btn]="transferring" (click)="transfer(user)">
              {{ transferring ? 'Transferring Ownership' : 'Transfer Ownership' }}
            </button>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
  <mat-dialog-actions>
  </mat-dialog-actions>
</div>
