import { Component, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './create-rsvp.component.html',
    styleUrls: ['./create-rsvp.component.css']
})

export class CreateRSVPComponent {
    public messageTitle: string = "";
    public messageGreen: string = "Yes";
    public messageAmber: string = "Maybe";
    public messageRed: string = "No";

    constructor(public dialogRef: MatDialogRef<CreateRSVPComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (data.message) {
            this.messageTitle = data.message.body;
            this.messageGreen = data.message.data.messageGreen;
            this.messageAmber = data.message.data.messageAmber;
            this.messageRed = data.message.data.messageRed;
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    createRSVP() {

        // Putting all event parameters together
        let attributes = {
            type: 'rsvp',
            timestamp: new Date().getTime(),
            data: {                
                messageGreen: this.messageGreen,
                messageAmber: this.messageAmber,
                messageRed: this.messageRed,
            }
        }
        // Event title is in the message body
        let body = this.messageTitle;

        this.dialogRef.close({ body, attributes });
    }
}
