<div class="container">
    <div class="col-md-12">
        <h2 mat-dialog-title>{{data.title}}</h2>
        <mat-accordion class="headers-align">
            <p *ngIf="data.reporting">
                <img src="/assets/images/tracking.gif" width="16" height="16" title="Tracking..." />
                GPS reporting in-progress...
            </p>
            <hr />
            <button *ngIf="data.type === 'sensor'" mat-menu-item [disabled]="generatingReport" (click)="generateReport()">
                <span>{{generatingReport ? 'Generating...' : 'Generate Report'}}</span>
            </button>
            <mat-expansion-panel *ngIf="data.editable && data.type === 'sensor'" hideToggle class="map-expansion-panel">
                <mat-expansion-panel-header class="map-expansion-panel-header">
                    <button mat-menu-item>
                        <span>Edit</span>
                    </button>
                </mat-expansion-panel-header>
                <mat-form-field class="full-width">
                    <input [(ngModel)]="data.title" class="full-width" matInput placeholder="GPS Title">
                </mat-form-field>
                <mat-action-row>
                    <button class="green-btn" mat-button (click)="updateRemove('update')">Update</button>
                </mat-action-row>
            </mat-expansion-panel>
            <button *ngIf="data.editable" mat-menu-item (click)="updateRemove('delete')">
                <span>Remove</span>
            </button>
            <button mat-menu-item (click)="updateRemove('copy')">
                <span>Copy</span>
            </button>
            <button mat-menu-item [disabled]="downloadingJSON" (click)="download('json')">
                <span>{{downloadingJSON ? 'Downloading...' : 'Download JSON'}}</span>
            </button>
            <button mat-menu-item [disabled]="downloadingExcel" (click)="download('excel')">
                <span>{{downloadingExcel ? 'Downloading...' : 'Download Excel'}}</span>
            </button>
        </mat-accordion>
    </div>
</div>