<div class="row">
    <div class="col-md-12 marginBottom">
        <ngx-dropzone (filesAdded)="onFilesAdded($event)" [showPreviews]=true #dropzone></ngx-dropzone>
    </div>
    <div class="col-md-12 text-center">
        <div *ngIf="loading" >
            <p>Uploading...</p>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="!loading">
            <button type="button " class="btn btn-primary marginRight" (click)="resetDropZone(dropzone)" [disabled]="fileList.length === 0">Reset</button>
            <button type="button" class="btn btn-primary" (click)="upload(dropzone)" [disabled]="fileList.length === 0">Upload</button>
        </div>
    </div>
</div>
