import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '~common/api.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ShareDataService } from '~common/share-data.service';
import { Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
  errorMessage = false;
  termsandcondition = false;
  data = null;
  constructor
    (@Inject(DOCUMENT) private document: Document,
    private api: ApiService,
    private shareDataService: ShareDataService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.shareDataService.removeBodyClasses();
    this.document.body.classList.add('landing');
    this.shareDataService.TermsandConditionsclick
    .subscribe(
      (status: any) => {
        this.termsandcondition = status;
      }
    );
  }

  onSubmit(form: NgForm) {
    const values = form.value;
    const payload = {
      email: values.email,
      phoneNumber: values.phoneNumber,
      password: values.password,
      beamId: values.beamId,
      termsandcondition: values.termsandcondition
    };
    this.api.post('account/register', payload)
      .subscribe((res) => {
        this.data = res;
        if (this.data && this.data.meta_data.user.userId && this.data.meta_data.user.userId) {
          this.router.navigate(['/account/login']);
        } else {
          this.errorMessage = true;
          return;
        }
      });
  }

}

