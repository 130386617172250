<div class="container-fluid login-content-height">
    <div class="row toploginrow py-2">
        <div class="col-md-12">
            <img src="../../assets/beamCloud.png" width="200" height="171" alt="beamLive" class="landing-logo">
            <h1>Live IoE Communication<sup>&trade;</sup></h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 tag-content">
            <p>
                Omni-Content Sharing<br/> Machine to People Collaboration<br/> Smart Alerts and Notifications<br/> Geo-Analytics and Management<br/> Customized IoT Apps in Weeks
            </p>
            <p class="text-high mt-3">
                Helping People and Organizations<br/> Make Better Decisions Faster
            </p>
        </div>
        <div class="col-md-6 d-flex align-items-center justify-content-center">
            <form #registerForm="ngForm" (submit)="onSubmit(registerForm)" class="login-block" name="registerForm">
                <div class="form-group w-100">
                    <textarea class="form-control width-control-login" placeholder="Your Message"></textarea>
                </div>
                <div class="form-group w-100">
                    <input class="form-control" type="text" name="name" placeholder="Name" ngModel>
                </div>
                <div class="form-group w-100">
                    <input class="form-control width-control-login" type="text" name="email" placeholder="Phone or Email" ngModel>
                </div>
                <div class="form-group w-100 ">
                    <select class="form-control">
                       <option selected>Report</option>
                       <option>Help</option>
                       <option>Issue</option>
                       <option>Spam</option>
                       <option>Abuse</option>
                   </select>
                </div>
                <div class="form-group w-100">
                    <button class="green-btn btn-button clear-outline width-control-login">Send</button>
                </div>
                 <div class="form-group w-100 justify-content-start d-flex form-padding">
                    New to Beam ? <a class="alink"  routerLink='/register'>Sign Up</a>
                </div>
                 <div class="form-group w-100">
                    <button class="btn-button blue-btn clear-outline width-control-login" routerLink='/login'>Sign In</button>
                </div>
            </form>
        </div>
    </div>
</div>
<footer class="text-center w-100 d-block">
    Copyright &copy; 2020 beamLive
</footer>