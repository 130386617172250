<div class="container">
    <h2 mat-dialog-title>Select date-time range</h2>
    <div class="row">
        <mat-form-field>
            <input matInput [matDatepicker]="dateFromPicker" placeholder="Date from" [(ngModel)]="dateFrom">
            <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateFromPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Time from" aria-label="Time from" [ngxTimepicker]="timeFromPicker" readonly
                [(ngModel)]="timeFrom">
            <ngx-material-timepicker #timeFromPicker></ngx-material-timepicker>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field>
            <input matInput [matDatepicker]="dateToPicker" placeholder="Date to" [(ngModel)]="dateTo">
            <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateToPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Time to" aria-label="Time to" [ngxTimepicker]="timeToPicker" readonly
                [(ngModel)]="timeTo">
            <ngx-material-timepicker #timeToPicker></ngx-material-timepicker>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-dialog-actions>
            <button mat-raised-button class="green-btn" (click)="done()">Done</button>
            <button mat-button class="orange-btn" mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </div>
</div>