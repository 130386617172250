/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
// @ts-ignore
import {} from 'googlemaps';
import { MapViewComponent } from './map-view.component';
import { IGps, IAssetMarker, IPeopleMarker, IPlaceMarker, IPageMarker, IMediaMarker } from './map.interface';
import { MarkerType } from './map.enum';
import { MapConfig } from './map.config';


const ICON_GPS = {
  path: 'm 14.650839,17.10804 '
    + 'l -7.61407,-6.335771 '
    + 'c 4.757582,-0.045325 5.319359,0.04921 9.44757,0.073815 '
    + 'l 10.552428,6.632326 '
    + 'c -4.278937,2.757696 -5.760671,4.11679 -9.829817,6.734626 '
    + 'l -10.170181,-0.02849'
    + 'c 3.673739,-3.27656 4.545096,-4.191037 7.61407,-7.076506z',
  rotation: 0,
  fillColor: 'black',
  fillOpacity: 1.0,
  strokeColor: '#000000',
  strokeWeight: 1,
};

const GPS_RED = 'rgb(243,0,0)';
const GPS_AMBER = 'rgb(255,255,0)';
const GPS_GREEN = 'rgb(102,255,102)';


@Injectable()
export class MapMarkerService {
  
  private view: MapViewComponent;

  constructor(
    private mapsAPILoader: MapsAPILoader,
  ) {
  }

  setMapView(view: MapViewComponent) {
    this.view = view;
  }

  /**
   * Creates a place marker, adds to map and returns it
   * 
   * @param title string Marker Title
   * @param lat number Latitude
   * @param long number Longitude
   * 
   * @returns IPlaceMarker
   */
  addPlaceMarker(title: string, lat: number, long: number): IPlaceMarker {
    const marker = this.createPlaceMarker(title, lat, long);
    this.view.markers.push(marker);
    return marker;
  }

  /**
   * Creates a page marker, adds to map and returns it
   * 
   * @param pageId string Page ID
   * @param title string Marker Title
   * @param lat number Latitude
   * @param long number Longitude
   * 
   * @returns IPageMarker
   */
  addPageMarker(pageId: string, title: string, lat: number, long: number): IPageMarker {
    const marker = this.createPageMarker(pageId, title, lat, long);
    this.view.markers.push(marker);
    return marker;
  }

  /**
   * Creates a media marker, adds to map and returns it
   * 
   * @param filename string Media filename, will be used as marker title
   * @param lat number Latitude
   * @param long number Longitude
   * 
   * @returns IMediaMarker
   */
  addMediaMarker(filename: string, lat: number, long: number): IMediaMarker {
    const marker = this.createMediaMarker(filename, lat, long);
    this.view.markers.push(marker);
    return marker;
  }

  /**
   * Creates a place marker at the current location,
   * adds to map and returns it
   * 
   * @param title string Marker Title
   * 
   * @returns IPlaceMarker
   */
  addCurrentMarker(title: string = MapConfig.Marker.UnknownTitle): IPlaceMarker {
    if (!this.view.current) {
      return;
    }
    const marker = this.createPlaceMarker(title, this.view.current.lat, this.view.current.long);
    this.view.markers.push(marker);
    return marker;
  }

  /**
   * Creates and return and people marker
   * People markers
   *  - Use directional icon
   *  - Outlined by colors generated using user ids
   *  - Filled with colors generated based on gps speed
   *  - Have the latest marked labelled by beamId of user
   * 
   * @param gps IGps gps data
   * @param isHistorical boolean Is this a historical breadcrumb or latest?
   * 
   * @returns IPeopleMarker
   */
  createPeopleMarker(gps: IGps, isHistorical = false): IPeopleMarker {
    const type = MarkerType.People;

    const m = new google.maps.Marker({
      position: new google.maps.LatLng(gps.lat, gps.long),
      map: this.view.map,
      title: gps.title,
    });

    // Create a copy of the icon to allow customizing it without
    // affecting the source icon
    const icon = { ...ICON_GPS };

    // If user id is provided, we outline the icon with a user id
    // based generated color.
    // We also need to keep the marker lablel on only the latest marker
    if (!isHistorical && gps.userId) {
      const previousLatestMarker = <IPeopleMarker> this.view.markers.find((marker: IPeopleMarker) =>
        marker.type === type && marker.isLatest && marker.gps.imei === gps.imei);
      if (previousLatestMarker) {
        previousLatestMarker.isLatest = false;
        previousLatestMarker.m.setLabel(null);
        const updatedPreviousLatestMarkerIcon = {
          ...ICON_GPS,
          anchor: new google.maps.Point(16, 16),
          rotation: previousLatestMarker.gps.heading - 90,
          fillColor:  this._getSpeedColor(previousLatestMarker.gps.speed, previousLatestMarker.gps.speedUnit),
        };
        previousLatestMarker.m.setIcon(updatedPreviousLatestMarkerIcon);
        // If we're not showing breadcrumbs, remove the last marker from map
        if (!this.view.filter.breadcrumbs) {
          previousLatestMarker.m.setMap(null);
        }
      }

      icon.strokeWeight = 5;
      icon.strokeColor = this._generateUniqueColor(gps.userId);
      m.setLabel(gps.title);
    }

    const isLatest = !isHistorical;
    // The icon is an SVG, which lets us control the colors and rotation
    // @ts-ignore
    icon.anchor = new google.maps.Point(16, 16);
    icon.rotation = gps.heading - 90;
    icon.fillColor = this._getSpeedColor(gps.speed, gps.speedUnit);
    
    m.setTitle(gps.title);
    m.setIcon(icon);

    // Generate a marker id so we can trace it back to its
    // IMarker parent by searching the markers array
    m.set('id', new Date());

    const marker: IPeopleMarker = { m, gps, type, isLatest };

    // Handle marker click
    google.maps.event.addListener(m, 'click', (_marker => () => {
      const center = _marker.m.getPosition();

      // Determine whether we need to show direction action or not
      // toFixed(3) gives us 3 decimal places, which reduces the location accuracy to within a 100m
      // This lets us check a user's location to a vicinity to 100m instead of a few meters.
      if (center.lat().toFixed(3) === marker.gps.lat.toFixed(3)
        && center.lng().toFixed(3) === marker.gps.long.toFixed(3)) {
        this.view.shared.ShowDirection = false;
      } else {
        this.view.shared.ShowDirection = true;
      }

      // Open the location info dialog
      this.view.openMapDialog(marker.gps);
    })(marker));

    return marker;
  }

  /**
   * Creates and returns a place marker
   * 
   * @param title string Marker Title
   * @param lat number Latitudes
   * @param long number Longitude
   * 
   * @returns IPlaceMarker
   */
  createPlaceMarker(title: string, lat: number, long: number): IPlaceMarker {
    const m = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      map: this.view.map,
      title,
      icon: '../../assets/images/map/marker_place.png',
    });

    // Generate a marker id so we can trace it back to its
    // IMarker parent by searching the markers array
    m.set('id', new Date());

    const marker: IPlaceMarker = { m, lat, long, type: MarkerType.Place, title };

    // Handle marker click
    google.maps.event.addListener(m, 'click', (_marker => () => {
      const center = _marker.m.getPosition();

      // Determine whether we need to show direction action or not
      // toFixed(3) gives us 3 decimal places, which reduces the location accuracy to within a 100m
      // This lets us check a user's location to a vicinity to 100m instead of a few meters.
      if (center.lat().toFixed(3) === marker.lat.toFixed(3)
        && center.lng().toFixed(3) === marker.long.toFixed(3)) {
        this.view.shared.ShowDirection = false;
      } else {
        this.view.shared.ShowDirection = true;
      }

      // Open the location info dialog
      this.view.openMapDialog({ lat: marker.lat, long: marker.long });
    })(marker));

    return marker;
  }

  /**
   * Creates and returns a page marker
   * 
   * @param pageId string Page ID
   * @param title string Marker Title
   * @param lat number Latitudes
   * @param long number Longitude
   * 
   * @returns IPageMarker
   */
  createPageMarker(pageId: string, title: string, lat: number, long: number): IPageMarker {
    const m = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      map: this.view.map,
      title,
      icon: '../../assets/images/map/marker_page.png',
    });

    // Generate a marker id so we can trace it back to its
    // IMarker parent by searching the markers array
    m.set('id', new Date());

    return <IPageMarker> { m, lat, long, type: MarkerType.Place, title, pageId };
  }

  /**
   * Creates and returns a media marker
   * 
   * @param filename, string Marker Title
   * @param lat number Latitudes
   * @param long number Longitude
   * 
   * @returns IMediaMarker
   */
  createMediaMarker(filename: string, lat: number, long: number): IMediaMarker {
    const m = new google.maps.Marker({
      position: new google.maps.LatLng(lat, long),
      map: this.view.map,
      title: filename,
      icon: '../../assets/images/map/marker_media.png',
    });

    // Generate a marker id so we can trace it back to its
    // IMarker parent by searching the markers array
    m.set('id', new Date());

    return <IMediaMarker> { m, lat, long, type: MarkerType.Place, filename };
  }

  private _getSpeedColor(speed, unit = 'kph') {
    // convert speed to meters per second
    let mpsSpeed = speed;
    switch (unit) {
      case 'mps':
        break;
      case 'mph':
        mpsSpeed = speed / 2.23694;
        break;
      case 'kph':
        mpsSpeed = speed / 3.6;
        break;
    }

    if (mpsSpeed <= MapConfig.Speed.Motion) {
      return GPS_AMBER;
    }
    if (mpsSpeed >= MapConfig.Speed.Speeding) {
      return GPS_RED;
    }
    return GPS_GREEN;
  }

  private _generateUniqueColor(userId: string): string {
    let uniqInt = 100000;
    for (let i = 0; i < userId.length / 2; i++) {
      uniqInt += (
        userId.charCodeAt(i)
        * userId.charCodeAt(userId.length - 1 - i)
        * 1111
      ) + (
        userId.charCodeAt((userId.length - 1) / 2)
        * 1000
      );
    }
    const uniqIntPadded = uniqInt.toString(16).padStart(6, '0');
    const colorCode = `#${uniqIntPadded}`.substr(0, 7);
    return colorCode;
  }

}