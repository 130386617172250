<div class="container">
  <h2 mat-dialog-title>People</h2>
  <div>
    <div class="search-row px-0">
      <input #SearchInput type="text" placeholder="Search..." (keyup)="handleSearchInput($event)">
    </div>
    <div *ngIf="nonBeamerToInvite" class="row">
      <div class="list-friends-wrapper">
        <table class="list-friends">
          <tbody>
            <tr>
              <td>
                <img src="assets/images/unknown.png" alt="" width="32" height="32" class="rounded-circle">
              </td>
              <td class="service-name-col">
                <p class="service-name-title">{{ nonBeamerToInvite }}</p>
                <p class="service-name-mute">Account not in system</p>
              </td>
              <td class="service-id-col">
                <button class="btn orange-btn" (click)="inviteNonBeamer(nonBeamerToInvite)" [disable]="invitingNonBeamer">
                  {{ invitingNonBeamer ? 'Inviting...' : 'Invite'}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr *ngIf="nonBeamerToInvite" />
    <div class="row">
      <h3>On Page
        <span *ngIf="insideMemberList.length > 0">({{ insideMemberList.length }})</span>
      </h3>
      <div class="list-friends-wrapper">
        <table class="list-friends">
          <tbody>
            <tr *ngIf="insideMemberList.length === 0 && outsideMemberList.length === 0">
              <td colspan="3">No one here!</td>
            </tr>
            <tr *ngFor='let user of insideMemberList'>
              <td>
                <img [src]="user.imageUrl ? user.imageUrl : 'assets/images/unknown.png'"
                     onError="this.src='assets/images/unknown.png'" alt="" width="32" height="32" class="rounded-circle">
              </td>
              <td class="service-name-col">
                <p class="service-name-title">{{ user.beamId.IdOne }}</p>
                <p *ngIf="user.beamId || user.username" class="service-name-mute">{{ user.username }}</p>
                <p *ngIf="user.phoneNumber" class="service-name-mute">{{ user.phoneNumber }}</p>
              </td>
              <td class="service-id-col">
                <button *ngIf="user.accepted !== 'pending'" class="btn orange-btn"
                  (click)="toggleUser(user)">Remove</button>
                <button *ngIf="user.accepted === 'pending'" class="btn yellow-btn"
                  (click)="toggleUser(user)">Cancel</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr *ngIf="insideMemberList.length !== 0 && outsideMemberList.length !== 0" />
    <div class="row">
      <h3>Add to Page
        <span *ngIf="outsideMemberList.length > 0">({{ outsideMemberList.length }})</span>
      </h3>
      <table class="list-friends">
        <tbody>
          <tr *ngFor='let user of outsideMemberList'>
            <td>
              <img [src]="user.imageUrl ? user.imageUrl : 'assets/images/unknown.png'"
                   onError="this.src='assets/images/unknown.png'" alt="" width="32" height="32"
                 class="rounded-circle">
            </td>
            <td class="service-name-col">
              <p class="service-name-title">{{ user.beamId.IdOne }}</p>
              <p *ngIf="user.beamId" class="service-name-mute">{{ user.username }}</p>
              <p *ngIf="user.phoneNumber" class="service-name-mute">{{ user.phoneNumber }}</p>
            </td>
            <td class="service-id-col">
              <button class="btn green-btn" (click)="toggleUser(user)">Invite</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr *ngIf="filteredGroups.length > 0" />
    <div *ngIf="filteredGroups.length > 0" class="row list-groups-wrapper">
      <h3>Groups
        <span>({{ filteredGroups.length }})</span>
      </h3>
      <table class="list-friends">
        <tbody>
          <tr *ngIf="filteredGroups.length === 0">
            <td colspan="3">No one here!</td>
          </tr>
          <tr *ngFor='let group of filteredGroups'>
            <td>
              <img
                [src]="group.imageUrl ? group.imageUrl : 'assets/images/unknown.png'"
                onError="this.src='assets/images/unknown.png'"
                alt="" width="32" height="32"
                class="rounded-circle">
            </td>
            <td class="service-name-col">
              <p class="service-name-title">{{ group.title }}</p>
            </td>
            <td class="service-id-col">
              <button class="btn green-btn" (click)="inviteGroup(group)">Invite Group</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <mat-dialog-actions></mat-dialog-actions>
</div>
