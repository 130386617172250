<div class="block-content">
    <div class="map-container" style="width: 100%">

        <app-map-header
            #mapHeader
            (search)="search($event)"
            [mapType]="mapType"
            [page]="page">
        </app-map-header>
        
        <agm-map 
            #mapLocation
            class="MapClass display-area w-100 float-left p-0 location-map"
            (mapDblClick)="mapClick($event)"
            (mapReady)="ready($event)"
            [fullscreenControl]="true"
            [mapTypeControl]="true"
            [panControl]="true"
            [rotateControl]="true"
            [scaleControl]="true"
            [zoom]="14">

            <agm-marker 
                *ngFor="let marker of markers; let i = index"
                (contextmenu)="$event.preventDefault()"
                [latitude]="marker.m.lat"
                [longitude]="marker.m.lng"
                [agmFitBounds]="filter.active"
                [iconUrl]="marker.m.icon"
                [markerClickable]="true"
                [markerDraggable]="true">

                <!--<agm-info-window *ngIf="marker" [isOpen]="true">
                    <div (click)="clickOnMarker(i)">
                        <a [style.pointer-events]="marker.m.title === 'More' ? 'auto' : 'none'"
                            href="https://www.beam.live"
                            style="text-decoration: none; color: rgba(0,0,0,.87);">{{marker.m.title}}</a>
                    </div>
                </agm-info-window>-->
                
            </agm-marker>

            <agm-direction
                [visible]="shared.ShowDirection"
                [origin]="origin"
                [destination]="destination"
                [markerOptions]="markerOptions"
                [panel]="directionsPanel"
                [renderOptions]="renderOptions">
            </agm-direction>

            <ng-container *ngFor="let taxi of ride.taxis | async">
                <agm-marker (markerClick)="ride.setSelectedTaxi(taxi)" *ngIf="taxi.location"
                    [latitude]="taxi.location.lat" [longitude]="taxi.location.lng"
                    iconUrl="../assets/images/taxi_marker.png">
                </agm-marker>
            </ng-container>

            <agm-circle
                [visible]="showGeofence"
                [latitude]="geofence.latitude"
                [longitude]="geofence.longitude"
                [radius]="geofence.radius"
                clickable="false">
            </agm-circle>

            <div class="cc" id="mapFilters">
                <div class="ccContent ccTop">
                    <app-map-filter title="Assets" [(selected)]="filter.selectedAssets" [items]="shared.assets"
                        (change)="filter.refresh()" bindLabel="title">
                    </app-map-filter>
                    <app-map-filter *ngIf="mapType === MapType.Home" title="Pages" [(selected)]="filter.selectedPages" [items]="shared.pages"
                        (change)="filter.refresh()" bindLabel="title">
                    </app-map-filter>
                    <button (click)="filter.toggleBreadcrumb()" class="toggle-breadcrumb" title="Show Breadcrumbs">
                        <img [class.breadcrumbs-off]="!filter.breadcrumbs" src="../../assets/images/map/breadcrumbs.png">
                    </button>
                    <button (click)="filter.toggleAuto()" class="toggle-breadcrumb"
                        [title]="trackingMode === MapTrackingMode.Manual ? 'Switch to Auto-tracking' : 'Switch to Manual tracking'">
                        <img [class.breadcrumbs-off]="trackingMode === MapTrackingMode.Manual" src="../../assets/images/map/auto.png">
                    </button>
                </div>
                <div class="ccContent">
                    <app-map-filter *ngIf="mapType === MapType.Page" title="People" [(selected)]="filter.selectedPeople"
                        [items]="shared.people" (change)="filter.refresh()" bindLabel="beamId" [trackable]="true">
                    </app-map-filter>
                    <app-map-filter *ngIf="mapType === MapType.Page" title="Places" [(selected)]="filter.selectedPlaces"
                        [items]="shared.places" (change)="filter.refresh()" bindLabel="title">
                    </app-map-filter>
                    <app-map-filter *ngIf="mapType === MapType.Page" title="Media" [(selected)]="filter.selectedMedia"
                        [items]="shared.media" (change)="filter.refresh()" bindLabel="filename">
                    </app-map-filter>
                </div>
            </div>

            <div class="btn-wrapper mapButtons" id="mapButtons">
                <button (click)="reset(false)" class="clearButton" title="Clear Map">
                    <img class="buttonIcons" src='../../assets/images/markLocation.png'>
                </button>
                <hr />
                <button (click)="reset(true)" class="gps-btn" title="My location">
                </button>
            </div>
            
            <app-ride-driver [show]="newRide" id="rideDriver"></app-ride-driver>
            
        </agm-map>

        <div class="tracking" [class.active]="trackingMode === MapTrackingMode.AutoSingle || trackingMode === MapTrackingMode.AutoAll">
            Tracking: {{trackingMode}}
        </div>

    </div>
</div>