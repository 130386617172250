import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT, formatDate } from "@angular/common";
//import Sugar from 'sugar-date';

import { ChatImportDialogComponent } from '~dialog/chat-import/chat-import.component';
import { LocationMessageMenuDialogComponent } from './location-message-menu-dialog/location-message-menu-dialog.component';
import { GPSTrackerToggleComponent } from './gps-tracker-toggle/gps-tracker-toggle.component';
import { ConfirmationDialogComponent } from '~dialog/confirmation/confirmation.component';
import { ShareDataService } from '~common/share-data.service';
import { ApiService } from '~common/api.service';
import { GPSService } from '~common/gps.service';
import { ListFriendsDialogComponent } from './list-friends/list-friends.component';
import { TransferOwnershipDialogComponent } from '~dialog/transfer-ownership/transfer-ownership.component';
import { FailureDialogComponent } from '~dialog/failure/failure.component';
import { MessageMenuDialogComponent } from './message-menu-dialog/message-menu-dialog.component';
import { Util } from '~common/util';
import { PageService } from '~common/page.service';
import { IOService } from '~common/io.service';
import { MediaUploadComponent } from './upload-media-dialog/upload-media.component';
import { Env } from '../../environments/environment';
import { ImageViewer } from './image-viewer/image-viewer.component';
import { VideoViewer } from './video-player/video-player.component';
import { LocationService } from '~common/location.service';
import { WarningDialogComponent } from '~dialog/warning/warning.component';
import { CreateEventComponent } from './create-event/create-event-component';
import { CreateRSVPComponent } from './create-rsvp/create-rsvp.component';
import { EventsCalendarComponent } from './events-calendar/events-calendar.component';
import { EventMessageMenuDialogComponent } from './event-message-menu-dialog/event-message-menu-dialog.component';
import { RSVPResponseListDialogComponent } from './rsvp-response-list/rsvp-response-list.component';
import { RSVPMessageMenuDialogComponent } from './rsvp-message-menu-dialog/rsvp-message-menu-dialog.component';
import { FormControl } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, pairwise, takeUntil } from "rxjs/operators";
import { DocumentViewerComponent } from "~chat/document-viewer/document-viewer.component";
import { MapType } from '~map/map.enum';
import { MedicalReportDialogComponent } from './medical-report-dialog/medical-report-dialog.component';

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.css']
})

/**
 * Notes:
 *  - Twilio is initialized on init
 *  - Loads all channels for quick channel switching
 */
export class ChatViewComponent implements OnInit, OnDestroy {
  @ViewChild('EditPageTitle') EditPageTitle: ElementRef;
  @ViewChild('ChatArea') ChatArea: ElementRef;
  @ViewChild('MessageBox') MessageBox: ElementRef;
  @ViewChild('ChatLocation') ChatLocationElement: ElementRef;
  page: any;
  messageGroups = [];
  inputDisabled = false;
  inputPlaceholder = 'Type message';
  editingPage: Boolean = false;
  editedPageTitle: String;
  showFriendChat: Boolean = false;
  showFriendSentInvite: Boolean = false;
  isDragging = false;
  searching = false;
  search = new FormControl('');
  mediaIconPath = {
    Image: 'assets/images/media/Image.png',
    Audio: 'assets/images/media/Audio.png',
    Video: 'assets/images/media/Video.png',
    Document: 'assets/images/media/Document.png'
  };
  filters = [
    { type: 'media', mediaType: 'Image', name: 'Image', imgsrc: 'assets/images/Image.png' },
    { type: 'media', mediaType: 'Video', name: 'Video', imgsrc: 'assets/images/Video.png' },
    { type: 'media', mediaType: 'Audio', name: 'Audio', imgsrc: 'assets/images/Audio.png' },
    // {type: 'media', mediaType: 'Document', name: 'Document', imgsrc: 'assets/images/Document.png'},
    { type: 'gps-report', mediaType: null, name: 'File', imgsrc: 'assets/images/File.png' },
    { type: 'pay', mediaType: null, name: 'Pay', imgsrc: 'assets/images/Pay.png' },
    { type: 'event', mediaType: null, name: 'Event', imgsrc: 'assets/images/Event.png' },
    { type: 'location', mediaType: null, name: 'Place', imgsrc: 'assets/images/Place.png' },
    { type: 'gps', mediaType: null, name: 'IoT', imgsrc: 'assets/images/Sensor.png' },
    { type: 'capture', mediaType: null, name: 'Capture', imgsrc: 'assets/images/IoT Files.png' },
    { type: 'incident', mediaType: null, name: 'Incident', imgsrc: 'assets/images/warning-ico.png' },
    { type: 'rsvp, rsvp-response', mediaType: null, name: 'RSVP', imgsrc: 'assets/images/rsvp.png' },
    { type: 'collaborate', mediaType: null, name: 'Collaborate', imgsrc: 'assets/images/collaborate.png' },
    { type: 'text, info', mediaType: null, name: 'Text', imgsrc: 'assets/images/Text.png' },
  ];
  filterCounter = new Array(Math.ceil(this.filters.length / 4));
  private pagesEvent = new EventEmitter<string>();
  private messages = [];
  private pages: Array<any> = [];
  private clipboardMessage: string = null;
  private oneKbInBytes = 0.001;
  private oneMbInBytes = 0.000001;
  private ngUnsubscribe = new Subject<void>();
  private friendChatFriend: any = null;
  private pageChanged = false;
  private lastChunk = false;
  private firstChunk = false;
  private nextChunk = false;
  private prevChunk = false;
  private chunkCache = 2;
  private chunkSize = 50;
  private searchResults = false;
  private searchMessageId: string;
  private scrollToId: string;
  private searchResultCount = 0;
  private currentSearchResultNumber = 0;
  private currentSearchResultNumberInMessage = 0;
  private filterObjectType: any[] = [];
  private objectTypes: any[] = [];
  private mediaTypes: any[] = [];
  private _typing = new BehaviorSubject<boolean>(false);
  private typing = this._typing.asObservable();
  private _lastReadMessage = new BehaviorSubject<String>('');
  private lastReadMessage = this._lastReadMessage.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document,
              private dialog: MatDialog,
              private api: ApiService,
              private io: IOService,
              private gps: GPSService,
              public pageService: PageService,
              public shared: ShareDataService,
              private util: Util,
              private cdr: ChangeDetectorRef,
              private locationService: LocationService
  ) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngOnInit() {
    this.inputDisabled = false;
    this.inputPlaceholder = 'Type message';

    // Subscribe to and changes to location, page list and selected page
    this.io.listen('message/added', this.onAddMessage.bind(this));
    this.io.listen('message/updated', this.onUpdateMessage.bind(this));
    this.io.listen('message/deleted', this.onDeleteMessage.bind(this));
    this.shared.location.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.handleLocationChange.bind(this));
    this.shared.showFriendChat.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.handleShowFriendChat.bind(this));
    this.pageService.list.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.handlePageListChange.bind(this));
    this.pageService.current.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.handleCurrentPageChange.bind(this));
    this.pageService.messageGroups.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((messageGroups) => this.messageGroups = messageGroups);
    this.pageService.messages.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((messages) => this.messagesUpdate(messages));

    this.typing.pipe(debounceTime(300), takeUntil(this.ngUnsubscribe))
      .subscribe(typing => this.sendTypingEvent(typing));
    this.lastReadMessage.pipe(debounceTime(1000), pairwise(), takeUntil(this.ngUnsubscribe))
      .subscribe(([prev, next]: [any, any]) => {
        if (next !== prev) {
          this.sendLastRead();
        }
      });

    this.search.valueChanges
      .pipe(debounceTime(300), pairwise(), takeUntil(this.ngUnsubscribe))
      .subscribe(([prev, next]: [any, any]) => {
        if (typeof next != 'undefined' && next !== prev) {
          this.chatSearchFilter();
        }
      });
  }

  onAddMessage({ pageId, message }) {
    // buffered own messages need update, own media upload doesn't buffer
    if (this.isOwnMessage(message) && (this.isNotMedia(message) || this.isMediaCopy(message))) {
      this.onUpdateMessage({ pageId, message });
    } else {
      if (!this.page || pageId !== this.page._id) {
        return;
      }
      this.pageService.addMessage(message);
      setTimeout(() => {
        // @ts-ignore
        if (!this.cdr.destroyed) {
          this.cdr.detectChanges();
        }
      }, 1000);
      this.resetView();
    }
  }

  onUpdateMessage({ pageId, message }) {
    if (!this.page || pageId !== this.page._id) {
      return;
    }
    this.pageService.updateMessage(message);
    setTimeout(() => {
      // @ts-ignore
      if (!this.cdr.destroyed) {
        this.cdr.detectChanges();
      }
    }, 1000);
    this.resetView();
  }

  onDeleteMessage({ pageId, message }) {
    if (!this.page || pageId !== this.page._id) {
      return;
    }
    this.pageService.deleteMessage(message);
    setTimeout(() => {
      // @ts-ignore
      if (!this.cdr.destroyed) {
        this.cdr.detectChanges();
      }
    }, 1000);
    this.resetView();
  }

  /**
   * Updates a channel's typing indicator
   *
   * @param channel any
   * @param member any
   * @param typing Boolean
   */
  updateTypingIndicator(channel, member, typing) {
    const page = this.pages.find(p => p.channelId === channel.id);
    page.whoTyping = this.getUsername(this.getUserDetails(member.identity));
    page.isTyping = typing;
  }

  /**
   * Gets details about the current user
   * If it's the current user, get it from auth
   * Otherwise, get it from the page's friends list
   *
   * @param userId string
   * @returns any
   */
  getUserDetails(userId) {
    const user = this.shared.users.get(this.shared.user._id);
    if (!user) {
      return null;
    }

    user.isCurrentUser = userId === this.shared.user._id;
    return user;
  }

  getUsername(user) {
    return user.beamId && user.beamId.IdOne ? user.beamId.IdOne : user.username;
  }

  isPageOwner() {
    return this.page ? this.page.owner._id === this.shared.user._id : false;
  }

  startVideo() {
    this.util.popupCenter(`/#/video/${this.page._id}`, 'beamLive Video Call', 800, 600);
  }

  /**
   * Hides the video container
   */
  stopVideo() {
  }

  addVideoStatusMessage(status) {
    const message = `${this.shared.user.beamId.IdOne} ${status} video call`;
    this.sendMessage(message, { type: 'info' });
  }

  resetView() {
    setTimeout(() => {
      if (this.ChatArea) {
        if (this.nextChunk) {
          this.setScrollBottomPosition();
          this.nextChunk = false;
        } else if (this.prevChunk) {
          this.setScrollTopPosition();
          this.prevChunk = false;
        } else {
          if (this.searchResults && this.searchMessageId) {
            this.setScrollSearchPositionAndHighlight();
          } else {
            this.ChatArea.nativeElement.scrollTop = this.ChatArea.nativeElement.scrollHeight - this.ChatArea.nativeElement.clientHeight;
            this.lastChunk = true;
          }
          this.searchResults = false;
        }
      }
      if (this.MessageBox && !this.searching) {
        this.MessageBox.nativeElement.focus();
      }
    }, 50);
  }

  rsvp(accepted) {
    this.shared.chatLoading = true;
    this.io.send('page/rsvp', { pageId: this.page._id, status: accepted });
  }

  editPage() {
    this.editingPage = true;
    setTimeout(() => {
      this.EditPageTitle.nativeElement.focus();
    }, 0);
  }

  cancelSavePage() {
    this.editingPage = false;
  }

  async removePage() {
    if (this.page.flag === 'friends') {
      this.deleteFriendPage();
      return;
    }
    if (this.shared.user._id === this.page.owner._id) {
      const doTransfer = await this.confirmDeleteOrTransfer();
      if (doTransfer) {
        this.transferPage();
      } else {
        this.deletePage();
      }
    } else {
      this.removeMeFromPage();
    }
  }

  confirmDeleteOrTransfer() {
    if (!this.page || !this.page.friends || this.page.friends.length === 0) {
      return false;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Transfer ownership?',
        message: 'Would you instead like to transfer ownership of this page to another friend on the page? '
          + 'You will be removed from the page and will need to be invited back in to rejoin.',
      }
    });

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(resolve);
    });
  }

  transferPage() {
    this.dialog.open(TransferOwnershipDialogComponent, {
      width: '550px',
      height: '80%',
      data: {},
    });
  }

  deletePage() {
    const title = 'Delete page?';
    const message = 'Are you sure to delete this page?';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: { title, message },
    });

    dialogRef.afterClosed().subscribe(this.handlePageDelete.bind(this));
  }

  deleteFriendPage() {
    const title = 'Remove friend?';
    const message = 'Are you sure you want to remove this friend?';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: { title, message },
    });

    dialogRef.afterClosed().subscribe(this.handlePageDelete.bind(this));
  }

  removeMeFromPage() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Remove yourself from this page?',
        message: 'Are you sure to remove yourself from this page? You will need to be invited back to rejoin.',
      }
    });

    dialogRef.afterClosed().subscribe(this.handlePageDelete.bind(this));
  }

  recoverPage() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Recover page?',
        message: 'Are you sure to undelete this page?',
      }
    });

    dialogRef.afterClosed().subscribe(this.handlePageRecover.bind(this));
  }

  handlePageDelete(doDelete) {
    if (!doDelete) {
      return;
    }
    this.api.delete(`page/${this.page._id}`).subscribe((result) => {
      if (!result.status) {
        this.dialog.open(FailureDialogComponent, {
          width: '300px',
          data: { Message: result.message },
        });
      }
    });
  }

  handlePageRecover(doRecover) {
    if (!doRecover) {
      return;
    }
    this.io.send('page/undelete', { pageId: this.page._id });
  }

  savePage() {
    this.api.put(`page/${this.page._id}`, { title: this.editedPageTitle }).subscribe((result) => {
      if (!result.status) {
        this.dialog.open(FailureDialogComponent, {
          width: '300px',
          data: { Message: result.message },
        });
        this.editingPage = false;
        this.editedPageTitle = this.page.title;
      } else {
        this.page.title = this.editedPageTitle;
        this.editingPage = false;
      }
    }, (result) => {
      this.dialog.open(FailureDialogComponent, {
        width: '300px',
        data: { Message: result.message },
      });
      this.editingPage = false;
      this.editedPageTitle = this.page.title;
    });
  }

  replyToBroadcast(user, message) {
    // Create a new friend page and add the broadcast message to it
    this.shared.chatLoading = true;
    this.api.post('page', { appId: '', memberIds: [user._id], flag: 'friends' }).subscribe((data) => {
      const page = data.meta_data.page || data.meta_data.duplicatePage;
      const isNew = data.status === 200;
      this.shared.chatLoading = false;
      if (isNew) {
        this.pagesEvent.subscribe((type) => {
          if (type === 'updated') {
            this.pagesEvent.unsubscribe();
            this.pageService.selectPage(page);
            this.util.delay(0);
            this.sendMessage(message.body, message.attributes);
          }
        });
      } else {
        this.pageService.selectPage(page);
        this.util.delay(0);
        this.sendMessage(message.body, message.attributes);
      }
    });
  }

  /**
   * Handles changes to the currently selected location
   * Send the location as a twilio message with the type 'location'
   * Sending it with 'type' location in the attributes will ensure
   * it's displayed with the clickable location icon
   *
   * @param location any
   */
  // @@TODO Better handling of two types of location requests
  async handleLocationChange(data: any) {
    const location = data.message ? data.message.attributes.data : data;

    if (data.message) {
      switch (data.action) {
        case 'update':
          const { message } = data;
          message.body = `Location: ${data.message.attributes.data.gps.title}`;
          await this.updateMessage(message);
          break;
        case 'delete':
          await this.removeMessage(data.message);
          break;
        case 'copy':
          await this.copyMessageToClipboard(data.message);
          break;
      }
    } else {
      const locationAddedMessage = `Location: ${location.data.gps.title}`;
      // Send the location message to the current channel
      this.sendMessage(locationAddedMessage, { type: 'location', data: location.data });
    }
  }

  /**
   * Reload channels when page list is refreshed
   *
   * @param pages Array<any>
   */
  async handlePageListChange(pages: Array<any>) {
    this.pages = pages;
    this.pagesEvent.emit('updated');
  }

  /**
   * Handles changes to the currently selected page
   * Reloads the channel if page's channel id has changeda
   * @param page any
   */
  async handleCurrentPageChange(page: any) {
    if (!page || !page._id) {
      this.page = null;
      this.editedPageTitle = '';
      this.shared.showFriendChat.emit();
      return;
    }
    page.unreadCount = 0;
    const cachedPage = this.pages.find(p => p._id === page._id);
    if (cachedPage) {
      cachedPage.isTyping = page.isTyping;
      cachedPage.whoTyping = page.whoTyping;
      page = cachedPage;
    }
    this.page = page;
    this.editedPageTitle = page.title;
    this.friendChatFriend = null;
    this.showFriendChat = false;
    this.showFriendSentInvite = false;
    this.shared.showFriendChat.emit();

    this.inputDisabled = false;
    this.inputPlaceholder = 'Type message';
    if (this.page.isInvitation) {
      this.inputDisabled = true;
      this.inputPlaceholder = `Accept ${this.page.invitedBy}'s invitation to start chatting`;
    }
    if (this.page.flag === 'broadcast' && !this.isPageOwner()) {
      this.inputDisabled = true;
      this.inputPlaceholder = 'To respond to a broadcast message, click the Reply button on that message';
    }
    this.pageChanged = true;
    this.firstChunk = false;
    this.resetFilter();
    this.clearSearch();
  }

  handleShowFriendChat(user: any) {
    if (this.shared.chatLoading) {
      return;
    }
    if (user) {
      this.shared.chatLoading = true;
      this.api.post('page', { appId: '', memberIds: [user._id], flag: 'friends' }).subscribe((data) => {
        const page = data.meta_data.page;
        this.pageService.selectPage(null);
        this.showFriendChat = true;
        this.friendChatFriend = user;
        this.shared.chatLoading = false;

        if (page) {
          if (!page.isInvitation || page.isDeleted) {
            this.pageService.selectPage(page);
          } else {
            this.showFriendChat = false;
            this.showFriendSentInvite = false;
          }
        }
      });
    } else {
      this.showFriendChat = false;
      this.showFriendSentInvite = false;
    }
  }

  handleStartFriendChat() {
    this.shared.chatLoading = true;
    this.api.post('page', {
      appId: '',
      memberIds: [this.friendChatFriend._id],
      flag: 'friends'
    }).subscribe((_data) => {
      const _page = _data.meta_data.page;
      this.shared.chatLoading = false;
      this.showFriendSentInvite = true;

      const pages = [...this.pages];
      if (!_page.title) {
        if (_page.owner._id === this.shared.user._id && _page.friends[0]) {
          _page.title = this.getUsername(_page.friends[0].userId);
        } else {
          _page.title = this.getUsername(_page.owner);
        }
      }

      pages.unshift(_page);
      this.pageService.updatePages(pages);
      setTimeout(() => {
        this.pageService.selectPage(_page);
        this.showFriendSentInvite = false;
      }, 0);
    });
  }

  /**
   * Handles clicking on a location message icon
   * Passes on the message to the relevant shared data object
   *
   * @param message any
   */
  handleLocationClick(message) {
    this.shared.ShowPointeronMap.emit(message);
    if (message.data.geofence &&
      (!message.data.geofenceExpiration || new Date(message.data.geofenceExpiration) > new Date())) {
      this.shared.ShowMapGeofence.emit(message);
    }
  }

  handleGPSClick(message) {
    const iot = this.page.flag === 'iot';
    if (message.attributes.data.live || this.shared.user._id === message.owner) {
      this.shared.ShowMapGPSLive.emit({ ...message.attributes.data, isOwner: true, iot });
    } else {
      this.dialog.open(FailureDialogComponent, {
        width: '300px',
        data: { Message: 'GPS is inactive' },
      });
    }
  }

  handleGPSRightClick(event, message) {
    const editable = this.shared.user._id === message.owner;
    if (!editable) {
      return true;
    }

    const dialogRef = this.dialog.open(GPSTrackerToggleComponent, {
      width: '300px',
      data: { ...message.attributes.data, editable, type: 'sensor' },
    });

    dialogRef.afterClosed().subscribe(async ({
                                               action, title, live, reporting, generatedReport,
                                               reportingStart, reportingEnd, lastLocation
                                             }) => {
      if (action && action === 'update') {
        message.body = `GPS Sensor: ${title}`;
        await this.updateMessage(message);
      } else if (action && action === 'delete') {
        await this.removeMessage(message);
      } else if (action && action === 'copy') {
        await this.copyMessageToClipboard(message);
      } else if (generatedReport && reportingStart) {
        this.shared.ShowMapGPSLive.emit({
          ...message.attributes.data,
          reportingStart, reportingEnd, lastLocation, showReport: true
        });
      } else if (live && !message.attributes.data.live) {
        message.attributes.data = {
          ...message.attributes.data,
          live,
          reporting,
          reportingStart: new Date(),
        };
        await this.updateMessage(message);
        this.shared.ShowMapGPSLive.emit({ ...message.attributes.data, live, reporting });
      } else if (!live && message.attributes.data.live) {
        message.attributes.data = {
          ...message.attributes.data,
          live,
          reporting,
        };
        await this.updateMessage(message);
        this.shared.ShowMapGPSLive.emit({ ...message.attributes.data, live, reporting });

        const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          data: {
            title: 'Archive Data?',
            message: 'Do you want to archive the GPS data for the reporting session?',
          }
        });

        confirmDialogRef.afterClosed().subscribe((doSaveReport) => {
          if (doSaveReport) {
            this.gps.post('gps/last-location', { imei: message.attributes.data.deviceKey })
              .subscribe(async (data) => {
                const currentLocation = data.meta_data.gps;
                this.sendMessage(`GPS Report: ${message.attributes.data.title}`, {
                  type: 'gps-report',
                  data: {
                    title: message.attributes.data.title,
                    deviceKey: message.attributes.data.deviceKey,
                    reportingStart: message.attributes.data.reportingStart,
                    lastLocation: currentLocation,
                    reportingEnd: Date.now(),
                  },
                });

                message.attributes.data = {
                  ...message.attributes.data,
                  reporting: false,
                  reportingStart: null,
                  reportingEnd: null,
                };
                await this.updateMessage(message);
              });
          }
        });
      }
    });

    event.stopPropagation();
    event.preventDefault();
  }

  handleGPSReportClick(message) {
    this.shared.ShowMapGPSLive.emit({ ...message.attributes.data, showReport: true });
  }

  handleGPSReportRightClick(event, message) {
    const dialogRef = this.dialog.open(GPSTrackerToggleComponent, {
      width: '300px',
      data: { ...message.attributes.data, editable: true, type: 'report' },
    });

    dialogRef.afterClosed().subscribe(async (response) => {
      if (response.action && response.action === 'delete') {
        await this.removeMessage(message);
      } else if (response.action && response.action === 'copy') {
        await this.copyMessageToClipboard(message);
      } else {
        this.shared.ShowMapGPSLive.emit({ ...message.attributes.data, report: response.report });
      }
    });

    event.stopPropagation();
    event.preventDefault();
  }

  handleVideoRecordingClick(message) {
    this.api.get(`page/video/record/media?id=${message.attributes.data.id}`)
      .subscribe((response) => {
        const url = response.url;
        this.handleVideoAudioMessage(message, url, true);
      });
  }

  handleMedicalReportClick(message) {
    this.dialog.open(MedicalReportDialogComponent, {
      width: '500px',
      data: message.attributes.data,
    });

    event.stopPropagation();
    event.preventDefault();
  }

  /**
   * Opens the import dialog
   */
  handleImportButtonClick() {
    const dialogRef = this.dialog.open(ChatImportDialogComponent, { width: '400px', data: {} });
    dialogRef.afterClosed().subscribe((action) => {
      if (!action) {
        return;
      }

      switch (action) {
        case 'people':
          this.dialog.open(ListFriendsDialogComponent, {
            width: '430px',
            height: '80%',
            data: {},
          });
          break;
        case 'gps':
          this.dialog.open(WarningDialogComponent, {
            width: '400px',
            data: { Message: 'GPS reporting is available on your beamLive mobile apps' },
          });
          break;
        case 'place':
          this.showMap();
          break;
        case 'media':
          this.handleMedia();
          break;
        case 'rsvp':
          this.handleRSVP();
          break;
        case 'event':
          this.handleEvent();
          break;
        default:
      }
    });
  }

  /**
   * Handle auto expansion of input gox
   *
   * @param event KeyboardEvent
   */
  handleMessageKeyDown(event: KeyboardEvent) {
    // Auto expand input box
    // @ts-ignore
    event.currentTarget.style.height = 'auto';
    // @ts-ignore
    event.currentTarget.style.height = `${event.currentTarget.scrollHeight}px`;
  }

  /**
   * Handle message hit enter key and send out a typing indicator
   *
   * @param event KeyboardEvent
   */
  handleMessageKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13 && !event.shiftKey) {
      // @ts-ignore
      event.currentTarget.style.height = 'auto';
      // @ts-ignore
      const message = event.currentTarget.value.trim();
      if (message !== '') {
        this.sendMessage(message);
        // @ts-ignore
        event.currentTarget.value = '';
      }
      event.preventDefault();
    } else {
      if (!this._typing.getValue()) {
        this.pageService.sendTypingEvent(true);
      }
      this._typing.next(true);
    }
  }

  /**
   * Handle updates to the channel
   *
   * @param param
   */
  handleChannelUpdated({ channel, updateReasons }) {
    if (updateReasons.includes('attributes')) {
    }
  }

  handleLocationMessageRightClick($event: MouseEvent, message, element, editable: boolean = false) {
    const dialogRef = this.dialog.open(LocationMessageMenuDialogComponent, {
      width: '580px',
      data: { message, element, editable },
    });
    dialogRef.afterClosed().subscribe();
    $event.preventDefault();
    $event.stopPropagation();
  }

  handleMessageRightClick($event: MouseEvent, message, editable: boolean = false) {
    if (message.type === 'gps' || (message.type === 'rsvp-response' && !editable)) {
      return;
    }

    const dialogRef = this.dialog.open(MessageMenuDialogComponent, {
      width: '300px',
      data: { message, editable },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.message) {
        const { action, message, data } = result;
        switch (action) {
          case 'update':
            message.body = data;
            await this.updateMessage(message);
            break;
          case 'delete':
            await this.removeMessage(message);
            break;
          case 'copy':
            await this.copyMessageToClipboard(message);
            break;
        }
      }
    });

    $event.preventDefault();
    $event.stopPropagation();
  }

  handleMedia() {
    this.dialog.open(MediaUploadComponent, { width: '500px', data: { pageId: this.page ? this.page._id : null } });
  }

  handleRSVP() {
    this.dialog
      .open(CreateRSVPComponent, { width: '500px', data: { pageId: this.page ? this.page._id : null } })
      .afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }
      const { body, attributes } = data;
      this.sendMessage(body, attributes);
    });
  }

  handleEvent() {
    this.dialog
      .open(CreateEventComponent, { width: '470px', data: {} })
      .afterClosed().subscribe((data) => {
      if (!data) {
        return;
      }
      for (const event of data) {
        const { body, attributes } = event;
        this.sendMessage(body, attributes);
      }
    });
  }

  async handleInputPaste($event: ClipboardEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    if (!await this.addClipboardMessage($event)) {
      this.MessageBox.nativeElement.value += this.clipboardMessage;
    }
    this.clipboardMessage = null;
  }

  async sendMessage(body: string, attributes: any = null) {
    if (this.page && this.page.flag && this.page.flag === 'broadcast') {
      if (!attributes) {
        attributes = {
          data: {
            isBroadcast: true,
            sender: this.shared.user,
            broadcastPage: this.page.title
          }
        };
      } else if (attributes.data) {
        attributes.data.isBroadcast = true;
        attributes.data.sender = this.shared.user;
        attributes.data.broadcastPage = this.page.title;
      } else {
        attributes.data = { isBroadcast: true, broadcastPage: this.page.title };
      }
    }
    const params = {
      body: body,
      attributes: attributes,
      owner: this.shared.user._id,
      createdAt: Date.now(),
      page: this.page._id,
    };
    this.pageService.addMessage(params);
    this.io.send('message/new', { pageId: this.page._id, message: { body, attributes } });
    this.resetFilter();
    this.clearSearch();
  }

  async updateMessage(message) {
    this.io.send('message/update', { pageId: this.page._id, message });
  }

  async removeMessage(message) {
    this.io.send('message/delete', { pageId: this.page._id, message });
  }

  async addClipboardMessage($event: ClipboardEvent) {
    if (!await this.validateClipboardMessage($event)) {
      return false;
    }
    if ($event.clipboardData.files.length > 0) {
      this.handlefileDrop($event.clipboardData.files);
    } else {
      const message = JSON.parse(this.clipboardMessage);
      this.sendMessage(message.body, message.attributes);
    }
    return true;
  }

  async validateClipboardMessage($event) {
    return new Promise((resolve) => {
      if ($event.clipboardData.files.length > 0) {
        resolve(true);
      } else {
        const text = $event.clipboardData.getData('text/plain');
        try {
          const data = JSON.parse(text);
          if (data.body !== null && data.attributes) {
            this.clipboardMessage = text;
            resolve(true);
          }
          resolve(false);
        } catch (e) {
          this.clipboardMessage = text;
          resolve(false);
        }
      }
    });
  }

  copyMessageToClipboard(message) {
    let attributes = { ...message.attributes };
    if (attributes.type === 'info') {
      attributes = {};
    } else if (attributes.type === 'media') {
      attributes['copy'] = true;
    }
    const data = { body: message.body, attributes };
    const text = JSON.stringify(data);
    (navigator as any).clipboard.writeText(text);
    this.clipboardMessage = text;
  }

  /**
   * Hide the chat window
   */
  chatClose() {
    const { className } = this.document.body;
    this.shared.removeBodyClasses();
    if (className === 'chat-only') {
      this.document.body.classList.add('leftnav-only');
    } else if (className === '') {
      this.document.body.classList.add('map-only');
    } else {
      this.document.body.classList.add('map-only');
    }
  }

  /**
   * Show the map
   */
  showMap() {
    this.shared.removeBodyClasses();
    this.shared.ShowMap = true;
    this.shared.ShowMapView.emit({ MapShow: true, Type: MapType.Page });
  }

  async handlefileDrop(files) {
    const fileList = [];
    for (const file of files) {
      const fileData = await this.fileParser(file);
      fileList.push(fileData);
    }
    this.upload(fileList);
    this.isDragging = false;
  }

  async fileParser(file) {
    return new Promise(async (s, r) => {
      const location = await this.getCurrentLocation();
      const data: any = {
        filename: file.name,
        size: file.size,
        mimetype: file.type,
        pageId: this.page ? this.page._id : null,
        location: location
      };
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: ProgressEvent) => {
        const content = (e.target as FileReader).result;
        data.content = content;
        s(data);
      };
    });
  }

  async getCurrentLocation() {
    return new Promise(s => {
      this.locationService.current.subscribe(location => {
        s(location);
      });
    });
  }

  upload(fileList) {
    if (fileList.length) {
      this.io.send('media/upload', fileList, (err, data) => {
        if (err) {
          this.dialog.open(FailureDialogComponent, { data: { Title: err } });
        }
      });
    }
  }

  onDragOver($event) {
    if ($event.dataTransfer.items.length > 0) {
      if (Array.from($event.dataTransfer.items).some(dataTransfer => dataTransfer['kind'] === 'file')) {
        event.stopPropagation();
        event.preventDefault();
        this.isDragging = true;
      }
    }
  }

  onDragLeave($event) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
  }

  handleMediaMessage(message) {
    switch (message.attributes.data.mediaType) {
      case 'Image':
        this.handleImageMessage(message);
        break;
      case 'Video':
        this.handleVideoAudioMessage(message);
        break;
      case 'Audio':
        this.handleVideoAudioMessage(message);
        break;
      default:
        this.handleDocMessage(message);
    }
  }

  handleDocMessage(message: any) {
    if (this.viewableDoc(message)) {
      const filename = message.attributes.data.filename;
      const doc = `${Env.apiUrl}${Env.uploads}/${filename}`;
      const url = `${Env.uploads}/${filename}`;
      this.dialog.open(DocumentViewerComponent, {
        width: '850px',
        maxWidth: '100%',
        height: '600px',
        data: { doc, url, filename }
      });
    } else {
      const docUrl = `${Env.uploads}/${message.attributes.data.filename}`;
      this.api.getMedia(docUrl).subscribe(res => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        document.body.appendChild(a);
        a.download = message.attributes.data.filename;
        a.click();
        document.body.removeChild(a);
      });
    }
  }

  handleVideoAudioMessage(message: any, mediaUrl?: string, isVideo?: boolean) {
    const url = mediaUrl ? mediaUrl : `${Env.apiUrl}${Env.uploads}/${message.attributes.data.filename}`;
    if (message.attributes.data.mediaType === 'Video' || isVideo) {
      this.dialog.open(VideoViewer, {
        width: '850px',
        height: '600px',
        data: { url: url, isVideo: true, isAudio: false }
      });
    } else {
      this.dialog.open(VideoViewer, { width: '350px', data: { url: url, isVideo: false, isAudio: true } });
    }
  }

  handleImageMessage(message: any) {
    const imageUrl = `${Env.apiUrl}${Env.uploads}/${message.attributes.data.filename}`;
    this.dialog.open(ImageViewer, { data: { imageUrl: imageUrl } });
  }

  handleRSVPMessage(message: any, messageText: string, color: string) {
    const responseTo = message._id;
    const rsvpResponse = this.messages.find(msg =>
      msg.attributes.type === 'rsvp-response'
      && msg.owner === this.shared.user._id
      && msg.attributes.responseTo === responseTo
    );
    const responseText = `${message.body}: ${messageText}`;
    if (!rsvpResponse) {
      this.sendMessage(responseText, { type: 'rsvp-response', responseTo });
    } else {
      rsvpResponse.body = responseText;
      this.updateMessage(rsvpResponse);
    }
    const response = {
      userId: this.shared.user._id,
      beamId: this.shared.user.beamId.IdOne,
      color,
      text: messageText,
    };
    if (!message.attributes.data.responses) {
      message.attributes.data.responses = [];
      message.attributes.data.responses.push(response);
    } else {
      const userResponseIdx = message.attributes.data.responses.findIndex(resp =>
        resp.userId === this.shared.user._id
      );
      if (userResponseIdx === -1) {
        message.attributes.data.responses.push(response);
      } else {
        message.attributes.data.responses[userResponseIdx] = {
          userId: this.shared.user._id,
          beamId: this.shared.user.beamId.IdOne,
          color,
          text: messageText,
        };
      }
    }
    this.updateMessage(message);
  }

  getSizeInMb(data) {
    if (!data || !data.size) {
      return '0 KB';
    }
    return ((this.oneMbInBytes * data.size) < 1) ?
      `${(this.oneKbInBytes * data.size).toFixed(2)} KB` :
      `${(this.oneMbInBytes * data.size).toFixed(2)} MB`;
  }

  getHumanFriendlyEta(eta) {
    if (eta === null) {
      return 'Unknown';
    }

    if (eta === 0) {
      return 'Arrived';
    }

    if (eta <= 60) {
      return 'Arriving soon';
    }

    const minutes = Math.ceil(eta * (1 / 60));
    return `${minutes} minutes`;
  }

  formatEventTimestamp(timestamp: Date): string {
    //return Sugar.Date.create(timestamp).full();
    return formatDate(timestamp,'fullTime',' en-US');
    //return timestamp.toString();
  }

  handleEventClick() {
    this.dialog.open(EventsCalendarComponent, {
      width: '750px',
      data: {
        messages: this.getPageEvents(),
      },
    });
  }

  handleEventRightClick(event, message) {
    const editable = this.shared.user._id === message.owner;
    this.dialog
      .open(EventMessageMenuDialogComponent, {
        width: '300px',
        data: { message, editable },
      })
      .afterClosed().subscribe(async (response) => {
      if (response.action && response.action === 'delete') {
        this.dialog
          .open(ConfirmationDialogComponent, {
            width: '300px',
            data: {
              title: 'Cancel Event?',
              message: 'Are you sure to want to cancel this event?',
            }
          }).afterClosed().subscribe(async (doCancel) => {
          if (!doCancel) {
            return;
          }
          await this.removeMessage(message);
          await this.sendMessage(
            `${message.data.title} cancelled by ${this.shared.user.beamId.IdOne}`,
            { type: 'info' },
          );
        });
      } else if (response.action && response.action === 'copy') {
        await this.copyMessageToClipboard(message);
      } else if (response.action && response.action === 'edit') {
        this.dialog
          .open(CreateEventComponent, { width: '470px', data: { message } })
          .afterClosed().subscribe((data) => {
          if (!data) {
            return;
          }
          const { body, attributes } = data[0];
          message.body = body;
          message.attributes = attributes;
          this.updateMessage(message);
        });
      }
    });

    event.stopPropagation();
    event.preventDefault();
  }

  handleRSVPClick(event, message) {
    this.dialog.open(RSVPResponseListDialogComponent, { width: '300px', data: { message } });
  }

  handleRSVPRightClick(event, message) {
    const editable = this.shared.user._id === message.owner;
    this.dialog
      .open(RSVPMessageMenuDialogComponent, {
        width: '300px',
        data: { message, editable },
      })
      .afterClosed().subscribe(async (response) => {
      if (response.action && response.action === 'delete') {
        this.dialog
          .open(ConfirmationDialogComponent, {
            width: '300px',
            data: {
              title: 'Delete RSVP?',
              message: 'Are you sure to delete this RSVP?',
            }
          }).afterClosed().subscribe(async (doDelete) => {
          if (!doDelete) {
            return;
          }
          await this.removeMessage(message);
          await this.sendMessage(
            `RSVP ${message.body} removed by ${this.shared.user.beamId.IdOne}`,
            { type: 'info' },
          );
        });
      } else if (response.action && response.action === 'copy') {
        await this.copyMessageToClipboard(message);
      } else if (response.action && response.action === 'edit') {
        this.dialog
          .open(CreateRSVPComponent, { width: '300px', data: { message } })
          .afterClosed().subscribe((data) => {
          if (!data) {
            return;
          }
          const { body, attributes } = data;
          message.body = body;
          message.attributes = attributes;
          this.updateMessage(message);
        });
      }
    });

    event.stopPropagation();
    event.preventDefault();
  }

  getPageEvents() {
    return this.messages.filter(message => message.attributes.type === 'event');
  }

  onChatScroll($event) {
    if (!this.pageChanged) {
      if (this.messages && this.messages.length > 0) {
        const scrollEnd = this.ChatArea.nativeElement.scrollHeight - this.ChatArea.nativeElement.clientHeight - 5;
        if (this.ChatArea.nativeElement.scrollTop === 0 && !this.firstChunk) {
          this.getPrevChunk();
        } else if (this.ChatArea.nativeElement.scrollTop >= scrollEnd && !this.lastChunk && !this.nextChunk) {
          this.getNextChunk();
        }
      }
    } else {

      this.pageChanged = false;
    }
  }

  getPrevChunk() {
    this.scrollToId = this.messages[0]._id;
    this.lastChunk = false;
    const data = { pageId: this.page._id, lastMessageId: this.messages[0]._id, filter: this.assembleFilter() };
    this.sendMessageListRequest(data, this.processPrevChunk);
  }

  getNextChunk() {
    this.scrollToId = this.messages[this.messages.length - 1]._id;
    this.firstChunk = false;
    const data = {
      pageId: this.page._id,
      firstMessageId: this.messages[this.messages.length - 1]._id,
      filter: this.assembleFilter()
    };
    this.sendMessageListRequest(data, this.processNextChunk);
  }

  clearSearch() {
    this.search.setValue('');
  }

  chatSearchFilter() {
    this.searching = (this.search.value && this.search.value !== '');
    this.firstChunk = false;
    this.lastChunk = false;
    if (this.searching) {
      this.resetFilter();
      const data = { pageId: this.page._id, searchText: this.search.value };
      this.sendMessageSearchRequest(data, (err, { messages, resultCount, result }) => {
        if (messages) {
          this.searchResults = true;
          this.searchResultCount = resultCount;
          this.currentSearchResultNumber = resultCount > 0 ? resultCount : 0;
          this.currentSearchResultNumberInMessage = 0;
          this.searchMessageId = result;
          this.pageChanged = true;
          this.pageService.setMessages(messages);
        }
      });
    } else {
      const data = { pageId: this.page._id, filter: this.assembleFilter() };
      this.sendMessageListRequest(data, (err, messages) => {
        this.pageService.setMessages(messages);
      });
    }
  }

  nextSearchResult() {
    if (this.currentSearchResultNumberInMessage > 0) {
      this.currentSearchResultNumberInMessage--;
      this.searchResults = true;
      this.pageService.setMessages(this.messages);
    } else if (this.currentSearchResultNumber < this.searchResultCount) {
      this.currentSearchResultNumber++;
      const data = { pageId: this.page._id, searchText: this.search.value, resultNum: this.currentSearchResultNumber };
      this.sendMessageSearchRequest(data, this.stepSearchNext);
    }
  }

  prevSearchResult() {
    if (this.getCurrentMessageSearchMatchNumber() > this.currentSearchResultNumberInMessage + 1) {
      this.currentSearchResultNumberInMessage++;
      this.searchResults = true;
      this.pageService.setMessages(this.messages);
    } else if (this.currentSearchResultNumber !== 1) {
      this.currentSearchResultNumber--;
      const data = { pageId: this.page._id, searchText: this.search.value, resultNum: this.currentSearchResultNumber };
      this.sendMessageSearchRequest(data, this.stepSearchPrev);
    }
  }

  getSearchResult() {
    return '| ' + (this.searchResultCount - this.currentSearchResultNumber + 1) + ' / ' + this.searchResultCount;
  }

  getFilterIconClass(messageType: string, mediaType?) {
    const messageTypes = messageType.split(', ');
    messageTypes[0] = mediaType ? messageTypes[0] + '-' + mediaType : messageTypes[0];
    if (this.filterObjectType.length === 0 || this.filterObjectType.indexOf(messageTypes[0]) > -1) {
      return '';
    }
    return 'filter-ico-item-not-selected';
  }

  removeSentIndicator(messages) {
    this.messageGroups.forEach(mg => {
      for (let i in mg.messages) {
        const msg = mg.messages[i];
        if (msg.updated) {
          for (let message of messages) {
            if (msg._id === message._id) {
              mg.messages[i].updated = false;
            }
          }
        }
      }
    });
  }

  setLastRead() {
    if (this.page && this.messages.length > 0 && this.shared.user && this.lastChunk) {
      if (this.document.hidden) {
        this.document.addEventListener('visibilitychange',
          () => this._lastReadMessage.next(this.messages[this.messages.length - 1]._id), { once: true });
      } else if (!this.document.hasFocus()) {
        this.document.addEventListener('focusin',
          () => this._lastReadMessage.next(this.messages[this.messages.length - 1]._id), { once: true });
      } else {
        this._lastReadMessage.next(this.messages[this.messages.length - 1]._id)
      }
    }
  }

  private sendLastRead() {
    if (this.messages.length > 0) {
      const lastMessage = this.messages[this.messages.length - 1];
      const beamId = this.shared.user.beamId.IdOne;
      if (lastMessage._id && (!lastMessage.lastRead || !lastMessage.lastRead.some(user => user.beamId === beamId))) {
        const data = {
          beamId: beamId,
          pageId: this.page._id,
          messageId: lastMessage._id
        };
        this.io.send('message/last-read', data, () => {
        });
      }
    }
  }

  private filterBy(event, messageType: string, mediaType?: string) {
    event.stopPropagation();
    const messageTypes = messageType.split(', ');
    for (let mt of messageTypes) {

      if (mediaType) {
        const mediaIndex = this.mediaTypes.indexOf(mediaType);
        if (mediaIndex > -1) {
          this.mediaTypes.splice(mediaIndex, 1);
          if (this.mediaTypes.length === 0) {
            this.objectTypes.splice(this.objectTypes.indexOf(mt), 1);
          }
        } else {
          this.mediaTypes.push(mediaType);
          if (this.mediaTypes.length === 1) {
            this.objectTypes.push(mt);
          }
        }
        mt = mt + '-' + mediaType;
      } else {
        const typeIndex = this.objectTypes.indexOf(mt);
        if (typeIndex > -1) {
          this.objectTypes.splice(typeIndex, 1);
        } else {
          this.objectTypes.push(mt);
        }
      }
      const indexOf = this.filterObjectType.indexOf(mt);
      if (indexOf > -1) {
        this.filterObjectType.splice(indexOf, 1);
      } else {
        this.filterObjectType.push(mt);
      }
    }
    this.pageChanged = true;
    if (this.searching) {
      this.clearSearch();
    } else {
      this.chatSearchFilter();
    }
  }

  private resetFilter() {
    this.filterObjectType = [];
    this.objectTypes = [];
    this.mediaTypes = [];
  }

  private assembleFilter() {
    if (this.filterObjectType.length > 0) {
      let filter = <any>{};
      filter.type = this.objectTypes.filter(type => type !== 'media');
      if (this.objectTypes.some(type => type === 'media')) {
        filter.mediaType = this.mediaTypes;
      }
      return filter;
    }
  }

  private isOwnMessage(message) {
    return message.owner === this.shared.user._id;
  }

  private isNotMedia(message) {
    return !message.attributes || message.attributes.type !== 'media';
  }

  private isMediaCopy(message) {
    return message.attributes && message.attributes.copy && message.attributes.type === 'media';
  }

  private setScrollBottomPosition() {
    const scrollTo = document.getElementById(this.scrollToId);
    if (scrollTo) {
      scrollTo.scrollIntoView(false);
    }
  }

  private setScrollTopPosition() {
    const scrollTo = document.getElementById(this.scrollToId);
    if (scrollTo) {
      scrollTo.scrollIntoView(true);
    }
  }

  private setScrollSearchPositionAndHighlight() {
    const searchMessage = document.getElementById(this.searchMessageId);
    if (searchMessage) {
      const texts = searchMessage.getElementsByClassName('chat-text');
      if (texts && texts.length > 0) {
        this.highlightSearchWords(texts[0]);
        const word = document.getElementById('searchResult');
        if (word) {
          this.pageChanged = true;
          word.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      }
    }
  }

  private highlightSearchWords(text: Element) {
    const searchValue = new RegExp(this.search.value, 'ig');
    const number = text.innerHTML.split(searchValue).length - 1;
    const n = number - this.currentSearchResultNumberInMessage;
    let i = 0;
    const html = text.innerHTML;
    text.innerHTML = html.replace(searchValue, function (match) {
      const replaceBold = '<b id="searchResult" style=\"background-color:yellow\">' + match + '</b>';
      const replaceYellow = '<span style=\"background-color:yellow\">' + match + '</span>';
      i++;
      return i === n ? replaceBold : replaceYellow;
    });
  }

  private viewableDoc(message: any) {
    const fileExtensions = 'txt, css, html, php, c, cpp, h, hpp, js, doc, docx, xls, xlsx, ppt, pptx, pdf, pages, ai, psd, tiff, dxf, svg, eps, ps, ttf, xps, zip, rar';
    const extension = message.data.filename.split('.').pop().toLowerCase();
    const sizeLimit = 2.5e+7; // 25MB
    return message.data.size < sizeLimit && fileExtensions.indexOf(extension) > -1;
  }

  private sendMessageListRequest(data, processFunction) {
    this.io.send('message/list', data, (err, { messages }) => {
      processFunction(err, messages);
    });
  }

  private processNextChunk = (err, receivedMessages: any) => {
    if (receivedMessages.length > 0) {
      let messages = this.messages.concat(receivedMessages);
      if (messages.length > this.chunkCache * this.chunkSize) {
        messages = messages.slice(this.chunkSize, messages.length);
      }
      this.nextChunk = true;
      this.pageService.setMessages(messages);
    } else {
      this.lastChunk = true;
    }
  }

  private processPrevChunk = (err, receivedMessages: any) => {
    if (receivedMessages.length > 0) {
      let messages = receivedMessages.concat(this.messages);
      if (messages.length > this.chunkCache * this.chunkSize) {
        messages = messages.slice(0, this.chunkCache * this.chunkSize);
      }
      this.prevChunk = true;
      this.pageService.setMessages(messages);
    } else {
      this.firstChunk = true;
    }
  }

  private sendMessageSearchRequest(data, processFunction) {
    this.io.send('message/search', data, (err, { messages, resultCount, result }) => {
      processFunction(err, { messages, resultCount, result });
    });
  }

  private getCurrentMessageSearchMatchNumber() {
    const resultMessage = this.messages.find(m => m._id === this.searchMessageId);
    const searchValue = new RegExp(this.search.value, 'i');
    return resultMessage.body.split(searchValue).length - 1;
  }

  private stepSearchNext = (err, { messages, resultCount, result }) => {
    this.stepSearch(messages, result);
    this.currentSearchResultNumberInMessage = this.getCurrentMessageSearchMatchNumber() - 1;
  };

  private stepSearchPrev = (err, { messages, resultCount, result }) => {
    this.stepSearch(messages, result);
    this.currentSearchResultNumberInMessage = 0;
  };

  private stepSearch = (messages, result) => {
    if (messages) {
      this.searchResults = true;
      this.searchMessageId = result;
      this.pageService.setMessages(messages);
    }
  };

  private sendTypingEvent(typing: boolean) {
    this.pageService.sendTypingEvent(typing);
    setTimeout(() => {
      if (this._typing.value === true) {
        this._typing.next(false);
      }
    }, 3000);
  }

  private messagesUpdate(messages: any) {
    this.messages = messages;
    this.resetView();
    if (this.page && (this.page.showGps || this.page.flag === 'iot')) {
      const gpsMessage = this.messages.find(message => message.attributes.type === 'gps' && message.attributes.data.live);
      if (gpsMessage) {
        setTimeout(() => this.handleGPSClick(gpsMessage), 0);
      }
    }
    const updatedMessages = this.messages.filter(m => m.updated);
    if (updatedMessages.length > 0) {
      setTimeout(() => this.removeSentIndicator(updatedMessages), 2000);
    }
    this.setLastRead();
  }

  getLocalDateString(date: any) {
    return new Date(date).toLocaleString();
  }
}
