import * as TwilioVideo from 'twilio-video';
import { connect, LocalVideoTrack } from 'twilio-video';
import {Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '~common/api.service';
import {IOService} from '~common/io.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class VideoComponent implements OnInit {

  @ViewChild('VideoWrapper', { static: true }) VideoWrapper: ElementRef;
  @ViewChild('LocalVideo', { static: true }) LocalVideo: ElementRef;
  @ViewChild('LocalAudio', { static: true }) LocalAudio: ElementRef;
  @ViewChild('RemoteVideo', { static: true }) RemoteVideo: ElementRef;
  @ViewChild('RemoteAudio', { static: true }) RemoteAudio: ElementRef;

  private twilioToken: string;
  public loading = false;
  private micActive = true;
  private speakerActive = true;
  private videoAllowed = false;
  private videoActive = false;
  private shareScreenActive = false;
  public friendConnected = false;
  public showSelectPageMessage = false;
  public showErrorMessage = false;
  public showPermissionError = false;
  public page: any;
  private room: any;

  constructor(
    private api: ApiService,
    private io: IOService,
    private route: ActivatedRoute,
    public renderer: Renderer2,
  ) {}

  ngOnInit() {
    const pageId = this.route.snapshot.params.id;
    if (!pageId) {
      this.showSelectPageMessage = true;
      return;
    }

    this.io.listen('page/list', async ({ pages }) => {
      this.page = pages.find(page => page._id === pageId);
      if (!this.page) {
        this.showSelectPageMessage = true;
        return;
      }
      await this.loadTwilioToken();
      await this.start();
    });

    this.io.listen('video/end', window.close);

    // Leave room when navigating away from browser tab
    window.addEventListener('beforeunload', this.reset.bind(this));
  }

  /**
   * Load the twilio token
   *
   * @returns Promise<void>
   */
  loadTwilioToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api.post('chat/token', { deviceId: 'browser' }).subscribe((response) => {
        const { token } = response.meta_data;
        this.twilioToken = token;
        resolve();
      });
    });
  }

  async start() {
    // Ensure a twilio token is passed to the component
    if (!this.twilioToken || !this.page) {
      this.showSelectPageMessage = true;
      return;
    }

    this.showSelectPageMessage = false;
    this.showErrorMessage = false;
    this.showPermissionError = false;
    this.loading = true;

    // Room names are page ids
    // so rooms therefore have a 1-1 relation with pages.
    try {
      this.room = await TwilioVideo.connect(this.twilioToken, { name: this.page._id, audio: true, video: false });
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        this.showPermissionError = true;
      } else {
        this.showErrorMessage = true;
        console.error('Error connecting to twilio video', err);
      }
      this.loading = false;
      return;
    }

    this.loading = false

    setTimeout(this.initTracks.bind(this), 0);

    this.io.send('video/join', { pageId: this.page._id, roomId: this.room.sid });
  }

  initTracks() {
     // Start current user's video and audio
     const localParticipantAudioTracks = this.room.localParticipant.audioTracks;
     const localParticipantVideoTracks = this.room.localParticipant.videoTracks;
     localParticipantAudioTracks.forEach((track) => {
       this.LocalAudio.nativeElement.appendChild(track.attach());
     });
     localParticipantVideoTracks.forEach((track) => {
       this.LocalVideo.nativeElement.appendChild(track.attach());
     });
 
     // Start remote users' video and audio
     this.room.participants.forEach((participant) => {
       const remoteParticipantAudioTracks = participant.audioTracks;
       const remoteParticipantVideoTracks = participant.videoTracks;
       remoteParticipantAudioTracks.forEach((track) => {
         this.RemoteAudio.nativeElement.appendChild(track.attach());
       });
       remoteParticipantVideoTracks.forEach((track) => {
         this.RemoteVideo.nativeElement.appendChild(track.attach());
       });
     });
 
     this.room.on('participantConnected', this.handleParticipantConnected.bind(this));
     this.room.on('participantDisconnected', this.handleParticipantDisconnected.bind(this));
     this.room.on('trackPublished', this.handleTrackAdded.bind(this));
     this.room.on('trackUnpublished', this.handleTrackRemoved.bind(this));
     this.room.on('trackSubscribed', this.handleTrackAdded.bind(this));
     this.room.on('trackUnsubscribed', this.handleTrackRemoved.bind(this));
     this.room.on('disconnected', this.handleDisconnected.bind(this));
  }

  toggleMic() {
    this.micActive = !this.micActive;

    const localParticipant = this.room.localParticipant;
    if (!this.micActive) {
      localParticipant.audioTracks.forEach((audioTrack) => {
        audioTrack.disable();
      });
    } else {
      localParticipant.audioTracks.forEach((audioTrack) => {
        audioTrack.enable();
      });
    }
  }

  toggleSpeaker() {
    this.speakerActive = !this.speakerActive;
  }

  async toggleVideo() {
    this.videoActive = !this.videoActive;

    if (!this.videoActive) {
      this.removeVideoTrack();
    } else {
      if (this.shareScreenActive){
        this.toggleShareScreen();
      }
      if (this.room) {
        const localParticipant = this.room.localParticipant;
        const videoTrack = await TwilioVideo.createLocalVideoTrack({ audio: true, video: { width: 640 } });
        localParticipant.publishTrack(videoTrack);
        this.LocalVideo.nativeElement.appendChild(videoTrack.attach());
      } else {
        this.videoActive = !this.videoActive;
      }
    }
  }

  async toggleShareScreen(){
    this.shareScreenActive = !this.shareScreenActive;

    if (!this.shareScreenActive) {
      if (this.videoAllowed){
        this.toggleVideo();
      }
      this.removeVideoTrack();
    } else {
      this.videoAllowed = this.videoActive;
      if (this.videoActive) {
        this.toggleVideo();
      }
      const localParticipant = this.room.localParticipant;
      // @ts-ignore
      const stream = await navigator.mediaDevices.getDisplayMedia();
      const screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
      localParticipant.publishTrack(screenTrack);
      this.LocalVideo.nativeElement.appendChild(screenTrack.attach());
    }
  }

  private removeVideoTrack() {
    const localParticipant = this.room.localParticipant;
    localParticipant.videoTracks.forEach(this.removeTrack);
    localParticipant.addTracks(Array.from(localParticipant.videoTracks.values()));
    localParticipant.unpublishTracks(Array.from(localParticipant.videoTracks.values()));
  }

  removeTrack(track) {
    if (track.stop) {
      track.stop();
    }
    const mediaElements = track.detach();
    mediaElements.forEach(mediaElement => mediaElement.remove());
  }

  disconnect() {
    this.io.send('video/leave', { pageId: this.page._id });
    if (this.room) {
      this.room.disconnect();
    }
  }

  reset() {
    this.loading = false;
    this.showSelectPageMessage = false;
    this.showErrorMessage = false;
    this.friendConnected = false;
    this.disconnect();
  }

  // Room handlers

  handleParticipantConnected(participant) {
    this.friendConnected = true;
  }

  handleParticipantDisconnected(participant) {
    participant.tracks.forEach(this.removeTrack);
    if (this.room.participants.size === 0) {
      this.friendConnected = false;
    }
  }

  handleTrackAdded(track, participant) {
    if (!track.attach) {
      return;
    }
    this.friendConnected = true;
    if (track.kind === 'audio') {
      this.RemoteAudio.nativeElement.appendChild(track.attach());
    } else if (track.kind === 'video') {
      this.RemoteVideo.nativeElement.appendChild(track.attach());
    }
  }

  handleTrackRemoved(track, participant) {
    this.removeTrack(track);
  }

  handleDisconnected() {
    this.room.localParticipant.tracks.forEach(this.removeTrack);

    this.room.participants.forEach((participant) => {
      participant.tracks.forEach(this.removeTrack);
    });

    this.room = null;
  }

  handleClose() {
    this.reset();
    window.close();
  }

  getColumnNumberCss() {
    const videoPartners = this.RemoteVideo.nativeElement.children.length;
    if (videoPartners > 4) {
      return '33% 33% 33%';
    } else if (videoPartners > 1) {
      return '50% 50%';
    } else {
      return '100%';
    }
  }

}
