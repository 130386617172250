import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'app-map-filter',
  templateUrl: './map-filter.component.html',
  styleUrls: ['./map-filter.component.css'],
})
export class MapFilterComponent {
  
  @Input() title: string;
  @Input() items: Array<any>;
  @Input() selected: Array<any>;
  @Input() bindLabel: string;
  @Input() trackable: boolean = false;
  @Output() selectedChange: EventEmitter<Array<any>> = new EventEmitter(); // For 2-way binding [(selected)]=""
  @Output() change: EventEmitter<null> = new EventEmitter();

  refresh() {
    this.selectedChange.emit(this.selected);
    this.change.emit();
  }

  all() {
    this.selected = this.items;
    this.items.forEach(this.onAdd.bind(this));
    this.items = [...this.items];
    this.refresh();
  }
  
  clear() {
    this.selected = [];
    this.items.forEach(items => this.onRemove({ value: items }));
    this.items = [...this.items];
    this.refresh();
  }

  group() {

  }

  toggleTracking(event: MouseEvent, item: any) {
    event.preventDefault();
    event.stopImmediatePropagation();
    item.tracking = !item.tracking;
    this.refresh();
  }

  onAdd(item: any) {
    item.tracking = true;
    item.selected = true;
    this.refresh();
  }

  onRemove({ value: item }) {
    item.tracking = false;
    item.selected = false;
    this.refresh();
  }

}
