<div class="container">
  <h2 mat-dialog-title>{{ title }}</h2>
  <div>
    <!--<div class="search-row px-0">
      <input #SearchInput type="text" placeholder="Search..." (keyup)="handleSearchInput($event)">
    </div>-->
    <h3 *ngIf="!responses">No responses yet</h3>
    <div *ngIf="responses" class="row">
      <div class="list-friends-wrapper">
        <table class="list-friends">
          <tbody>
            <tr *ngFor="let response of responses">
              <td class="service-name-col">
                <p class="service-name-title">{{ response.beamId }}</p>
              </td>
              <td class="service-id-col">
                <button class="btn"
                  [class.green-btn]="response.color === 'green'"
                  [class.yellow-btn]="response.color === 'amber'"
                  [class.orange-btn]="response.color === 'red'"
                  [title]="response.text"></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <mat-dialog-actions></mat-dialog-actions>
</div>