import { Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { saveAs } from 'file-saver';
//import { utils as xlsx, write as writeXLSX } from 'xlsx';

import { GPSTimeRangeComponent } from './gps-time-range/gps-time-range.component';
import { ShareDataService } from '~common/share-data.service';
import { GPSService } from '~common/gps.service';

@Component({
  templateUrl: './gps-tracker-toggle.component.html',
  styleUrls: ['./gps-tracker-toggle.component.css'],
})

export class GPSTrackerToggleComponent {
  public downloadingJSON = false;
  public downloadingExcel = false;
  public generatingReport = false;
  public title = '';

  constructor(private gps: GPSService,
    public dialogRef: MatDialogRef<GPSTrackerToggleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public shareDataService: ShareDataService,
    public dialog: MatDialog ) {
  }

  toggleLive(live) {
    this.dialogRef.close({ live, reporting: live });
  }

  generateReport() {
    if (this.data.type === 'sensor') {
      const dialogRef = this.dialog.open(GPSTimeRangeComponent);
      dialogRef.afterClosed().subscribe(async (response) => {
        if (!response.startDate) {
          return;
        }
        this.dialogRef.close({
          live: this.data.live,
          reporting: this.data.live,
          reportingStart: response.startDate,
          reportingEnd: response.endDate,
          lastLocation: this.data.lastLocation,
          generatedReport: true,
        });
      });
    }
  }

  updateRemove(action) {
    this.dialogRef.close({ action, title: this.data.title });
  }

  async download(type) {
    if (type === 'json') {
      this.downloadingJSON = true;
    } else if (type === 'excel') {
      this.downloadingExcel = true;
    }

    if (this.data.type === 'sensor') {
      const dialogRef = this.dialog.open(GPSTimeRangeComponent);
      dialogRef.afterClosed().subscribe(async (response) => {
        if (!response.startDate) {
          return;
        }
        this.downloadReport(response.startDate, response.endDate, type);
      });
    } else {
      this.downloadReport(new Date(this.data.reportingStart), new Date(this.data.reportingEnd), type);
    }
  }

  async downloadReport(startDate, endDate, type) {
    const reportData = await this.getGPSHistory(startDate, endDate);
    switch (type) {
      case 'json':
        this.downloadingJSON = false;
        saveAs(new Blob([JSON.stringify(reportData)], { type: 'application/json' }), `${this.data.title}.json`);
        break;
      case 'excel':
        this.downloadingExcel = false;
        /* const worksheet = xlsx.json_to_sheet(reportData as any[]);
        const workbook = xlsx.book_new();
        xlsx.book_append_sheet(workbook, worksheet);
        const file = writeXLSX(workbook, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(file)], { type: 'application/octet-stream' }), `${this.data.title}.xlsx`); */
        break;
    }
    this.dialogRef.close({});
  }

  getGPSHistory(startDate, endDate) {
    return new Promise((resolve) => {
      this.gps.post('gps/list', {
        imei: this.data.deviceKey,
        from: startDate,
        to: endDate || new Date(),
      }).subscribe((data) => {
        resolve(data.meta_data.gps);
      });
    });
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF; // tslint:disable-line:no-bitwise
    }

    return buf;
  }
}
