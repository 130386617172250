import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Renderer2 } from '@angular/core';
import { PageService } from '~common/page.service';
import { IOService } from '~common/io.service';
import { Util } from '~common/util';

@Component({
  selector: 'app-video-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class VideoInvitationComponent implements OnInit {

  public active: Boolean = false;
  public caller: string;
  
  private page: any;
  private title = '';
  private audio;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private io: IOService,
    private util: Util,
    public renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.audio = new Audio('/assets/audio/ring-normal.wav');
    this.audio.loop = true;

    this.io.listen('video/invitation', async ({ page, sender, type, camera }) => {
      this.start(page, sender);
    })

    this.io.listen('video/join/you', this.stop.bind(this))
    this.io.listen('video/end', this.stop.bind(this))
  }

  start(page: any, caller: string) {
    this.page = page;
    this.caller = page.title === caller ? null : caller;
    this.active = true;
    this.audio.play();
    setTimeout(() => {
      this.stop();
    }, 28000);
  }

  accept() {
    this.util.popupCenter(`/#/video/${this.page._id}`, 'beamLive Video Call', 800, 600);
    this.stop();
  }

  deny() {
    this.stop();
  }

  stop() {
    this.active = false;
    this.page = null;
    this.caller = null;
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}
