<div #VideoWrapper class="video-wrapper">
  <img class="logo" src="assets/beamCloud.png" width="200">
  <div *ngIf="page" class="title">{{ page.title }}</div>
  <div *ngIf="loading && !showErrorMessage && !showPermissionError" class="page-alert">
    <h4>Starting Call</h4>
    <p>Please wait...</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div *ngIf="!loading && showSelectPageMessage" class="page-alert">
    <img class="info-img img-thumbnail" src="./assets/images/warning.svg"/>
    <h4>No call in progress</h4>
    <p>Please select a page and start a video call to begin</p>
  </div>
  <div *ngIf="!loading && !showErrorMessage && showPermissionError" class="page-alert">
    <img class="info-img img-thumbnail" src="./assets/images/warning.svg"/>
    <h4>No permission to access camera / mic</h4>
    <p>You have disabled beamLive from accessing your camera and / or mic. Please enable camera / mic permissions to
      make video / audio calls</p>
  </div>
  <div *ngIf="showErrorMessage" class="page-alert">
    <img class="info-img img-thumbnail" src="./assets/images/failure.svg"/>
    <h4>Error!</h4>
    <p>Whoops! Something went terribly wrong</p>
  </div>
  <div *ngIf="!loading && !friendConnected && !showSelectPageMessage && !showErrorMessage && !showPermissionError"
       class="page-alert">
    <h4>No participants</h4>
    <p>Waiting for someone to connect...</p>
    <mat-progress-bar mode="buffer"></mat-progress-bar>
  </div>

  <div #RemoteVideo [style.grid-template-columns]="getColumnNumberCss()" class="remote-video"></div>
  <div #RemoteAudio class="remote-audio"></div>
  <div #LocalVideo class="local-video"></div>
  <div #LocalAudio class="local-audio"></div>

  <div *ngIf="!loading && !showSelectPageMessage && !showErrorMessage && !showPermissionError" class="video-toolbar">
    <div class="video-btn-group">
      <a (click)="toggleMic()" *ngIf="room" [class.active]="micActive" class="video-btn">
        <img [src]="'assets/images/video/mic' + (!micActive ? '-off' : '') + '.png'"/>
      </a>
      <a (click)="toggleSpeaker()" *ngIf="room" [class.active]="speakerActive" class="video-btn">
        <img [src]="'assets/images/video/speaker' + (!speakerActive ? '-off' : '') + '.png'"/>
      </a>
      <a (click)="toggleVideo()" *ngIf="room" [class.active]="videoActive" class="video-btn">
        <img [src]="'assets/images/video/camera' + (!videoActive ? '-off' : '') + '.png'"/>
      </a>
      <a (click)="toggleShareScreen()" *ngIf="room" [class.active]="shareScreenActive" class="video-btn">
        <img [src]="'assets/images/video/screenshare' + (!shareScreenActive ? '-off' : '') + '.png'"/>
      </a>
      <a (click)="handleClose()" class="hangup-btn video-btn">
        <img src="assets/images/video/call.png"/>
      </a>
    </div>
  </div>
</div>
