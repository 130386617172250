import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild, ViewEncapsulation
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

const DEFAULT_PHONE_MASK = '+000000000000';
const PHONE_MASK = new Map([
  [1, '+1 (000) 000-0000'], // US
  [61, '+61 000-000-000'], // Australia
  [51, '+51 000-000-0000'], // Peru
  [92, '+92 000-000-0000'], // Pakistan
  [91, '+91 000-000-0000'], // India
  [36, '+36 00/000-0000'], // Hungary
  [44, '+44 000-000-0000'], // UK
]);

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css']
})
export class PhoneInputComponent implements OnInit, OnChanges {
  @ViewChild('PhoneNumber', { static: true }) phoneNumberRef: ElementRef;
  @Input() phoneInput;
  @Output() phoneOutput = new EventEmitter<any>();
  @Output() countryCode = new EventEmitter<any>();
  public phoneNumber = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit() {
    this.phoneNumber.valueChanges.subscribe(this.setPhoneNumber.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phoneInput) {
      this.phoneNumber.setValue(this.phoneInput);
    }
  }

  setPhoneNumber(phoneNumber: string) {
    // If 3 characters are left in phone number, send out the api request to guess passenger
    // We do this to search ahead and speed up the passenger finding workflow
    // By the time the full phone number is entered, the passenger has been guessed if it exists
    let formatLength = 3;
    for (const countryCode of Array.from(PHONE_MASK.keys())) {
      if (phoneNumber && phoneNumber.startsWith(`+${countryCode}`)) {
        const normalizedMask = this.normalizePhoneNumber(PHONE_MASK.get(countryCode));
        formatLength = normalizedMask ? normalizedMask.length : 0;
        this.countryCode.emit(countryCode);
      }
    }
    this.phoneOutput.emit(this.phoneNumber.value);
  }

  setPhonePlaceholder(focus: boolean) {
    if (focus) {
      this.phoneNumberRef.nativeElement.placeholder = '+________';
    } else {
      this.phoneNumberRef.nativeElement.placeholder = 'Phone Number';
    }
  }

  phoneMasker(phoneNumber: string) {
    function convertFormatToMask(format: string): (string | RegExp)[] {
      return format.split('').map(s => s === '0' ? /\d/ : s);
    }

    for (const countryCode of Array.from(PHONE_MASK.keys())) {
      if (phoneNumber.startsWith(`+${countryCode}`)) {
        return convertFormatToMask(PHONE_MASK.get(countryCode));
      }
    }
    return convertFormatToMask(DEFAULT_PHONE_MASK);
  }

  normalizePhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    return phoneNumber.replace(/[^+0-9]/g, '');
  }
}
