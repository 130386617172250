import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Env } from '../../environments/environment';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})

export class DispatchService {
  private baseUrl = Env.dispatchUrl;
  private socket: SocketIOClient.Socket;

  constructor(private auth: AuthService) {
  }

  connect() {
    if (this.socket) {
      return false;
    }

    return new Promise((resolve, reject) => {
      this.socket = io(this.baseUrl, {
        query: {
          accessToken: this.auth.getToken()
        },
        transports: ['websocket']
      });

      this.socket.on('connect', resolve);
      this.socket.on('error', reject);
    });
  }

  listen(event: string, fn: Function) {
    this.socket.on(event, fn);
  }

  send(event: string, data: any, callback?: Function) {
    if (this.socket) {
      if (callback) {
        this.socket.emit(event, data, callback);
      } else {
        this.socket.emit(event, data);
      }
    }
  }

}
