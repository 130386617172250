/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { MapViewComponent } from './map-view.component';
import { IPlaceMarker, IPlace, IPageMarker, IMediaMarker, IMedia, IPeopleMarker } from './map.interface';
import { MarkerType, MapTrackingMode } from './map.enum';

@Injectable()
export class MapFilterService {

  private view: MapViewComponent;

  public active = false;
  public breadcrumbs = true;
  public selectedAssets: any[] = [];
  public selectedPages: any[] = [];
  public selectedPeople: any[] = [];
  public selectedPlaces: any[] = [];
  public selectedMedia: any[] = [];
  public trackingImeis: string[] = [];

  setMapView(view: MapViewComponent) {
    this.view = view;
  }

  getSubscribedImeis(): Array<string> {
    return this.selectedPeople.map(gps => gps.imei)
      .concat(this.selectedAssets.map(asset => asset.imei));
  }

  getTrackingImeis(): Array<string> {
    return this.selectedPeople.concat(this.selectedAssets)
      .filter(selected => selected.tracking).map(selected => selected.imei);
  }

  toggleBreadcrumb() {
    this.breadcrumbs = !this.breadcrumbs;
    this.refresh(this.breadcrumbs);
  }

  toggleAuto() {
    this.view.setTrackingMode(this.view.trackingMode === MapTrackingMode.Manual ? MapTrackingMode.AutoAll : MapTrackingMode.Manual);
    this.refresh();
  }

  apply(loadBreadcrumbs = false) {
    this.view.gps.refreshImeiSubscriptions(loadBreadcrumbs);
    
    this.view.markers.forEach(marker => {
      if ([MarkerType.Page, MarkerType.Place, MarkerType.Media].includes(marker.type)) {
        marker.m.setMap(null)
      }
    });
    this.view.markers = this.view.markers.filter(marker =>
      ![MarkerType.Page, MarkerType.Place, MarkerType.Media].includes(marker.type));

    // Clear markers that are not needed
    this.view.markers = this.view.markers.filter(marker => {
      let keep = true;
      // Remove all static markers, they can be easily re-added without too much repaint
      if ([MarkerType.Page, MarkerType.Place, MarkerType.Media].includes(marker.type)) {
        keep = false;
      }

      // Clear markers of imeis no longer subscribed
      marker = marker as IPeopleMarker;
      const imeis = this.getSubscribedImeis();
      if (!imeis.includes(marker.gps.imei)) {
        keep = false;
      }

      // If breadcrumbs is toggled off, remove all but latest breadcrumb
      if (!this.breadcrumbs && !marker.isLatest) {
        keep = false;
      }

      // Remove markers from map
      if (!keep) {
        marker.m.setMap(null);
      }

      return keep;
    });

    this.selectedPlaces.forEach((place: IPlace) =>
      this.view.marker.addPlaceMarker(place.title, place.lat, place.long));
    this.selectedPages.forEach((page) =>
      this.view.marker.addPageMarker(page._id, page.title || '(Page)', page.location.coordinates[1], page.location.coordinates[0]));
    this.selectedMedia.forEach((media: IMedia) =>
      this.view.marker.addMediaMarker(media.filename, media.location.coordinates[1], media.location.coordinates[0]));

    const gpsTrackingCount = this.getTrackingImeis().length;
    if (gpsTrackingCount === 0) {
      this.view.setTrackingMode(MapTrackingMode.Manual);
    } else if (this.view.trackingMode === MapTrackingMode.AutoAll && gpsTrackingCount === 1) {
      this.view.setTrackingMode(MapTrackingMode.AutoSingle);
    } else if (this.view.trackingMode === MapTrackingMode.AutoSingle && gpsTrackingCount > 1) {
      this.view.setTrackingMode(MapTrackingMode.AutoAll);
    }

    if (this.selectedPlaces.length > 0
      || this.selectedPages.length > 0
      || this.selectedMedia.length > 0) {
      this.view.setTrackingMode(MapTrackingMode.AutoAll);
    }

    this.view.recalculateViewport();
  }

  refresh(loadBreadcrumbs = false) {
    this.apply(loadBreadcrumbs);

    this.active =
      this.selectedAssets.length > 0 ||
      this.selectedPages.length > 0 ||
      this.selectedPeople.length > 0 ||
      this.selectedPlaces.length > 0;
      this.selectedMedia.length > 0;
  }

  reset() {
    this.selectedAssets = [];
    this.selectedPeople = [];
    this.selectedPlaces = [];
    this.selectedMedia = [];
    this.selectedPages = [];
    this.active = false;
    this.apply();
  }
}