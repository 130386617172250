import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './rsvp-message-menu-dialog.component.html',
  styleUrls: ['./rsvp-message-menu-dialog.component.css']
})

export class RSVPMessageMenuDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RSVPMessageMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  doAction(action, data = null) {
    this.dialogRef.close({ action, message: this.data.message, data });
  }
}
