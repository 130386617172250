import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { RideService } from '~common/ride.service';
import { LoadingService } from '~common/loading.service';
import { ApiService } from '~common/api.service';
import { ITaxi } from '../../beam-forms/ride/ride.interface';

@Component({
  selector: 'app-ride-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css'],
})
export class RideDriverComponent {
  @Input() show: boolean = true;
  private page: any;
  rideRequested = false;
  taxi: ITaxi;

  constructor(
    private readonly api: ApiService,
    private readonly ride: RideService,
    private readonly loading: LoadingService,
    private readonly router: Router,
  ) {
    this.ride.page.subscribe((page) => { this.page = page; });
    this.ride.selectedTaxi.subscribe(taxi => this.taxi = taxi);
  }

  setAsSelectedTaxi() {
    this.ride.setSelectedTaxi(this.taxi);
  }

  requestRide() {
    this.loading.show('ride:requesting', `Requesting ride from ${this.taxi.driver}...`);
    this.rideRequested = true;
    if (this.page) {
      this.api.post('ride/submit', { id: this.page._id, vehicles: [this.taxi._id] })
      .subscribe((res) => {
        this.loading.hide('ride:requesting');
        this.router.navigate(['page']);
      });
    } else {
      this.ride.driverSelect.emit([this.taxi._id]);
    }
  }
}
