import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { ShareDataService } from '~common/share-data.service';
import { IOService } from '~common/io.service';
import { MapType } from '~map/map.enum';

@Component({
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})

export class LocationDialogComponent implements OnInit {
  public MapType = MapType;
  public title = '';
  public addressLoading = false;

  constructor(
    private io: IOService,
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public shareDataService: ShareDataService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.data.location && this.data.location.speed) {
      this.data.location.speedFormatted = `${parseInt(this.data.location.speed, 10)} ${this.data.location.speedUnit || 'mph'}`;
    }

    if (this.data.location && this.data.location.ts) {
      this.data.location.tsFormatted = new Date(this.data.location.ts).toLocaleString();
    } else if (this.data.location && this.data.location.date) {
      this.data.location.tsFormatted = new Date(this.data.location.date).toLocaleString();
    }

    if (!this.data.address) {
      this.addressLoading = true;
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(this.data.location.lat, this.data.location.long ? this.data.location.long : this.data.location.lng);
      geocoder.geocode({ 'location': latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0] && results[0].formatted_address) {
          this.data.address = results[0].formatted_address;
        }
        this.addressLoading = false;
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showDirection() {
    this.shareDataService.ShowMapDirection.emit(true);
    this.shareDataService.showDirectionpanel = true;
    this.onNoClick();
  }

  hidePin() {
    this.shareDataService.ShowMapDirection.emit(false);
    this.shareDataService.ShowDirection = false;
    this.shareDataService.showDirectionpanel = false;
    this.shareDataService.ShowMarker = false;
    this.onNoClick();
  }

  saveTitle(eta = false) {
    const data = {
      gps: {
        title: this.title,
        lat: this.data.location.lat,
        long: this.data.location.long,
      },
      eta,
    };

    this.shareDataService.location.emit({ data });
    this.onNoClick();
  }

  enableEta() {
    this.saveTitle(true);
  }

  getNavigateUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(
      `beamnav:${this.data.location.lat},${this.data.location.long},${this.title}`
    );
  }
}
