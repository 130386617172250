<div class="container d-flex d-flex-row">
    <div>
      <table cellpadding="10">
        <tbody>
        <tr>
          <td><strong>Latitude</strong></td>
          <td>{{data.message.data.gps.lat}}</td>
        </tr>
        <tr>
          <td><strong>Longitude</strong></td>
          <td>{{data.message.data.gps.long}}</td>
        </tr>
        </tbody>
      </table>
      <hr/>
      <div *ngIf="addressLoading" class="address">
        <img src="/assets/images/map-pointer.png" />
        <p>Fetching address...</p>
      </div>
      <div *ngIf="address" class="address">
        <img src="/assets/images/map-pointer.png" />
        <p>{{address}}</p>
      </div>
    </div>
    <div>
      <mat-accordion class="headers-align">
      <mat-expansion-panel *ngIf="data.editable" class="map-expansion-panel" hideToggle>
        <mat-expansion-panel-header class="map-expansion-panel-header">
          <button mat-menu-item>
            <span>Edit</span>
          </button>
        </mat-expansion-panel-header>
        <mat-form-field class="full-width">
                    <textarea #autosize="cdkTextareaAutosize" [(ngModel)]="title" cdkAutosizeMaxRows="5"
                              cdkAutosizeMinRows="2"
                              cdkTextareaAutosize
                              class="full-width"
                              matInput
                              placeholder="Location Title"></textarea>
        </mat-form-field>
        <mat-action-row>
          <button (click)="updateRemove(this.data.message, 'update')" class="green-btn" mat-button>Update</button>
          <button (click)="onNoClick()" class="orange-btn" mat-button>Cancel</button>
        </mat-action-row>
      </mat-expansion-panel>
      <button (click)="updateRemove(this.data.message, 'delete')" *ngIf="data.editable" mat-menu-item>
        <span>Remove</span>
      </button>
      <button (click)="updateRemove(this.data.message, 'copy')" mat-menu-item>
        <span>Copy</span>
      </button>
      <button (click)="showDirection(this.data.message)" [disabled]="!showDirectionButton" mat-menu-item>
        <span>Directions</span>
      </button>
      <a [disabled]="!showNavigationButton" [href]="getNavigateUrl(this.data.message)" mat-menu-item>
        <span>Navigation</span>
      </a>
      <button (click)="toggleEta(this.data.message)" mat-menu-item>
        <span> {{this.data.message.attributes.data.eta ? 'Disable' : 'Enable' }} ETA </span>
      </button>
      <mat-expansion-panel (expandedChange)="setGeofenceExpirationDate($event)" class="map-expansion-panel" hideToggle>
        <mat-expansion-panel-header class="map-expansion-panel-header">
          <button mat-menu-item>
            <span>Geofence</span>
          </button>
        </mat-expansion-panel-header>
        <mat-form-field class="full-width">
          <input [(ngModel)]="geofenceRadius" class="full-width" matInput placeholder="Radius" type="number"> meters
        </mat-form-field>
        <app-text-time-input #GeofenceExpirationDate
                             (scheduledTime)="geofenceExpiration = $event"
                             class="geofenceExpirationDate"
                             placeholder="Expiration Date">
        </app-text-time-input>
        <mat-action-row>
          <button (click)="addGeofence()" class="green-btn" mat-button>{{this.data.message.data.geofence ? 'Update' : 'Add'}}</button>
          <button (click)="onNoClick()" class="orange-btn" mat-button>Cancel</button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    </div>
</div>
