<ng-select (change)="refresh()"
            [(ngModel)]="selected"
            [clearSearchOnAdd]="true"
            [closeOnSelect]="false"
            [items]="items"
            [multiple]="true"
            [selectOnTab]="true"
            [virtualScroll]="true"
            [placeholder]="title"
            [bindLabel]="bindLabel"
            (add)="onAdd($event)"
            (remove)="onRemove($event)">
    <ng-template let-items="items" ng-multi-label-tmp>
        <div *ngIf="items.length === 1" class="ng-value">
        <span class="ng-value-label">{{items[0][bindLabel]}}</span>
        </div>
        <div *ngIf="items.length > 1" class="ng-value">
        <span class="ng-value-label">{{items.length}} {{title}}</span>
        </div>
    </ng-template>
    <ng-template ng-header-tmp>
        <div class="filter-controls">
        <button (click)="all()" class="btn btn-link">All</button>
        <button (click)="clear()" class="btn btn-link">Clear</button>
        <button (click)="group()" class="btn btn-link">Group</button>
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div>
            <div class="item-text">{{item[bindLabel]}}</div>
            <a *ngIf="trackable && item.selected" class="item-lock" [class.active]="item.tracking"
                title="Lock on map"
                (click)="toggleTracking($event, item)">
                <img src="assets/images/map/lock.png" width="20">
            </a>
        </div>
    </ng-template>
</ng-select>