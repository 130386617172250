import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

import { ApiService } from '~common/api.service';
import { ShareDataService } from '~common/share-data.service';
import { FailureDialogComponent } from '~dialog/failure/failure.component';
import { PageService } from '~common/page.service';

@Component({
  templateUrl: './transfer-ownership.component.html',
  styleUrls: ['./transfer-ownership.component.css']
})
export class TransferOwnershipDialogComponent implements OnInit {
  friendList = [];
  filteredFriendList = [];
  page;
  transferring = false;

  @ViewChild('SearchInput', { static: true }) searchInput: ElementRef;

  constructor(
    private pageService: PageService,
    private shared: ShareDataService,
    public dialogRef: MatDialogRef<TransferOwnershipDialogComponent>,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.pageService.current.subscribe(data => this.data = data);
    this.page = this.data;
    this.friendList = this.page.friends.map(friend => friend.userId);
    this.applySearch();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  transfer(friend) {
    this.transferring = true;
    this.api.post(`page/transfer/${this.page._id}/${friend._id}`, {}).subscribe((result) => {
      if (!result.status) {
        this.dialog.open(FailureDialogComponent, {
          width: '300px',
          data: { Message: result.message },
        });
      } else {
        this.dialogRef.close(true);
      }
      this.transferring = false;
    });
  }

  applySearch() {
    const term = this.searchInput.nativeElement.value;
    this.filteredFriendList = this.friendList.filter(friend =>
      friend.username.startsWith(term));
  }

  handleSearchInput(event) {
    this.applySearch();
  }
}
